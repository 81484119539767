import React, { useState } from "react";
import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import Modal from "react-modal";
import zIndex from "@mui/material/styles/zIndex";

// Initialize moment localizer
const localizer = momentLocalizer(moment);

// Define types for events
type Event = {
  id: string;
  title: string;
  start: Date;
  end: Date;
  description?: string;
  type?: string; // e.g., "In Progress", "Completed"
  fileName?: string; 
};

const TrainingCalendar: React.FC = () => {
  const [events, setEvents] = useState<Event[]>([
    // {
    //   id: "1",
    //   title: "React Training",
    //   start: new Date(2025, 0, 2, 9, 0), // January 2, 2025, 9:00 AM
    //   end: new Date(2025, 0, 2, 17, 0), // January 2, 2025, 5:00 PM
    //   description: "Full-day React training session.",
    //   type: "In Progress",
    // },
    // {
    //   id: "2",
    //   title: "TypeScript Workshop",
    //   start: new Date(2025, 0, 5, 10, 0),
    //   end: new Date(2025, 0, 5, 12, 0),
    //   description: "Hands-on TypeScript workshop.",
    //   type: "Completed",
    // },
    // {
    //   id: "3",
    //   title: "Angular Training",
    //   start: new Date(2025, 0, 5, 11, 0), // Overlapping event
    //   end: new Date(2025, 0, 5, 13, 0),
    //   description: "Angular deep dive session.",
    //   type: "In Progress",
    // },
    // {
    //   id: "4",
    //   title: "Vue.js Webinar",
    //   start: new Date(2025, 0, 5, 14, 0), // Non-overlapping event
    //   end: new Date(2025, 0, 5, 15, 0),
    //   description: "Vue.js for beginners.",
    //   type: "Completed",
    // },
  ]);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [newEventTitle, setNewEventTitle] = useState("");
  const [newEventDescription, setNewEventDescription] = useState("");
  const [newEventType, setNewEventType] = useState("In Progress");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [eventDetails, setEventDetails] = useState<Event | null>(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [newEventFile, setNewEventFile] = useState<File | null>(null);

  // Handle navigation
  const handleNavigate = (direction: "prev" | "next") => {
    const newDate =
      direction === "next"
        ? moment(currentDate).add(1, "month").toDate() // Adjust by one month (change "month" to "week" for weekly navigation)
        : moment(currentDate).subtract(1, "month").toDate();
    setCurrentDate(newDate);
  };

  // Filter events based on the search query
  const filteredEvents = events.filter((event) =>
    event.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (event.description &&
      event.description.toLowerCase().includes(searchQuery.toLowerCase()))
  );

  // Handle search input
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  // Custom event styling
  const eventStyleGetter = (event: any) => {
    let backgroundColor = "#3174ad"; // Default color
    if (event.type === "In Progress") backgroundColor = "#ffcc00";
    if (event.type === "Completed") backgroundColor = "#4caf50";

    return {
      style: {
        backgroundColor,
        color: "white",
        borderRadius: "5px",
        padding: "5px",
        border: "none",
        display: "inline-block", // Ensure it stays in line
        whiteSpace: "normal", // Allow text to break to a new line
        wordWrap: "break-word", // Ensure long text wraps
        minWidth: "100px", // Set a minimum width
        marginBottom: "5px", // Add some margin to events
      },
    };
  };

  // Event click handler to open modal for editing
  const onEventClick = (event: Event) => {
    setEventDetails(event);
    setNewEventTitle(event.title);
    setNewEventDescription(event.description || "");
    setNewEventType(event.type || "In Progress");
    setIsModalOpen(true);
  };

  // Open modal for adding new event
  const handleSelectSlot = (slotInfo: { start: Date; end: Date }) => {
    setSelectedDate(slotInfo.start);
    setNewEventTitle("");
    setNewEventDescription("");
    setNewEventType("In Progress");
    setEventDetails(null);
    setIsModalOpen(true);
    setNewEventFile(null);
  };

  // Save or update event
  const saveEvent = () => {
    if (newEventTitle) {
      if (eventDetails) {
        // Update existing event
        const updatedEvent: Event = {
          ...eventDetails,
          title: newEventTitle,
          description: newEventDescription,
          type: newEventType,
          fileName: newEventFile ? newEventFile.name : eventDetails.fileName
        };
        const updatedEvents = events.map((event) =>
          event.id === updatedEvent.id ? updatedEvent : event
        );
        setEvents(updatedEvents);
      } else {
        // Add new event
        const newEvent: Event = {
          id: String(events.length + 1),
          title: newEventTitle,
          start: selectedDate!,
          end: moment(selectedDate!).add(1, "hour").toDate(),
          description: newEventDescription,
          type: newEventType,
          fileName: newEventFile ? newEventFile.name : undefined,
        };
        setEvents([...events, newEvent]);
      }

      setNewEventTitle(""); // Reset title input
      setNewEventDescription(""); // Reset description input
      setNewEventType("In Progress"); // Reset event type
      setIsModalOpen(false); // Close the modal
    } else {
      alert("Title is required.");
    }
  };

  // Custom Event Card Component
const EventCard = ({ event }: { event: any }) => {
  return (
    <div
      style={{
        //backgroundColor: "grey",
        borderRadius: "8px",
        padding: "10px",
        boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
        //borderLeft: "5px solid #007BFF",
        fontSize: "14px",
        textAlign: "left",
      }}
    >
      <strong>{event.title}</strong>
      {/* <p style={{ margin: "5px 0" }}>{event.description}</p> */}
      <div style={{ marginBottom: "5px" }}>
        {/* {event.tags?.map((tag: string) => ( */}
          <span
            //key={tag}
            style={{
              display: "inline-block",
              padding: "2px 6px",
              marginRight: "5px",
              backgroundColor: "#007BFF",
              color: "#fff",
              borderRadius: "4px",
              fontSize: "12px",
            }}
          >
            Tag
          </span>
        {/* ))} */}
      </div>
      <p style={{ margin: 0, color: "#555" }}>
        <strong>Assigned To:</strong> Bryan
      </p>
    </div>
  );
};

  return (
    <div style={{ textAlign: "center", height: "100vh", padding: "20px" }}>
      <h1 className="common-manage-h1">Training Calendar</h1>

      {/* Search Input */}
      <div style={{ margin: "20px" }}>
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search events by title or description"
          style={{
            width: "50%",
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #ccc",
          }}
        />
      </div>

      {/* Navigation Buttons */}
      <div
        style={{
          //margin: "10px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <button
          onClick={() => handleNavigate("prev")}
          style={{
            padding: "10px 20px",
            backgroundColor: "#3174ad",
            color: "white",
            border: "none",
            borderRadius: "5px",
          }}
        >
          Back
        </button>
        <button
          onClick={() => handleNavigate("next")}
          style={{
            padding: "10px 20px",
            backgroundColor: "#3174ad",
            color: "white",
            border: "none",
            borderRadius: "5px",
          }}
        >
          Next
        </button>
      </div>

      <div style={{margin: '20px'}}>
        <Calendar
          localizer={localizer}
          events={filteredEvents}
          startAccessor="start"
          endAccessor="end"
          selectable
          popup // Ensures the +2 more popup works
          style={{ height: "calc(100vh - 150px)" }}
          //dayLayoutAlgorithm="overlap" // Ensures overlapping events trigger +2 more popup
          //style={{ height: "150vh", margin: "20px" }}
          eventPropGetter={eventStyleGetter}
          onSelectEvent={onEventClick}
          onSelectSlot={handleSelectSlot}
          date={currentDate}
          // components={{
          //   event: EventCard, // Use the custom event card#
          // }}
        />
      </div>

      {/* Modal for adding/editing event */}
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Event Details"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            padding: "20px",
            width: "400px",
            backgroundColor: "#f9f9f9",
            borderRadius: "10px",
            boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.2)",
            zIndex: '10000'
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: '9999'
          },
        }}
      >
        <h3 style={{ textAlign: "center", marginBottom: "20px" }}>
          {eventDetails ? "Edit Event" : "Add New Event"}
        </h3>
        <div>
          <div style={{ marginBottom: "10px" }}>
            <label htmlFor="eventTitle" style={{ display: "block", marginBottom: "5px" }}>
              Shopper Name:
            </label>
            <input
              type="text"
              id="eventTitle"
              value={newEventTitle}
              onChange={(e) => setNewEventTitle(e.target.value)}
              placeholder="Enter event title"
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                marginBottom: "15px",
              }}
            />
          </div>
          <div style={{ marginBottom: "10px" }}>
            <label
              htmlFor="eventDescription"
              style={{ display: "block", marginBottom: "5px" }}
            >
              Shopper Notes:
            </label>
            <textarea
              id="eventDescription"
              value={newEventDescription}
              onChange={(e) => setNewEventDescription(e.target.value)}
              placeholder="Enter event description"
              rows={4}
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                resize: "vertical",
              }}
            />
          </div>
          <div style={{ marginBottom: "15px" }}>
            <label htmlFor="eventType" style={{ display: "block" }}>
              Event Type:
            </label>
            <select
              id="eventType"
              value={newEventType}
              onChange={(e) => setNewEventType(e.target.value)}
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ccc",
              }}
            >
              <option value="In Progress">In Progress</option>
              <option value="Completed">Completed</option>
            </select>
          </div>
          <div style={{ marginBottom: "15px" }}>
            {/* <label htmlFor="eventFile" style={{ display: "block" }}>
              Upload File (optional):
            </label>
            <input
              type="file"
              id="eventFile"
              onChange={(e) => setNewEventFile(e.target.files ? e.target.files[0] : null)}
              style={{
                width: "100%",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #ccc",
              }}
            /> */}
          </div>
          <div style={{ textAlign: "center" }}>
            <button
              onClick={saveEvent}
              style={{
                padding: "10px 20px",
                backgroundColor: "#4caf50",
                color: "white",
                border: "none",
                borderRadius: "5px",
                marginRight: "10px",
              }}
            >
              Save
            </button>
            <button
              onClick={() => setIsModalOpen(false)}
              style={{
                padding: "10px 20px",
                backgroundColor: "#f44336",
                color: "white",
                border: "none",
                borderRadius: "5px",
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TrainingCalendar;
