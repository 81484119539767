const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const api_version = `${API_BASE_URL}api/v1`
const route_mediator = 'manage'

export const usersLogin = `${api_version}/${route_mediator}/auth/login`
export const getAllRoles = `${api_version}/${route_mediator}/roles/getAllRoles`
export const getAdminList = `${api_version}/${route_mediator}/user/getAdminList`
export const getClientEntities = `${api_version}/${route_mediator}/client/get-client-entities`
export const getUserDetails = `${api_version}/${route_mediator}/user/getUserDetails/`
export const postCreateAdmin = `${api_version}/${route_mediator}/user/create-admin-user`
export const putUpdateClient = `${api_version}/${route_mediator}/client/updateClient`
export const postCreateClient = `${api_version}/${route_mediator}/client/create-client`
export const getClients = `${api_version}/${route_mediator}/client/getClients`
export const putDeleteClient = `${api_version}/${route_mediator}/client/deleteClient`
export const putStoreStatus = `${api_version}/${route_mediator}/client/update-client-entity-status`
export const putUpdateClientEntity = `${api_version}/${route_mediator}/client/update-client-entity`
export const postCreateClientEntity = `${api_version}/${route_mediator}/client/create-client-entity`
export const getEntityTypeList = `${api_version}/${route_mediator}/entity-type/getEntityTypeList`
export const getVenueList = `${api_version}/${route_mediator}/venue/venue-list`
export const putVenueStatus = `${api_version}/${route_mediator}/venue/update-venue-status`
export const postCreateVenue = `${api_version}/${route_mediator}/venue/create-venue`
export const putUpdateVenue = `${api_version}/${route_mediator}/venue/update-venue`
export const putUpdateAdminDetails = `${api_version}/${route_mediator}/user/update-admin-details`
export const putUpdateAdminRole = `${api_version}/${route_mediator}/user/updateAdminRole`
export const putUpdateAdminStatus = `${api_version}/${route_mediator}/user/updateAdminStatus`
export const getShopType = `${api_version}/${route_mediator}/shop-type`
export const getStarRating = `${api_version}/${route_mediator}/star-rating`
export const getBriefJson = `${api_version}/${route_mediator}/brief-json`
export const putUpdateEditors = `${api_version}/${route_mediator}/client/update-editors`
export const putVenueEditors = `${api_version}/${route_mediator}/venue/update-editors`
export const getVisitTimes = `${api_version}/${route_mediator}/visit-time`
export const postVisitTimes = `${api_version}/${route_mediator}/visit-time`
export const putVisitTimes = `${api_version}/${route_mediator}/visit-time`
export const postClientEntityTemplate = `${api_version}/${route_mediator}/client/entity-template`
export const getClientEntityTemplateByClientEntityId = `${api_version}/${route_mediator}/client/entity-template`
export const putClientEntityTemplate = `${api_version}/${route_mediator}/client/entity-template`
export const postVenueTemplate = `${api_version}/${route_mediator}/venue/venue-template`
export const putVenueTemplate = `${api_version}/${route_mediator}/venue/venue-template`
export const getVenueEntityTemplateByClientEntityId = `${api_version}/${route_mediator}/venue/venue-template`
export const getAllocations = `${api_version}/${route_mediator}/allocation/get-allocations`
export const putUpdateAllocation = `${api_version}/${route_mediator}/allocation/update-allocation`
export const postCreateAllocations = `${api_version}/${route_mediator}/allocation/create-allocations`
export const getAllShoppersApplications = `${api_version}/${route_mediator}/shopper/get-applications`
export const putUpdateShopperApplicationStatus = `${api_version}/${route_mediator}/shopper/update-shopper-status`
export const getAppliedAllocations = `${api_version}/${route_mediator}/allocation/get-applied-allocations`
export const postUpdateAppliedAllocationStatus = `${api_version}/${route_mediator}/allocation/allot-shopper-to-allocation`
export const getShopper = `${api_version}/${route_mediator}/shopper/get-applications`
export const getOrphanAllocations = `${api_version}/${route_mediator}/allocation/get-orphan-allocations`
export const getNearbyShopper = `${api_version}/${route_mediator}/allocation/get-nearby-shopper-list`
export const postInviteShopper = `${api_version}/${route_mediator}/allocation/invite-shopper`
export const getcategories = `${api_version}/${route_mediator}/category/getCategoryList`
export const getentities = `${api_version}/${route_mediator}/entity-type/getEntityTypeList`
export const addquestions = `${api_version}/${route_mediator}/question/add-question`
export const updatequestions = `${api_version}/${route_mediator}/question/update-question`
export const getquestions = `${api_version}/${route_mediator}/question/get-question-list`
export const getquestiontemplate = `${api_version}/${route_mediator}/question/get-que-temp`
export const addquestiontemplate = `${api_version}/${route_mediator}/question/add-que-temp`
export const updatequestiontemplate = `${api_version}/${route_mediator}/question/update-que-temp`
export const deletequestiontemplate = `${api_version}/${route_mediator}/question/delete-que-temp`
export const gettemplatequestions = `${api_version}/${route_mediator}/question/get-temp-questions`
export const addtemplatequestions = `${api_version}/${route_mediator}/question/add-temp-questions`
export const removetemplatequestions = `${api_version}/${route_mediator}/question/remove-temp-questions`
export const uploadVenueImage = `${api_version}/${route_mediator}/upload/upload-file`
export const putCancelJob = `${api_version}/${route_mediator}/allocation/cancel-allocation`
export const getcliententityquestions = `${api_version}/${route_mediator}/question/get-client-entity-questions`
export const getvenuequestions = `${api_version}/${route_mediator}/question/get-venue-questions`
export const addbusinessquestions = `${api_version}/${route_mediator}/question/add-client-entity-questions`
export const addvenuequestions = `${api_version}/${route_mediator}/question/add-venue-questions`
export const removebusinessquestions = `${api_version}/${route_mediator}/question/delete-client-entity-questions`
export const removevenuequestions = `${api_version}/${route_mediator}/question/delete-venue-questions`
export const updatebusinessquestions = `${api_version}/${route_mediator}/question/update-client-entity-questions`
export const updatevenuequestions = `${api_version}/${route_mediator}/question/update-venue-questions`
export const updatevenuequestionorder = `${api_version}/${route_mediator}/question/update-questions-display-orders`
export const updatevenuecategoryorder = `${api_version}/${route_mediator}/question/update-category-display-orders`
export const getClientDbName = `${api_version}/${route_mediator}/client/getclients-dbnames`