import React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  FormControl,
  IconButton,
  Stack,
  Autocomplete,
  TextField,
  Popover,
  Checkbox,
  Pagination,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "react-datepicker/dist/react-datepicker.css";
import {
  ApiGetOrphanAllocations,
  ApiGetVenueList,
  ApiGetClientEntities,
  ApiGetNearbyShopper,
} from "../../../apiService/Core/Apicall.tsx";
import useDebounce from "../CreateAllocations/UseDebounce.tsx";
import Dcrage from "./Dcrage.tsx";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

export default function OrphanAllocation() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [allocations, setAllocations] = useState<any>([]);
  const [venueName, setVenueName] = useState([]);
  const [venueNameValue, setVenueNameValue] = useState<any>("");
  const [clientEntityList, setClientEntityList] = useState<any>([]);
  const [clientEntityId, setClientEntityId] = useState<any>(null);
  const debouncedClientEntityId = useDebounce(clientEntityId, 500);
  const debouncedVenue = useDebounce(venueNameValue, 700);
  const [nearestOpen, setOpenNearest] = useState(false);
  const [nearbyShopper, setNearbyShopper] = useState<any>([]);
  const [loanData, setLoanData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Get the current slice of allocations for the page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentAllocations = allocations.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handlePrevMonth = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(
        prevDate.getFullYear(),
        prevDate.getMonth() - 1,
        1
      );
      const jobMonth = newDate.getMonth() + 1;
      const jobYear = newDate.getFullYear();
      ApiCallGetAllocations(jobMonth, jobYear);

      return newDate;
    });
  };

  const handleNextMonth = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(
        prevDate.getFullYear(),
        prevDate.getMonth() + 1,
        1
      );
      const jobMonth = newDate.getMonth() + 1;
      const jobYear = newDate.getFullYear();
      ApiCallGetAllocations(jobMonth, jobYear);

      return newDate;
    });
  };
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = new Date(event.target.value + "-01");
    setCurrentDate(newDate);
  };

  const formatDate = (date: Date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}`;
  };

  const mmOptions = ["MM", "ALDI", "BLOOMS"];

  useEffect(() => {
    const fetchData = async () => {
      const currentDate = new Date();
      const jobMonth = currentDate.getMonth() + 1;
      const jobYear = currentDate.getFullYear();
      await ApiCallGetAllocations(jobMonth, jobYear, debouncedVenue);
      await ApiCallGetVenueList();
      await handleGetClientList();
    };
    fetchData();
    return () => {};
  }, [debouncedVenue]);

  const ApiCallGetVenueList = async (debouncedClientEntityId?: any) => {
    const data = `?clientEntityId=${clientEntityId}&offset=0&limit=500`;
    const res = await ApiGetVenueList(data);
    const response = res?.data.map((item) => ({
      label: item.venueName,
      value: item.venueId,
    }));
    setVenueName(response);
  };

  useEffect(() => {
    if (debouncedClientEntityId) {
      ApiCallGetVenueList(debouncedClientEntityId);
    }
    else {
      setVenueName([]); // Clear venue list when business is cleared
      setVenueNameValue(null); // Also clear selected venue
    }
  }, [debouncedClientEntityId]);

  const handleGetClientList = async () => {
    const data = "";
    try {
      const res: any = await ApiGetClientEntities(data);
      const response = res?.data.map((item) => ({
        label: item.entityName,
        value: item.clientEntityId,
      }));
      setClientEntityList(response);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const ApiCallGetAllocations = async (
    jobMonth: any = "",
    jobYear: any = "",
    debouncedVenue?: any
  ) => {
    //const data = `?offset=0&limit=100&venueId=${venueNameValue}&jobMonth=${jobMonth}&jobYear=${jobYear}`;
    const data =
      `?offset=0&limit=100` +
      (venueNameValue ? `&venueId=${venueNameValue}` : "") +
      `&jobMonth=${jobMonth}&jobYear=${jobYear}`;
    try {
      const res: any = await ApiGetOrphanAllocations(data);
      setAllocations(res?.data);
      console.log("setAllocations", res?.data);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };
  const navigate = useNavigate();
  const handelInvite = (stateData: any) => {
    sessionStorage.setItem("inviteShopperState", JSON.stringify(stateData));
    const url = `/admin/allocations/Invite-Shopper`;
    window.open(url, "_blank");
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const handleHover = (event, index) => {
    debugger
    setAnchorEl(event.currentTarget);
    setSelectedIndex(index); // Store the hovered row index
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedIndex(null);
  };

  const ApiCallGetNearbyShopper = async (newVenueId: any) => {
    const data = `?venueId=${newVenueId}`;
    // &kilometerRange=10
    try {
      const res: any = await ApiGetNearbyShopper(data);
      setNearbyShopper(res?.data);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  console.log(nearbyShopper)

  return (
    <div>
      <Box sx={{ p: 4 }} style={{ background: "white" }}>
        <Stack spacing={3}>
          <Box sx={{ width: "100%" }}>
            <h1 className="common-manage-h1" style={{ textAlign: "center" }}>
              Orphan Allocation
            </h1>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "-70px",
                marginTop: "23px",
              }}
            >
              <Stack direction="row" spacing={2}>
                <FormControl size="small" sx={{ width: 180 }}>
                  <Autocomplete
                    disablePortal
                    id="beacon-autocomplete"
                    options={clientEntityList}
                    onChange={(event, newValue: any) => {
                      setClientEntityId(newValue?.value || null);
                      if (!newValue) {
                        // Clear venue when business is cleared
                        setVenueNameValue(null);
                      }
                    }}
                    value={
                      clientEntityList.find(
                        (option) => option.value === clientEntityId
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Select Business" />
                    )}
                    size="small"
                  />
                </FormControl>
                <FormControl size="small" sx={{ width: 180 }}>
                  <Autocomplete
                    disablePortal
                    id="mm-autocomplete"
                    options={venueName}
                    value={venueName.find((option) => option.value === venueNameValue) || null}
                    onChange={(event, newValue) => {
                      setVenueNameValue(newValue?.value || null);
                    }}
                    defaultValue=""
                    renderInput={(params) => (
                      <TextField {...params} label="Search Venue" />
                    )}
                    size="small"
                  />
                </FormControl>
              </Stack>
              <Stack direction="row" spacing={2}>
                <FormControl size="small" sx={{ width: 180 }}>
                  <Autocomplete
                    disablePortal
                    id="mm-autocomplete"
                    options={mmOptions}
                    defaultValue="MM"
                    renderInput={(params) => (
                      <TextField {...params} label="Select Client" />
                    )}
                    size="small"
                  />
                </FormControl>
              </Stack>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "70px",
            }}
          >
            <IconButton onClick={handlePrevMonth}>
              <ChevronLeftIcon />
            </IconButton>
            <Box sx={{ display: "flex", alignItems: "center", mx: 2 }}>
              <input
                type="month"
                value={formatDate(currentDate)}
                onChange={handleDateChange}
                className="primary-color"
                style={{
                  border: "none",
                  background: "none",
                  fontSize: "1.25rem",
                  fontWeight: "bold",
                  cursor: "pointer",
                  fontFamily: "inherit",
                }}
              />
            </Box>
            <IconButton onClick={handleNextMonth}>
              <ChevronRightIcon />
            </IconButton>
          </Box>
          <Grid
            container
            spacing={4}
            className="mt-5"
            style={{
              gap: "20px",
              paddingRight: "32px",
              flexWrap: "nowrap",
              gridTemplateColumns: "1fr 1fr",
              display: "block",
            }}
          >
            <div
              className="table-row-background"
              style={{
                color: "white",
                borderRadius: "5px",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "16px",
                }}
              >
                <div style={{ flexGrow: 1, width: "50%" }}>venue Name</div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    width: "50%",
                    flexWrap: "wrap",
                  }}
                >
                  <div style={{ width: "150px", marginLeft: "16px" }}>
                    2nd Last Shoppers
                  </div>
                  <div style={{ width: "150px", marginLeft: "8px" }}>
                    Last Shoppers
                  </div>
                  <div style={{ width: "150px", marginLeft: "8px" }}></div>
                </div>
              </div>
            </div>
            {currentAllocations?.map((allocation, index) => (
              <Grid
                style={{
                  padding: "0px",
                  boxShadow: "none",
                  maxWidth: "100%",
                  width: "100%",
                  marginBottom: "20px",
                }}
                item
                xs={12}
                md={6}
                key={index}
              >
                <Card
                  className="min-border-color"
                  style={{ boxShadow: "none" }}
                >
                  <CardContent>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ flexGrow: 1, width: "50%" }}>
                        <Typography variant="h6" gutterBottom>
                          {allocation?.venueId?.venueName}
                        </Typography>
                        <Typography>
                          <b>Address:</b> {allocation?.venueId?.address}
                        </Typography>
                        <Typography>
                          <b>Postcode:</b> {allocation?.venueId?.postalCode}
                        </Typography>
                        <Typography>
                          <b>Trade House:</b>
                          {allocation?.venueId?.tradingHours}
                        </Typography>
                        <Typography>
                          <b>WebSite:</b> {allocation?.venueId?.websiteURL}
                        </Typography>
                        <hr></hr>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className="table-row-background"
                          style={{ marginBottom: "8px", width: "170px" }}
                          onClick={() => {
                            setOpenNearest(
                              nearestOpen === index ? null : index
                            );
                            const newVenueId = allocation?.venueId?.venueId;
                            ApiCallGetNearbyShopper(newVenueId);
                            console.log("newVenueId", newVenueId);
                          }}
                        >
                          load Nearest
                        </Button>
                        {nearestOpen === index && (
                          <div>
                            <div className="nearest-table min-border-color">
                              <table>
                                <thead
                                  className="table-row-background"
                                  style={{ color: "white" }}
                                >
                                  <tr>
                                    <th>Select</th>
                                    <th>Shopper</th>
                                    <th>Phone</th>
                                    <th>Distance(kms)</th>
                                    <th>Dob</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {nearbyShopper?.map((item, index) => (
                                    <tr key={index}>
                                      <td>
                                        <Checkbox></Checkbox>
                                      </td>
                                      <td>
                                        <Button
                                          variant="contained"
                                          color="primary"
                                          size="small"
                                          className="table-row-background"
                                          style={{
                                            width: "100%",
                                          }}
                                          onMouseEnter={(e) => {
                                            setLoanData(item);
                                            handleHover(e, index);
                                          }}
                                        >
                                          {item.firstName} --- {item.lastName}
                                        </Button>
                                      </td>
                                      <td>{item.contactNo}</td>
                                      <td>{item.distanceInKm.toFixed(2)}</td>
                                      <td>
                                        {dayjs(item.dob).format("DD/MM/YYYY")}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                            <div style={{ textAlign: "right" }}>
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                className="table-row-background"
                                style={{ marginTop: "20px", width: "auto" }}
                              >
                                Invite Selected shoppers
                              </Button>
                            </div>
                          </div>
                        )}
                      </div>
                      <Popover
                        open={Boolean(anchorEl) && selectedIndex === index}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      >
                        <Dcrage handleClose={handleClose} loanData={loanData} />
                      </Popover>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginLeft: "16px",
                          justifyContent: "end",
                          gap: "10px",
                          flexWrap: "wrap",
                          width: "50%",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className="table-row-background"
                          style={{ marginBottom: "0px", width: "150px" }}
                          onMouseEnter={(e) => {
                            handleHover(e, index);
                          }}
                        >
                          Josie Gair
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className="table-row-background"
                          style={{ marginBottom: "0px", width: "150px" }}
                          onMouseEnter={(e) => {
                            handleHover(e, index);
                          }}
                        >
                          Christine Smith
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className="table-row-background"
                          style={{ width: "150px" }}
                          onClick={() => handelInvite(allocation)}
                        >
                          Invite Shopper
                        </Button>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Stack>
        {/* Pagination */}
        {allocations.length > itemsPerPage && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "70px",
            }}
          >
            <Pagination
              count={Math.ceil(allocations.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        )}
      </Box>
    </div>
  );
}
