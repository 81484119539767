import Domains from "./constants/domain.ts";
export const Images = () => {
  const domain = window.location.hostname;

  if (domain.includes(Domains.ALDI)) {
    return {
      AldiBanner2: require("../assets/aldi/images/Aldi-Banner2.jpg"),
      AldiBanner1: require("../assets/aldi/images/Aldi-Banner1.jpg"),
      mmLogo: require("../assets/aldi/images/aldimm-logo.png"),
      LoginBanner: require("../assets/aldi/images/loginbgnew.jpg"),
    };
  } else if (domain.includes(Domains.BLOOMS)) {
    return {
      AldiBanner2: require("../assets/blooms/images/Blooms-banner1.png"),
      AldiBanner1: require("../assets/blooms/images/Blooms-banner2.png"),
     LoginBanner: require("../assets/blooms/images/Blooms.webp"),
    };
  } else if (domain.includes(Domains.SHOPPER)) {
    return {
      AldiBanner2: require("../assets/images/shoppers-image-1.jpg"),
      AldiBanner1: require("../assets/images/shoppers-image.jpg"),
      mmLogo: require("../assets/common/images/MM-logo.jpg"),
    };
  } else if (domain.includes(Domains.MM)) {
    return {
      AldiBanner2: require("../assets/aldi/images/Aldi-Banner2.jpg"),
      AldiBanner1: require("../assets/aldi/images/Aldi-Banner1.jpg"),
      mmLogo: require("../assets/common/images/MM-logo.jpg"),
    };
  } else {
    return {
      AldiBanner2: require("../assets/aldi/images/Aldi-Banner2.jpg"),
      AldiBanner1: require("../assets/aldi/images/Aldi-Banner1.jpg"),
      mmLogo: require("../assets/common/images/MM-logo.jpg"),
    };
    //   return require('../assets/common/images/default-image.jpg');
  }
};
