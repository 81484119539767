import React, { useEffect, useState } from 'react'
import {
  Box,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  Select,
  TextField,
  MenuItem
} from '@mui/material'
import {
  ApiGetShopper,
  ApiGetNearbyShopper,
  ApiPostInviteShopper
} from '../../../apiService/Core/Apicall.tsx'
import { toast } from 'react-toastify'

const withinData = [
  { label: 'Within 10 km', value: '10km' },
  { label: 'Within 25 km', value: '25km' },
  { label: 'Within 50 km', value: '50km' },
  { label: 'Within 150 km', value: '150km' }
]

const InviteShopper = () => {

  const [values, setValues] = useState<{ oldShopperIds: string[] }>({
    oldShopperIds: []
  })
  const [shopperList, setShopperList] = useState<any>([])
  const [stateData, setStateData] = useState<any>(null)
  const [searchQuery, setSearchQuery] = useState('')
  const [gender, setGender] = useState<any>('')
  const [startAge, setStartAge] = useState('')
  const [age, setAge] = useState('')

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    setValues(prevValues => ({
      oldShopperIds: event.target.checked
        ? [...prevValues.oldShopperIds, value]
        : prevValues.oldShopperIds.filter(val => val !== value)
    }))
  }
  useEffect(() => {
    const fetchData = async () => {
      await ApiCallGetShopperList()
    }
    fetchData()
    return () => {}
  }, [])

  const ApiCallGetShopperList = async () => {
    const data = `?offset=0&limit=500`
    const res = await ApiGetShopper(data)
    const response = res?.data.map(item => ({
      label:
        item.firstName + item.lastName === ''
          ? '-'
          : item.firstName + item.lastName,
      value: item.userId
    }))
    setShopperList(response)
  }

  useEffect(() => {
    const storedDataUp = sessionStorage.getItem('inviteShopperState')
    if (storedDataUp) {
      setStateData(JSON.parse(storedDataUp))
    }
  }, [])

  useEffect(() => {
    setGender(stateData?.gender || '')
    setStartAge(stateData?.minAge || '')
    setAge(stateData?.maxAge || '')
  }, [stateData])

  const filteredVenues = shopperList.filter(option =>
    option.label.toLowerCase().includes(searchQuery.toLowerCase())
  )

  const handleSendInviteToShopper = async () => {
    const data ={
      userIds: values.oldShopperIds,
      allocationId: stateData.allocationId,
      createdBy: 1
    }
    try{
      const response = await ApiPostInviteShopper(data)
      toast.success('Selected Shoppers Invited Successfully!')
    }
    catch (error) {
      console.error('Error updating store:', error)
      toast.error(error);
    }
  }

  return (
    <div className='invite-shopper'>
      <h1 className='common-add' style={{ textAlign: 'center' }}>
        Orphan Allocation - Invite-Shopper
      </h1>
      <div>
        <Typography variant='h6' gutterBottom>
          {stateData?.venueId?.venueName}
        </Typography>
        <Typography>
          <b>Address:</b> {stateData?.venueId.address}
        </Typography>
        <Typography>
          <b>Postcode:</b> {stateData?.venueId?.postalCode}
        </Typography>
        <Typography>
          <b>Trade House:</b> {stateData?.venueId?.tradingHours}
        </Typography>
        <Typography>
          <b>WebSite:</b>
          <a href={stateData?.venueId?.websiteURL}>
            {stateData?.venueId?.websiteURL}
          </a>
        </Typography>
        <hr />
        <div>
          {withinData.map(option => (
            <FormControlLabel
              key={option.value}
              control={
                <Checkbox
                  checked={values.oldShopperIds.includes(option.value)}
                  onChange={event => handleCheckboxChange(event, option.value)}
                />
              }
              label={option.label}
            />
          ))}
        </div>
        <hr />
        <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
          <div className='flex items-center gap-2'>
            <span>Start Age: </span>
            <Select
              value={startAge}
              onChange={e => setStartAge(e.target.value)}
            >
              {Array.from({ length: 86 }, (_, i) => (i + 15).toString()).map(
                age => (
                  <MenuItem key={age} value={age}>
                    {age}
                  </MenuItem>
                )
              )}
            </Select>
          </div>

          <div className='flex items-center gap-2'>
            <span>End Age: </span>
            <Select value={age} onChange={e => setAge(e.target.value)}>
              {Array.from({ length: 86 }, (_, i) => (i + 15).toString()).map(
                ageOption => (
                  <MenuItem key={ageOption} value={ageOption}>
                    {ageOption}
                  </MenuItem>
                )
              )}
            </Select>
          </div>
          <div className='flex items-center gap-2'>
            <span>Gender: </span>
            <Select
              value={gender}
              onChange={e => setGender(e.target.value)}
              style={{ width: '150px' }}
            >
              <MenuItem value='Female'>Female</MenuItem>
              <MenuItem value='Male'>Male</MenuItem>
              <MenuItem value='All'>All</MenuItem>
            </Select>
          </div>
        </div>
        <hr />
        <TextField
          fullWidth
          type='text'
          InputLabelProps={{ shrink: true }}
          placeholder='Search Shopper...'
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
        <hr />
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            Please Select Shopper (Shoppername) (State) (Age) (Distance)
            <Button
              variant='contained'
              color='primary'
              size='small'
              className='table-row-background'
              style={{ marginBottom: '0px', width: '200px' }}
              onClick={handleSendInviteToShopper}
            >
              Invite Shopper Select
            </Button>
          </div>
          <hr />
          {filteredVenues.map(option => (
            <FormControlLabel
              key={option.value}
              control={
                <Checkbox
                  checked={values.oldShopperIds.includes(option.value)}
                  onChange={event => handleCheckboxChange(event, option.value)}
                />
              }
              label={option.label}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default InviteShopper
