import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Autocomplete,
  TextField,
  Box,
  IconButton,
} from "@mui/material";
import { data, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import Switch from "@mui/material/Switch";
import edit from '../../../assets/images/editIcon.png'
import {
  ApiGetClientEntities,
  ApiGetAdminList,
  ApiPutStoreStatus,
  ApiPutUpdateClientEntity,
  ApiGetClientEntitiTemplate,
} from "../../../apiService/Core/Apicall.tsx";
import { toast } from "react-toastify";

const ManageStore: React.FC = () => {
  const [adminUsers, setAdminUsers] = useState<any>([]);
  const [clientEntityList, setClientEntityList] = useState<any>([]);
  const [status, setStatus] = useState({});

  const navigate = useNavigate();

  const handleGetApiCall = async () => {
    try {
      const response: any = await ApiGetAdminList();
      setAdminUsers(response.data);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const handleGetClientList = async () => {
    try {
      const response: any = await ApiGetClientEntities(data);
      setClientEntityList(response.data);
      console.log("rse store", response.data);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const deleteStore = async (store: any, newStatus: string) => {
    try {
      const body = {
        clientEntityId: store?.clientEntityId,
        status: newStatus,
      };
      const response: any = await ApiPutStoreStatus(body);
      toast.success("Business Deleted successfully");
      console.log("Status updated successfully:", response);
      await handleGetClientList();
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const addOrUpdateStore = (store?: any) => {
    if (store?.clientEntityId) {
      navigate(`/admin/add-or-update-store/update/${store?.clientEntityId}`, {
        state: { clientEntity: store },
      });
    } else {
      navigate("/admin/add-or-update-store/add");
    }
  };

  const navigateToViewEditors = (store?: any) => {
    navigate("/admin/view-editor-business", {
      state: { store },
    });
  };

  const navigateToViewJobCriteria = (store?: any) => {
    navigate("/admin/view-business-jobcriteria", {
      state: { store },
    });
  };
  const navigateToClientQuestion = (store?: any) => {
    navigate("/admin/create-client-template", {
      state: { store },
    });
  };

  const handleChange = async (store: any, newStatus: string) => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      [store.clientEntityId.ClientID]: newStatus,
    }));

    setClientEntityList((prevList) =>
      prevList.map((item) =>
        item.clientEntityId === store.clientEntityId
          ? {
              ...item,
              clientEntityStatus: {
                ...item.clientEntityStatus,
                userStatus: newStatus,
              },
            }
          : item
      )
    );

    try {
      const body = {
        clientEntityId: store?.clientEntityId,
        status: newStatus,
      };
      const response: any = await ApiPutStoreStatus(body);
      handleGetApiCall();
      console.log("Status updated successfully:", response);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      await handleGetClientList();
      await handleGetApiCall();
    };
    fetchData();
    return () => {};
  }, []);
  const onclickEdit = (value: any, store: any) => {
    setClientEntityList((prevState) =>
      prevState.map((item) =>
        item.clientEntityId === store.clientEntityId
          ? { ...item, editorId: { ...item.editorId, userName: value?.value } }
          : item
      )
    );
    const FormData = {
      clientEntityId: store.clientEntityId,
      editorId: value.roleId,
      entityName: store.entityName,
      entityCode: store.entityCode,
    };
    const res = ApiPutUpdateClientEntity(FormData);
  };
  const typeOptions = adminUsers.map((role) => ({
    label: role.userName,
    roleId: role.userId,
    value: role.userName,
  }));

  return (
    <div className="manage-admin-container">
      <h1 className="common-manage-h1">Manage Business</h1>
      <div className="add-button common-manage-button">
        <Button
          variant="contained"
          onClick={() => addOrUpdateStore()}
          className="table-row-background "
        >
          Add New Business
        </Button>
      </div>
      <Table>
        <TableHead>
          <TableRow className="table-row-background">
            <TableCell style={{ color: "#fff" }}>ID</TableCell>
            <TableCell style={{ color: "#fff" }}>Location Name</TableCell>
            <TableCell style={{ color: "#fff" }}>Code</TableCell>
            <TableCell style={{ color: "#fff" }}>Hotel Type</TableCell>
            <TableCell style={{ color: "#fff" }}>Editor</TableCell>
            <TableCell style={{ color: "#fff" }}>Job Criteria</TableCell>
            <TableCell style={{ color: "#fff" }}>Questions</TableCell>
            <TableCell style={{ color: "#fff" }}>Active</TableCell>
            {/* <TableCell></TableCell> */}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clientEntityList.map((store) => (
            <TableRow key={store.clientEntityId}>
              <TableCell>{store.clientEntityId}</TableCell>
              <TableCell>{store.entityName}</TableCell>
              <TableCell>{store.entityCode}</TableCell>
              <TableCell>{store.entityTypeId.entityTypeName}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  onClick={() => navigateToViewEditors(store)}
                  className="table-row-background"
                >
                  View Editors
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  onClick={() => navigateToViewJobCriteria(store)}
                  className="table-row-background"
                >
                  Job Criteria
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  onClick={() => navigateToClientQuestion(store)}
                  className="table-row-background"
                >
                  Questions
                </Button>
              </TableCell>
              <TableCell>
                <div className="switch-admin">
                  <Switch
                    checked={store?.clientEntityStatus?.userStatus === "active"}
                    onChange={(e) => {
                      const newStatus = e.target.checked
                        ? "active"
                        : "inActive";
                      handleChange(store, newStatus); // Pass the store and the new status
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </div>
              </TableCell>
              {/* <TableCell>
                <IconButton
                  // variant="contained"
                  onClick={() => addOrUpdateStore(store)}
                  // className="table-row-background"
                >
                  <Box component='img' className="w-75" src={edit}></Box>
                </IconButton>
              </TableCell> */}
              <TableCell>
              <IconButton
                  // variant="contained"
                  onClick={() => addOrUpdateStore(store)}
                  // className="table-row-background"
                >
                  <Box component='img' className="w-75" src={edit}></Box>
                </IconButton>
                <Button
                  variant="contained"
                  className="delete-transparent"
                  onClick={() => {
                    const isConfirmed = window.confirm(
                      "Are you sure you want to delete the Business?"
                    );
                    if (isConfirmed) {
                      deleteStore(store, "delete"); // Call the API or action
                    }
                  }}
                >
                  <DeleteIcon className="delete-icon" />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ManageStore;
