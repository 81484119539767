import React, { useRef, useState } from "react";
import GoogleMap from "google-maps-react-markers";

interface AppProps {
  lat: number; // Changed to just number
  lng: number; // Changed to just number
  setLat: (lat: number) => void;
  setLng: (lng: number) => void;
  setAddress: (address: google.maps.places.PlaceResult | undefined) => void
}

const GoogleMapDialog: React.FC<AppProps> = ({ setLat, lat, lng, setLng, setAddress }) => {
  const mapRef = useRef<google.maps.Map | null>(null);
  const [searchBox, setSearchBox] =
    useState<google.maps.places.SearchBox | null>(null);
  const [marker, setMarker] = useState<google.maps.Marker | null>(null);

  const onGoogleApiLoaded = ({
    map,
    maps,
  }: {
    map: google.maps.Map;
    maps: typeof google.maps;
  }) => {
    mapRef.current = map;

    const input = document.getElementById("search-input") as HTMLInputElement;
    const searchBoxInstance = new maps.places.SearchBox(input);
    setSearchBox(searchBoxInstance);

    searchBoxInstance.addListener("places_changed", () => {
      const places = searchBoxInstance.getPlaces();
      console.log(places);
      setAddress(places[0])
      if (places && places.length > 0) {
        const place = places[0];
        const location = place.geometry?.location;

        if (location) {
          const searchedLat = location.lat();
          const searchedLng = location.lng();

          setLat(searchedLat);
          setLng(searchedLng);
          map.setCenter({ lat: searchedLat, lng: searchedLng });

          if (marker) {
            marker.setPosition(location);
          } else {
            const newMarker = new maps.Marker({
              position: location,
              map,
              title: place.name,
            });
            setMarker(newMarker);
          }
        }
      }
    });

    map.addListener("click", (event: google.maps.MapMouseEvent) => {
      if (event.latLng) {
        const clickedLat = event.latLng.lat();
        const clickedLng = event.latLng.lng();

        setLat(clickedLat);
        setLng(clickedLng);

        if (marker) {
          marker.setPosition(event.latLng);
        } else {
          const newMarker = new maps.Marker({
            position: event.latLng,
            map,
          });
          setMarker(newMarker);
        }
      }
    });
  };

  return (
    <>
      <div style={{ width: "calc(100% - 220px)", position: "relative" }}>
        <input
          id="search-input"
          type="text"
          placeholder="Search location"
          style={{
            width: '30%',
            padding: '7px',
            marginBottom: '10px',
            border: '1px solid rgb(204, 204, 204)',
            borderRadius: '4px',
            position: 'absolute',
            zIndex: 999,
            top: '10px',
            left: '194px'
          }}
        />
        <GoogleMap
          apiKey="AIzaSyCmcI0swO1MNjQYUD7qktNT78-cDMBoi44"
          defaultCenter={{ lat, lng }}
          defaultZoom={15}
          mapMinHeight="100vh"
          onGoogleApiLoaded={onGoogleApiLoaded}
        />
      </div>
    </>
  );
};

export default GoogleMapDialog;
