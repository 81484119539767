import instance from "./ApiConfig.tsx";
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../../global/config/Action/loadingActions.ts";
import store from "../../global/config/store.ts"; 
export const baseApiCall = async (config) => {
  store.dispatch(setLoadingTrue());
  try {
    const response = await instance(config);

    if (response?.status >= 200 && response?.status < 300) {  
      store.dispatch(setLoadingFalse());
      return response?.data; 
    } else {
      store.dispatch(setLoadingFalse());
      throw new Error(`Unexpected status code: ${response?.status}`);
    }
  } catch (e) {
    console.log('API error', e);
    const errorMessage = e?.response?.data?.message || "An error occurred while making the request.";
    store.dispatch(setLoadingFalse());
    console.log("errorMessage errorMessage",errorMessage)
    // throw new Error(errorMessage);
    throw errorMessage
  
  }
};
