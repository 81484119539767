import React, { forwardRef, useEffect, useRef, useState } from 'react'
import { Box, Button, Divider, FormControl, IconButton, InputLabel, List, ListItem, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import '../../../assets/ExtraStyle.css'
import { CancelOutlined } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import QuestionList from '../QuestionLists/QuestionList.tsx';
import { GetCategories, GetTemplateQuestions, RemoveTemplateQuestions, UpdateVenueQuestionOrder } from '../../../apiService/Core/Apicall.tsx';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';


interface DragItem {
    index: number;
}
const QuestionCard = forwardRef(({ question, index, moveQuestion, removequestion, }: { question: any; index: number; moveQuestion: (from: number, to: number) => void; removequestion: (id: number) => void; }, ref) => {
    const dragRef = useRef<any>(null);
    const dropRef = useRef<any>(null);

    const [, drag] = useDrag({
        type: 'QUESTION',
        item: { index },
    });

    const [, drop] = useDrop({
        accept: 'QUESTION',
        hover: (draggedItem: DragItem) => {
            if (draggedItem.index !== index) {
                moveQuestion(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });

    drag(dragRef);
    drop(dropRef);

    return (
        <Box ref={(node) => {
            dragRef.current = node;
            dropRef.current = node;
        }} key={index} className='my-2' border='1px solid #E0E0E0' borderRadius='5px' padding='10px'>
            <Box className='w-100 d-flex justify-content-end'>
                <Typography variant='caption' className='text-end'>Index: {index}</Typography>
            </Box>
            <Box className='d-flex align-items-center justify-content-between my-3'>
                <Box className='d-flex align-items-center' gap={1}>
                    <Typography className='text-main fw-bold'>Question ID: #{question.questionId.questionId}</Typography>
                    <Typography className='text-main fw-bold'>|</Typography>
                    <Typography className='fw-bold'>Category: {question.questionId.categoryId?.categoryName}</Typography>
                    <Typography className='fw-bold'>|</Typography>
                    <Typography className='fw-bold'>Type: {question.questionId.qType?.length > 0 ? question.questionId.qType === "R" ? "Radio" : "Text" : 'notFound'}</Typography>
                </Box>
                <Button className='px-4 py-1 bg-danger transform text-light' onClick={() => { removequestion(question.entityTypeTemplateQuestionId) }}>- Remove</Button>
            </Box>
            <Box>
                <Typography className='fw-bold my-1 d-flex'>Q:&nbsp;<span dangerouslySetInnerHTML={{ __html: question.questionId.questions }} /></Typography>
                <Box className='d-flex align-items-center' gap={3}>
                    <Box className='d-flex align-items-center'>
                        <Box className='px-4 py-1 text-main' borderRight='1px solid #747247' bgcolor='#7472471A'>
                            <Typography>Ver {question.questionId.verNo}</Typography>
                        </Box>
                        <Typography className='px-4 my-2 py-1 text-main bg-light-primary'>{question.questionId.entityTypeId?.entityTypeName || 'undefined'}</Typography>
                    </Box>
                    {question.questionId.isPhotoRequire && (
                        <Box className=" bg-light-primary px-4 py-2" borderRadius='20px' width='fit-content'>
                            <Typography>Images - Min: {question.questionId.minPhoto}</Typography>
                        </Box>
                    )}
                </Box>
                {question.questionId.qOptionJson !== null ? (
                    <List component='ol' className="question-options w-100 d-flex justify-content-between flex-wrap">
                        {(() => {
                            try {
                                const parsedOptions = JSON.parse(question.questionId.qOptionJson);
                                return parsedOptions.map((option, index) => (
                                    <ListItem key={index} className="col-6 option-text">
                                        <span>{index + 1}. &nbsp;</span>
                                        <span dangerouslySetInnerHTML={{ __html: option.value }} />
                                    </ListItem>
                                ));
                            } catch (error) {
                                console.error("Invalid JSON in qOptionJson:", error);
                                return (
                                    <Typography className="option-text text-danger">
                                        Error: Invalid JSON format
                                    </Typography>
                                );
                            }
                        })()}
                    </List>
                ) : (
                    <Typography className="option-text">
                        Answer will be a manual Text Entry
                    </Typography>
                )}
            </Box>
            <Typography className="d-flex">
                Discription: &nbsp;<span dangerouslySetInnerHTML={{ __html: question.questionId.questionDescription }} />
            </Typography>
            <Typography className="d-flex">
                Hint: &nbsp;<span dangerouslySetInnerHTML={{ __html: question.questionId.questionHint }} />
            </Typography>
        </Box>
    );
});

function CreateNewTemplate() {

    const [category, setcategorey] = useState<any>([])
    const [activequestions, setactivequestions] = useState<any>([])
    const [selectedCategory, setselectedCategory] = useState<number | string>('')
    const [TemplateQuestions, setTemplateQuestions] = useState<any>('')
    const [addedData, setAddedData] = useState<number[]>([]);
    const [addedFilters, setAddedFilters] = useState<number[]>([]);
    const [alignment, setAlignment] = useState<number>(addedFilters[0]);
    const [reload, setreload] = useState<boolean>(false);
    const [refresh, setRefresh] = useState<number>(0);
    const [ischange, setischange] = useState<boolean>(false);


    const handleQuestionListUpdate = () => {
        setRefresh((prev) => prev + 1);
    };
    const moveQuestion = (fromIndex, toIndex) => {
        const updatedQuestions = [...activequestions];
        const [movedQuestion] = updatedQuestions.splice(fromIndex, 1);
        updatedQuestions.splice(toIndex, 0, movedQuestion);
        setactivequestions(updatedQuestions);
        setischange(!ischange)
    };
    const location = useLocation();
    const templateData = location.state?.templateData;
    useEffect(() => {
        if (category.length > 0) {
            setselectedCategory(category[category.length - 1].categoryId);
        }
    }, [selectedCategory, category]);
    useEffect(() => {
        const fetchcategory = async () => {
            try {
                const categories = await GetCategories()
                setcategorey(categories.data)
            }
            catch (error) {
                alert(error)
            }

        }

        fetchcategory()
    }, [])

    useEffect(() => {
        const fetchtselectedquestions = async () => {
            try {
                const response = await GetTemplateQuestions(templateData?.entityTypeTemplateId)
                console.log(response)
                setTemplateQuestions(response.data)
                
                setreload(false)
                response.data.map(item => {
                    setAddedFilters(prevFilters =>
                        prevFilters.includes(item.questionId.categoryId.categoryId)
                        ? prevFilters
                        : [...prevFilters, item.questionId.categoryId.categoryId]
                    );
                    setAlignment(response.data[0].questionId.categoryId.categoryId)
                    
                })
            }
            catch (error) {
                alert(error)
            }

        }
        fetchtselectedquestions()
    }, [alignment, addedFilters, reload, refresh])

    const handleChange2 = (event) => {
        setAddedFilters(prevFilters =>
            prevFilters.includes(event)
                ? prevFilters
                : [...prevFilters, event]
        );
    };

    const handleAlignment = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: number,
    ) => {
        setAlignment(newAlignment);
    };

    const removequestion = async (entityTypeTemplateQuestionId) => {
        try {
            const response = await RemoveTemplateQuestions(entityTypeTemplateQuestionId)
            alert(response.message)
            setreload(true)
        }
        catch (error) {
            alert(error)
        }

    }
    useEffect(() => {
        if (TemplateQuestions) {
            const activeCategory = TemplateQuestions.filter(q => q.questionId.categoryId.categoryId === alignment)
                .sort((a, b) => a.displayOrder - b.displayOrder);
            setactivequestions(activeCategory);
        }
    }, [alignment, TemplateQuestions]);

    useEffect(() => {
        if (TemplateQuestions) {
            if (activequestions) {
                const updatequestionOrder = async () => {
                    const updatedMap = {
                        type: "template_questions",
                        questionOrders: activequestions?.map((question, index) => ({
                            entityTypeTemplateQuestionId: question.entityTypeTemplateQuestionId,
                            displayOrder: index
                        })) || []
                    };
                    try {
                        const response = await UpdateVenueQuestionOrder(updatedMap)
                        // alert(response.message)
                    }
                    catch (error) {
                        alert(error)
                    }
                }
                updatequestionOrder()
            }
        }
    }, [ischange]);

    return (
        <Box className='container-fluid bg-light create-new-template extra' boxShadow='0px 0px 3px #000000' minHeight='calc(100vh - 165px)' padding='20px'>
            <Box className='d-flex align-items-center justify-content-between'>
                <Typography className='fs-18'>Manage Questions</Typography>
            </Box>
            <Divider className='my-4 mb-0' />
            <Typography className='m-0 p-0' variant='subtitle1'>{templateData?.entityTypeTemplateId || 0}&nbsp;{templateData?.templateName || "Undefine"}</Typography>
            {/* <FormControl className='col-1'>
                    <InputLabel id="demo-simple-select-label" shrink={false}>Select Business Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        // value={BusinessType}
                        label="Age"
                    // onChange={handleChange}
                    >
                        <MenuItem value={'Chemist'}>Ten</MenuItem>
                        <MenuItem value={'drugist'}>Twenty</MenuItem>
                        <MenuItem value={'Psycological'}>Thirty</MenuItem>
                    </Select>
                </FormControl> */}
            <Divider className='my-3' />
            <Box className='d-flex' gap={2}>
                <Box className='w-50 p-2' border='1px solid #E0E0E0'>
                    <QuestionList venueId={null} onUpdate={handleQuestionListUpdate}
                        entityTypeTemplateId={templateData?.entityTypeTemplateId} clientEntityId={null} />
                </Box>
                <Box className='w-50 p-2' border='1px solid #E0E0E0'>
                    <Typography className='fs-18 fw-bold mb-2 mt-1'>Template Finalised Questions</Typography>
                    <Box className='d-flex align-items-stretch'>
                        <FormControl className='col-2'>
                            <Select value={selectedCategory ?? ''} onChange={(e) => { setselectedCategory(Number(e.target.value)); handleChange2(e.target.value) }}>

                                {category.length > 0 &&
                                    category.map((cat, index) => (
                                        <MenuItem value={cat.categoryId} key={index}>
                                            {cat.categoryName}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <ToggleButtonGroup
                            sx={{ overflow: 'auto' }}
                            color="primary"
                            value={alignment}
                            exclusive
                            onChange={handleAlignment}
                            aria-label="Platform"
                        >
                            {addedFilters.map((filter, index) => {
                                const matchedCategory = category.find((cat) => cat.categoryId === filter);
                                const categoryName = matchedCategory ? matchedCategory.categoryName : "Unknown";
                                return (
                                    <ToggleButton
                                        className="transform d-flex align-items-center"
                                        sx={{ gap: 2, fontSize: '16px', whiteSpace: 'nowrap' }}
                                        key={index}
                                        value={filter}
                                    >
                                        {categoryName}
                                        <span
                                            className='d-flex align-items-center'
                                            onClick={() => setAddedFilters(addedFilters.filter((e) => e !== filter))}
                                        >
                                            <CancelOutlined sx={{ fontSize: '18px' }} />
                                        </span>
                                    </ToggleButton>
                                )
                            })}
                        </ToggleButtonGroup>

                    </Box>
                    {/* {TemplateQuestions?.length > 0 && TemplateQuestions.map((question, index) => (
                         alignment === question.questionId.categoryId?.categoryId && (
                        
                    )))} */}
                    <DndProvider backend={HTML5Backend}>
                        {/* {TemplateQuestions?.length > 0 && TemplateQuestions.map((question, index) => (
                            alignment === question.questionId.categoryId?.categoryId && (

                                <QuestionCard key={index} removequestion={removequestion} question={question} index={index} moveQuestion={moveQuestion} />
                            )))} */}
                        {
                            activequestions?.length > 0 && activequestions.map((question, index) => (
                                <QuestionCard key={index} removequestion={removequestion} question={question} index={index} moveQuestion={moveQuestion} />
                            ))
                        }
                    </DndProvider>


                </Box>
            </Box>

        </Box>
    )
}

export default CreateNewTemplate
