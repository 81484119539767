import React, { useState } from 'react'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button
} from '@mui/material'

const ImportExportQuestionsManager: React.FC = () => {
  const [selectedVenue, setSelectedVenue] = useState('')
  const [venues, setVenues] = useState([])
  const handleVenueChange = event => {
    setSelectedVenue(event.target.value)
  }
  return (
    <div className='manage-admin-container'>
      <h1 className='common-manage-h1'>Import Export Manager Questions</h1>
      <FormControl className='export-manager-width mt-4' variant='outlined'>
        <InputLabel id='select-venue-label'>Select Venue</InputLabel>
        <Select
          labelId='select-venue-label'
          id='select-venue'
          value={selectedVenue}
          onChange={handleVenueChange}
          label='Select Venue'
        >
          {venues.length === 0 ? (
            <MenuItem disabled value=''>
              No options available
            </MenuItem>
          ) : (
            venues.map(venue => <MenuItem></MenuItem>)
          )}
        </Select>
      </FormControl>
      <div className='import-export-flex'>
        <div className='import-export-box'>
          <h5>Import Questions</h5>
          <div className='questions-flex'>
            <TextField
              type='file'
              variant='outlined'
              className='import-file m-2'
            />
            <div className='import-flex-button'>
              <Button
                variant='contained'
                className='table-row-background  my-2'
              >
                Upload
              </Button>
              <Button variant='contained' className='table-row-background my-2'>
                Upload to All Venues
              </Button>
            </div>
          </div>
        </div>

        <div className='import-export-box'>
          <Button variant='contained' className='table-row-background m-2'>
            Export All Questions
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ImportExportQuestionsManager
