import React from "react";
import { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import {
  Autocomplete,
  Button,
  TextField,
  Radio,
  RadioGroup,
  FormHelperText,
  FormControl,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PatchedReactQuill from "./PatchedReactQuill.tsx";
import {
  ApiGetClientEntities,
  ApiPostCreateVenue,
  ApiPutUpdateVenue,
  ApiGetShopType,
  ApiGetStarRating,
  ApiGetBriefJson,
  ApiGetClientEntitiTemplate,
} from "../../../apiService/Core/Apicall.tsx";
import GoogleMapDialog from "./GoogleMapDialog.tsx";
import * as Yup from "yup";
import { CustomUploadProfilePic } from "../../../apiService/Core/CustomUploadProfilePic.tsx";

interface AddOrUpdateVenueProps {
  initialValues?: {
    venueName: string;
    venueCode: string;
    entityName: string;
    hotelType: string;
    territory: string;
    contact: string;
    address: string;
    suburb: string;
    state: string;
    country: string;
    postCode: string;
    phone: string;
    fax: string;
    email: string;
    feedbackEmail: string;
    auditEmail: string;
    fixItEmail: string;
    reportEmail: string;
    description: string;
    shopType: string;
    email2: string;
    jobType: string;
    tradingHours: string;
    website: string;
    venuePrice: string;
    venuePriceDisplay: string;
    pdfDescription: string;
    briefJson: string;
    lat: number;
    long: number;
    starRatings: string;
    venueLogo: string | File;
    locationType: string;
  };
  mode: "add" | "update";
  onSubmit: (values: any) => void;
}

interface FormValues {
  entityName: string;
  hotelType: string;
  venueName: string;
  venueCode: string;
  contact: string;
  email: string;
  locationType: string;
  starRatings: string;
}

const AddOrUpdateVenue: React.FC<AddOrUpdateVenueProps> = () => {
  const { mode, id } = useParams<{ mode: "add" | "update"; id?: string }>();
  const location = useLocation();
  const venue = location?.state?.venueData;
  const Latitude: any = Number(venue?.lat);
  const Longitude = Number(venue?.long);
  const [latNew, setLat] = useState<number>(Latitude || 0);
  const [lngNew, setLng] = useState<number>(Longitude || 0);
  const [hotelType, setHotelType] = useState<any>("");
  const [entityName, setEntityName] = useState<any>("");
  const [formValues, setFormValues] = useState<any>({
    venueName: "",
    venueCode: "",
    entityName: entityName,
    hotelType: hotelType,
    territory: "",
    contact: "",
    address: "",
    state: "",
    country: "",
    suburb: "",
    postCode: "",
    phone: "",
    fax: "",
    email: "",
    feedbackEmail: "",
    auditEmail: "",
    fixItEmail: "",
    reportEmail: "",
    description: "",
    shopType: "",
    email2: "",
    jobType: "",
    tradingHours: "",
    website: "",
    venuePrice: "",
    venuePriceDisplay: "",
    pdfDescription: "",
    briefJson: "",
    starRatings: "",
    venueLogo: "",
    locationType: "",
  });
  const [clientEntity, setClientEntity] = useState([]);
  const [entityType, setEntityType] = useState<any[]>([]);
  const [editorValue, setEditorValue] = useState<any>("");
  const [preview, setPreview] = useState<string | null>(null);
  const [starRatingList, setStarRating] = useState<any>([]);
  const navigate = useNavigate();
  const [briefJsonNew, setBriefJson] = useState<any>([]);
  const [logoUrl, setLogoUrl] = useState<any>();
  const [mapAddress, setAddress] = useState<any>()

  useEffect(() => {
    if (mode === "update" && venue) {
      setEditorValue(venue.venueDescription || "");
      setHotelType(venue?.shopTypeId?.shopTypeId || "");
      setEntityName(venue.clientEntityId.clientEntityId || "");
      setLogoUrl(venue?.logoUrl || "");
      setFormValues({
        venueName: venue.venueName || "",
        venueCode: venue.venueCode || "",
        entityName: venue.clientEntityId.clientEntityId || "",
        contact: venue.contactNo || "",
        address: venue.address || "",
        city: venue.city || "",
        state: venue.state || "",
        postalCode: venue.postalCode || "",
        email: venue.emailId || "",
        country: venue.country || "",
        briefJson: venue.briefJson || "",
        starRatings: venue.starRating || "",
        feedbackEmail: venue.feedbackEmail || "",
        auditEmail: venue.auditEmail || "",
        fixItEmail: venue.fixItEmail || "",
        reportEmail: venue.reportEmail || "",
        venueDescription: venue.venueDescription || "",
        tradingHours: venue.tradingHours || "",
        website: venue.websiteURL || "",
        venuePrice: venue.venuePrice || "",
        venuePriceDisplay: venue.venuePriceDisplay || "",
        pdfDescription: venue.pdfDescription || "",
        hotelType: venue?.shopTypeId?.shopTypeId || "",
        locationType: venue.locationType || "",
      });
      console.log(venue.starRating)
    }
  }, [mode, id, lngNew, latNew]);

  useEffect(() => {
    if (venue) {
      console.log("response getUserDetails", venue?.venueName);
    }
    handleGetApiCallClientEntity();
    handleGetApiCallEntityType();
    handelApiGetStarRating();
    handleGetEditType();
  }, [venue, mode]);

  const handleGetEditType = async () => {
    try {
      const response: any = await ApiGetBriefJson();
      if (Array.isArray(response.data)) {
        const transformedData = response.data.map((item: any) => ({
          value: item.briefJsonId,
          label: item.briefJsonTitle,
        }));
        setBriefJson(transformedData);
      } else {
        console.error("Unexpected data format:", response.data);
        setBriefJson([]);
      }
    } catch (error) {
      console.error("Error during API call:", error);
      setBriefJson([]);
    }
  };

  const handelApiGetStarRating = async () => {
    try {
      const response: any = await ApiGetStarRating();
      console.log("StarRating list", response.data);
      setStarRating(
        response.data.map((starRating: any) => ({
          label: starRating.starRatingDesc,
          value: starRating.starRatingId,
        }))
      );
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const handleGetApiCallClientEntity = async () => {
    const data = {};
    try {
      const response: any = await ApiGetClientEntities(data);
      console.log("clientEntity list", response.data);
      setClientEntity(
        response.data.map((clientEntity: any) => ({
          label: clientEntity.entityName,
          value: clientEntity.clientEntityId,
        }))
      );
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const handleEntityChange = async (newValue) => {
    try {
      if (!newValue) return; // Prevent errors if value is cleared
      setEntityName(newValue.value);
      const data = `?clientEntityId=${newValue.value}`;
      const response = await ApiGetClientEntitiTemplate(data);
      if (response.data.length === 0) {
        toast.error("Please add job crieteria for this business");
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const handleGetApiCallEntityType = async () => {
    try {
      const response: any = await ApiGetShopType();
      const transformedData = response.data.map((item: any) => ({
        value: item.shopTypeId,
        label: item.shopTypeName,
      }));
      setEntityType(transformedData);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const handleAddVenue = async (values: any) => {
    // const valueNew = Array.isArray(values.starRatings)
    //   ? values.starRatings
    //   : [];
    // const commaSeparated =
    //   valueNew.length > 0 ? valueNew.join(",") : values.starRatings;

    const addressComponents = mapAddress ? mapAddress.address_components : undefined;

    // Extracting values from address_components
    const formattedAddress = addressComponents ? addressComponents
      .slice(0, 3) // First 3 values for address
      .map((component) => component.long_name)
      .join(", ") : null

    const city = addressComponents ? addressComponents[3]?.long_name || "" : null;
    const state = addressComponents ? addressComponents[5]?.long_name || "" : null;
    const country = addressComponents ? addressComponents[6]?.long_name || "": null;
    const postalCode = addressComponents ? addressComponents[8]?.long_name || "": null;

    const FormData = {
      venueCode: values.venueCode,
      venueName: values.venueName,
      clientEntityId: entityName,
      contactNo: values.contact,
      emailId: values.email,
      address: formattedAddress,
      city: city,
      state: state,
      postalCode: postalCode,
      country: country,
      lat: latNew,
      long: lngNew,
      feedbackEmail: values.feedbackEmail,
      auditEmail: values.auditEmail,
      fixItEmail: values.fixItEmail,
      reportEmail: values.reportEmail,
      venueDescription: editorValue,
      shopTypeId: hotelType,
      tradingHours: values.tradingHours,
      websiteURL: values.website,
      venuePrice: values.venuePrice,
      venuePriceDisplay: values.venuePriceDisplay,
      pdfDescription: values.pdfDescription,
      briefJson: values.briefJson,
      logoUrl: logoUrl,
      starRating: values.starRatings,
      createdBy: 11,
      locationType: values.locationType,
    };
    try {
      const response = await ApiPostCreateVenue(FormData);
      console.log("New store added:", response);
      toast.success("Venue added successfully!");
      navigate(-1);
    } catch (error) {
      console.error("Error adding Venue:", error);
      toast.error(error);
    }
  };

  const handleUpdateVenue = async (values: any) => {
    debugger
    // const valueNew = Array.isArray(values.starRatings)
    //   ? values.starRatings
    //   : [];
    
      
    // const commaSeparated =
    //   valueNew.length > 0 ? valueNew.join(",") : values.starRatings;

    const addressComponents = mapAddress ? mapAddress.address_components : undefined;
    // Extracting values from address_components
    const formattedAddress = addressComponents ? addressComponents
      .slice(0, 3) // First 3 values for address
      .map((component) => component.long_name)
      .join(", ") : null

    const city = addressComponents ? addressComponents[4]?.long_name || "" : null;
    const state = addressComponents ? addressComponents[6]?.long_name || "" : null;
    const country = addressComponents ? addressComponents[7]?.long_name || "": null;
    const postalCode = addressComponents ? addressComponents[8]?.long_name || "": null;

    const FormData = {
      venueId: venue.venueId,
      venueCode: values.venueCode,
      venueName: values.venueName,
      clientEntityId: entityName,
      contactNo: values.contact,
      emailId: values.email,
      address: formattedAddress,
      city: city,
      state: state,
      postalCode: postalCode,
      country: country,
      lat: latNew,
      long: lngNew,
      feedbackEmail: values.feedbackEmail,
      auditEmail: values.auditEmail,
      fixItEmail: values.fixItEmail,
      reportEmail: values.reportEmail,
      venueDescription: editorValue,
      shopTypeId: hotelType,
      tradingHours: values.tradingHours,
      websiteURL: values.website,
      venuePrice: values.venuePrice,
      venuePriceDisplay: values.venuePriceDisplay,
      pdfDescription: values.pdfDescription,
      briefJson: values.briefJson,
      logoUrl: logoUrl,
      starRating: values.starRatings,
      createdBy: 11,
      updatedBy: 11,
      locationType: values.locationType,
    };
    try {
      const response = await ApiPutUpdateVenue(FormData);
      console.log("New Venue updated:", response);
      toast.success("Venue updated successfully!");
      navigate(-1);
    } catch (error) {
      console.error("Error adding Venue:", error);
    }
  };

  const validationSchema: Yup.ObjectSchema<FormValues> = Yup.object({
    entityName: Yup.string().required("Business type is required"),
    hotelType: Yup.string().required("Shop type is required"),
    venueName: Yup.string().required("Venue name is required"),
    venueCode: Yup.string().required("Venue code is required"),
    contact: Yup.string().required("Contact is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    locationType: Yup.string().required("Location type is required"),
    starRatings: Yup.string().required("Star Ratings are required"),

    // starRatings: Yup.array().required( "Star Rating is required")
  });

  const uploadImage = async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileType", "venue_logo");
    console.log("file", file);

    try {
      const response = await CustomUploadProfilePic(formData);
      toast.success("File uploaded successfully");
      setLogoUrl(response.location);
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error(error);
    }
  };
  if (mapAddress) {
    const addressComponents = mapAddress ? mapAddress.address_components : undefined;
    // Extracting values from address_components
    const formattedAddress = addressComponents ? addressComponents
      .slice(0, 3) // First 3 values for address
      .map((component) => component.long_name)
      .join(", ") : null
  }

  return (
    <Formik
      initialValues={formValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values) => {
        if (mode === "add") {
          handleAddVenue(values);
        } else {
          handleUpdateVenue(values);
        }
      }}
    >
      {({
        handleChange,
        handleBlur,
        values,
        errors,
        touched,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <Form
          className="add-new-site"
          style={{ background: "white", padding: "30px" }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        >
          <h1 className="common-add">
            {mode === "add" ? "Add New Site" : "Update Site"}
          </h1>

          <div style={{ padding: "20px", width: "100%", paddingBottom: "0px" }}>
            {[
              {
                label: "",
                name: "entityName",
                type: "entityName",
              },
              {
                label: "",
                name: "hotelType",
                type: "hotelType",
              },
              { label: "Venue Name", name: "venueName", type: "text" },
              { label: "Venue Code", name: "venueCode", type: "text" },
              { label: "Contact", name: "contact", type: "text" },
              // { label: "Address", name: "address", type: "text" },
              // { label: "City", name: "city", type: "text" },
              // { label: "State", name: "state", type: "text" },
              // { label: "Post Code", name: "postalCode", type: "text" },
              // { label: "Country", name: "country", type: "text" },
              { label: "Email", name: "email", type: "email" },
              { label: "Feedback Email", name: "feedbackEmail", type: "email" },
              { label: "Audit Email", name: "auditEmail", type: "email" },
              { label: "Fix It Email", name: "fixItEmail", type: "email" },
              { label: "Report Email", name: "reportEmail", type: "email" },
              {
                label: "Description",
                name: "venueDescription",
                type: "Description",
              },
              { label: "Trading Hours", name: "tradingHours", type: "text" },
              { label: "Website", name: "website", type: "url" },
              { label: "Venue Price", name: "venuePrice", type: "number" },
              {
                label: "Venue Price Display",
                name: "venuePriceDisplay",
                type: "text",
              },
              {
                label: "PDF Description",
                name: "pdfDescription",
                type: "textarea",
              },
              { label: "Brief JSON", name: "briefJson", type: "Brief" },
              { label: "Latitude", name: "lat", type: "map" },
              { label: "Longitude", name: "long", type: "long" },
              { label: "Map", name: "", type: "googleMap" },
              // { label: "Address", name: "mapaddress", type: "text" },
              { label: "Star Ratings", name: "starRatings", type: "string" },
            ].map(({ label, name, type }) => (
              <div
                key={name}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "15px",
                  width: "100%",
                }}
              >
                <label
                  htmlFor={name}
                  style={{
                    marginRight: "10px",
                    minWidth: "200px",
                    textAlign: "right",
                  }}
                >
                  {label}
                  {type === "entityName" || type === "hotelType" ? "" : ":"}
                </label>
                {type === "entityName" ? (
                  <div
                    style={{
                      width: "calc(100% - 220px)",
                      marginBottom: "-15px",
                    }}
                  >
                    <Autocomplete
                      options={clientEntity}
                      value={
                        clientEntity.find(
                          (option: any) => option.value === entityName
                        ) || null
                      }
                      getOptionLabel={(option: any) => option.label}
                      onChange={(_, newValue) => {
                        handleEntityChange(newValue);
                        handleChange({
                          target: {
                            name: "entityName",
                            value: newValue?.value || "",
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          required
                          {...params}
                          label="Select Business"
                          error={
                            touched.entityName && Boolean(errors.entityName)
                          }
                          helperText={
                            touched.entityName && errors.entityName
                              ? String(errors.entityName)
                              : ""
                          }
                        />
                      )}
                      fullWidth
                      disabled={mode === "update"}
                    />
                  </div>
                ) : type === "hotelType" ? (
                  <Autocomplete
                    options={entityType}
                    style={{ width: "calc(100% - 220px)", marginTop: "15px" }}
                    value={
                      entityType.find((option) => option.value === hotelType) ||
                      null
                    }
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setHotelType(newValue.value);
                        handleChange({
                          target: { name: "hotelType", value: newValue.value },
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required={mode === "update" ? false : true}
                        error={touched.hotelType && Boolean(errors.hotelType)}
                        helperText={
                          touched.hotelType && errors.hotelType
                            ? String(errors.hotelType)
                            : ""
                        }
                        label="Select Shop Type"
                      />
                    )}
                    fullWidth
                    disabled={mode === "update"}
                  />
                ) : type === "Brief" ? (
                  <div style={{ width: "calc(100% - 220px)" }}>
                    <Autocomplete
                      multiple
                      options={briefJsonNew}
                      value={briefJsonNew.filter((editor) =>
                        values.briefJson.includes(editor.value)
                      )}
                      getOptionLabel={(option) => option.label}
                      onChange={(event, newValue) => {
                        const selectedValues = newValue.map(
                          (option) => option.value
                        );
                        handleChange({
                          target: { name: "briefJson", value: selectedValues },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select briefJson"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                ) : type === "Description" ? (
                  <PatchedReactQuill
                    setEditorValue={setEditorValue}
                    editorValue={editorValue}
                  />
                ) : type === "textarea" ? (
                  <Field
                    as="textarea"
                    id={name}
                    name={name}
                    style={{ width: "calc(100% - 220px)", height: "80px" }}
                    value={values[name]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : type === "checkbox" ? (
                  <Field
                    type="checkbox"
                    id={name}
                    name={name}
                    style={{ width: "auto", margin: "0 10px" }}
                    checked={values[name]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : type === "map" ? (
                  <Field
                    type={type}
                    name={name}
                    style={{
                      width: "calc(100% - 220px)",
                      padding: "5px",
                    }}
                    value={latNew}
                  />
                ) : type === "long" ? (
                  <Field
                    type={type}
                    name={name}
                    style={{
                      width: "calc(100% - 220px)",
                      padding: "5px",
                    }}
                    value={lngNew}
                  />
                  )
                  // : type === "address" ? (
                  //   <Field
                  //     type={type}
                  //     name={name}
                  //     style={{
                  //       width: "calc(100% - 220px)",
                  //       padding: "5px",
                  //     }}
                  //     value={mapAddress}
                  //   />
                  // ) 
                  :
                 type === "number" ? (
                  <Field
                    type={type}
                    id={name}
                    name={name}
                    style={{
                      width: "calc(100% - 220px)",
                      padding: "5px",
                    }}
                    value={values[name]}
                    min="0"
                    onChange={(e) => {
                      const value = e.target.value;
                      if (
                        value === "" ||
                        (Number(value) >= 0 && /^\d*$/.test(value))
                      ) {
                        handleChange(e);
                      }
                    }}
                    onBlur={handleBlur}
                  />
                ) : type === "googleMap" ? (
                  <GoogleMapDialog
                    setLat={setLat}
                    lat={latNew}
                    lng={lngNew}
                    setLng={setLng}
                    setAddress={setAddress}
                  />
                ) : type === "string" ? (
                  <Autocomplete
                    multiple
                    id="star-ratings-multiple"
                    options={starRatingList}
                    style={{ marginTop: "15px", width: "calc(100% - 220px)" }}
                    value={starRatingList.filter((option) =>
                      values.starRatings.includes(option.value)
                    )}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValues) => {
                      console.log(newValues);
                      const selectedValues = newValues.map((option) => option.value).join(","); // ✅ Convert to comma-separated string
                  
                      handleChange({
                        target: { name: "starRatings", value: selectedValues },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Star Ratings"
                        error={touched.starRatings && Boolean(errors.starRatings)} // ✅ Pass error to TextField
                        helperText={
                          touched.starRatings && errors.starRatings
                            ? String(errors.starRatings)
                            : ""
                        } // ✅ Pass helper text to TextField
                      />
                    )}
                    fullWidth
                  />
                ) : (
                  <div style={{ width: "calc(100% - 220px)" }}>
                    <Field
                      type={type}
                      id={name}
                      name={name}
                      style={{
                        width: "100%",
                        padding: "5px",
                      }}
                      value={values[name]}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required={
                        name === "venueName" ||
                          name === "venueCode" ||
                          name === "contact" ||
                          name === "email"
                          ? true
                          : false
                      }
                      className={`${(name === "venueName" &&
                          errors.venueName &&
                          touched.venueName) ||
                          (name === "venueCode" &&
                            errors.venueCode &&
                            touched.venueCode) ||
                          (name === "contact" &&
                            errors.contact &&
                            touched.contact) ||
                          (name === "email" && errors.email && touched.email)
                          ? "border-rad"
                          : ""
                        }`}
                    />
                    {["venueName", "venueCode", "contact", "email"].map(
                      (field) =>
                        name === field &&
                        touched[field] &&
                        errors[field] && (
                          <div
                            key={field}
                            style={{
                              color: "#d32f2f",
                              fontSize: "12px",
                              marginLeft: "15px",
                            }}
                          >
                            {String(errors[field])}
                          </div>
                        )
                    )}
                  </div>
                )}
              </div>
            ))}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "15px",
                width: "100%",
              }}
            >
              <label
                htmlFor="venueLogo"
                style={{
                  marginRight: "10px",
                  minWidth: "200px",
                  textAlign: "right",
                }}
              >
                Venue Logo:
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={(event) => {
                  const file = event.target.files
                    ? event.target.files[0]
                    : null;
                  if (file) {
                    const fileReader = new FileReader();
                    fileReader.onloadend = () => {
                      setPreview(fileReader.result as string);
                    };
                    uploadImage(file);
                    fileReader.readAsDataURL(file);
                  }
                }}
                style={{ width: "calc(100% - 220px)" }}
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "15px",
                width: "100%",
              }}
            >
              <label
                htmlFor="locationType"
                style={{
                  marginRight: "10px",
                  minWidth: "200px",
                  textAlign: "right",
                }}
              >
                Location Type:
              </label>
              <FormControl
                error={touched.locationType && Boolean(errors.locationType)}
                style={{ width: "calc(100% - 220px)" }}
              >
                <RadioGroup
                  style={{
                    display: "flex",
                    gap: "0px",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  name="locationType"
                  value={values.locationType}
                  onChange={(e) => {
                    setFieldValue("locationType", e.target.value);
                  }}
                >
                  <Radio value="city" />
                  Metro
                  <Radio style={{ marginLeft: "20px" }} value="region" />
                  Regional
                </RadioGroup>
                {touched.locationType && errors.locationType && (
                  <FormHelperText style={{ color: "#d32f2f", marginLeft: "0" }}>
                    {String(errors.locationType)}
                  </FormHelperText>
                )}
              </FormControl>
            </div>

            {logoUrl && (
              <div style={{ margin: "20px" }} className="text-center">
                <img
                  src={logoUrl || "/placeholder.svg"}
                  alt="Venue Logo Preview"
                  style={{
                    maxWidth: "200px",
                    maxHeight: "200px",
                    objectFit: "contain",
                  }}
                />
              </div>
            )}

            <div className="text-center">
              <Button
                style={{ padding: "9px 70px" }}
                type="submit"
                onClick={() => handleSubmit()}
                variant="contained"
                color="primary"
                className="table-row-background"
                disabled={isSubmitting}
              >
                {mode === "add" ? "Add Site" : "Update Site"}
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddOrUpdateVenue;
