import React, { useEffect, useState } from "react";
import { Box, Typography, TextField, Button, Divider, IconButton, MenuItem, Checkbox, Grid, Select, FormControl, InputLabel } from "@mui/material";
import * as Yup from "yup";
import DeleteIcon from "@mui/icons-material/Delete";
import '../../../assets/ExtraStyle.css'
import { useFormik } from "formik";
import { AddNewQuestion, GetCategories, GetEntities, UpdateQuestion } from "../../../apiService/Core/Apicall.tsx";
import { useLocation, useNavigate } from "react-router-dom";

interface Option {
    id: number;
    text: string;
    commentEnabled: boolean;
    minCommentLength: string;
}
interface FormValues {
    questions: string;
    entityTypeId: number
    categoryId: number
    isPhotoRequire: boolean
    isCommentBox: boolean
    minPhoto: number
    maxScore: number
    displayOrder: 1
    isScore: boolean
    questionGroup: string
    questionDescription: string
    questionHint: string
    qType: string
    minChar: number
    createdBy: number
    questionWeightage: number
    qOptionJson: Array<{
        optionId: number;
        value: string;
        optionScore: number;
        optionImage: number;
        optionComment: string;
        minChar: number;
    }>
}


const CreateNewQuestion: React.FC = () => {
    const location = useLocation();
    const questionData = location.state?.questionData;
    const [category, setcategorey] = useState<any>([])
    const [entity, setentity] = useState<any>('')
    const [ShowError, setShowError] = useState<boolean>(false)
    const navigate = useNavigate()
    // console.log(" questionData?.qOptionJson",  questionData?.qOptionJson);

    const addOption = () => {
        formik.setFieldValue("qOptionJson", [
            ...formik.values.qOptionJson,
            {
                optionId: formik.values.qOptionJson.length + 1,
                value: '',
                optionScore: 0,
                optionImage: 0,
                optionComment: "",
                minChar: 0
            }
        ]);
    };
    const parseHtmlToText = (htmlString: string) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, "text/html");
        return doc.body.textContent || "";
    };
    useEffect(() => {
        const fetchcategory = async () => {
            try {
                const categories = await GetCategories()
                setcategorey(categories.data)
            }
            catch (error) {
                alert(error)
            }

        }
        const fetchentities = async () => {
            try {
                const entities = await GetEntities()
                setentity(entities.data)
            }
            catch (error) {
                alert(error)
            }
        }
        fetchcategory()
        fetchentities()
    }, [])


    const initialValues: FormValues = {
        questions: (questionData && parseHtmlToText(questionData?.questions)) || '',
        entityTypeId: (questionData?.entityTypeId?.entityTypeId) || 0,
        categoryId: (questionData?.categoryId?.categoryId) || 0,
        qType: (questionData?.qType) || 'R',
        displayOrder: (questionData?.displayOrder) || 1,
        maxScore: (questionData?.maxScore) || 0,
        questionDescription: (questionData?.questionDescription) || '',
        questionHint: (questionData?.questionHint) || '',
        questionWeightage: (questionData?.questionWeightage) || 0,
        isPhotoRequire: (questionData?.isPhotoRequire) || false,
        isCommentBox: (questionData?.isCommentBox) || false,
        minPhoto: (questionData?.minPhoto) || 0,
        questionGroup: 'No Needed',
        isScore: (questionData?.isScore) || false,
        minChar: (questionData?.minChar) || 0,
        createdBy: (questionData?.cretedBy) || 0,
        qOptionJson: questionData?.qOptionJson.length > 2 ? JSON.parse(questionData?.qOptionJson) : [
            {
                optionId: 0,
                value: '',
                optionScore: 0,
                optionImage: 0,
                optionComment: "",
                minChar: 0
            }
        ]
    };
    const validationSchema = Yup.object({
        questions: Yup.string().required("Enter question name"),
        questionHint: Yup.string().required("Enter question name"),
        questionDescription: Yup.string().required("Enter question name"),
        qOptionJson: Yup.array().of(
            Yup.object().shape({
                optionId: Yup.number().required("Option ID is required"),
                value: Yup.string().when("optionId", {
                    is: (val: number | undefined) => !!val,
                    then: (schema) => schema.required("Option Value is required"),
                }),
            })
        ),

    })
    useEffect(() => {
        if (category.length > 0 && !category.some(cat => cat.categoryId === formik.values.categoryId)) {
            formik.setFieldValue("categoryId", category[0].categoryId);
        }
        if (entity.length > 0 && !entity.some(ent => ent.entityTypeId === formik.values.entityTypeId)) {
            formik.setFieldValue("entityTypeId", entity[0].entityTypeId);
        }
    }, [category, entity]);


    const onSubmit = async (values: FormValues) => {
        const updatedValues = {
            ...values,
            ...(questionData ? { questionId: questionData.questionId } : {}),
            isScore: values.maxScore > 0,
            maxScore: Number(values.maxScore),
            minChar: Number(values.minChar),
            minPhoto: Number(values.minPhoto),
            questionWeightage: Number(values.questionWeightage),
            createdBy: Number(localStorage.getItem('createdBy')),
            isCommentBox: values.qType === "T" ? true : false,
            qOptionJson: values.qType === "R" ? values.qOptionJson : [],
        };

        const submitData = await (questionData?.questionId ? UpdateQuestion(updatedValues) : AddNewQuestion(updatedValues))
        console.log(updatedValues)
        alert(submitData.message)
        navigate('/admin/question-bank')
    }
    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: true,
        validateOnBlur: true,
        onSubmit
    });

    return (
        <Box className="box-container1 extra" minHeight="calc(100vh - 180px)" p={3} >
            {/* Header */}
            < form onSubmit={formik.handleSubmit} >
                <Box className='d-flex justify-content-between align-items-center mb-2'>
                    <Typography variant="h6" >
                        Create New Question
                    </Typography>
                    <Button className="bg-grey transform px-5 text-light" type="submit" onClick={() => { setShowError(true) }}>
                        Save
                    </Button>
                </Box>

                <Divider className="w-100 my-2 mb-3" />

                <FormControl className="w-100 my-3 mt-4 ">
                    <InputLabel shrink={false}>Your Question</InputLabel>
                    <TextField
                        className="w-100"
                        placeholder="Write your Question here..."
                        variant="outlined"
                        fullWidth
                        value={formik.values.questions}
                        onChange={(e) => formik.setFieldValue('questions', e.target.value)}
                    />
                    {ShowError && formik.errors.questions && <Typography className="text-danger">{formik.errors.questions}</Typography>}

                </FormControl>

                <Box className="d-flex w-100 my-4  flex-wrap" gap={1}>
                    <FormControl fullWidth className="w-33">
                        <InputLabel id="demo-simple-select-label" shrink={false}>Category</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formik.values.categoryId}
                            onChange={(e) => formik.setFieldValue('categoryId', e.target.value)}
                        >
                            {category.length > 0 &&
                                category.map((cat) => (
                                    <MenuItem value={cat.categoryId} key={cat.categoryId}>
                                        {cat.categoryName}
                                    </MenuItem>
                                ))
                            }

                        </Select>
                    </FormControl>
                    < FormControl fullWidth className="w-33">
                        <InputLabel id="demo-simple-select-label" shrink={false}>Business Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formik.values.entityTypeId}
                            onChange={(e) => formik.setFieldValue('entityTypeId', e.target.value)}
                        >
                            {entity.length > 0 &&
                                entity.map((cat) => (
                                    <MenuItem value={cat.entityTypeId} key={cat.entityTypeId}>
                                        {cat.entityTypeName}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    < FormControl fullWidth className="w-33">
                        <InputLabel id="demo-simple-select-label3" shrink={false}>Answer Type</InputLabel>
                        <Select
                            labelId="demo-simple-select-label3"
                            id="demo-simple-select-label3"
                            value={formik.values.qType}
                            onChange={(e) => formik.setFieldValue('qType', e.target.value)}
                        >
                            <MenuItem value="R">Radio Selection</MenuItem>
                            <MenuItem value="T">TextBox</MenuItem>
                        </Select>
                    </FormControl>
                    < FormControl fullWidth className="w-33">
                        <InputLabel id="demo-simple-select-label" shrink={false}>Score</InputLabel>
                        <TextField onBlur={(e) => {
                            const value = parseInt(e.target.value, 10);
                            if (isNaN(value) || value < 0) {
                                alert('Number Should be positive')
                                formik.setFieldValue('maxScore', '0');
                            }
                        }} inputProps={{ min: 0 }} type="number" value={formik.values.maxScore} onChange={(e) => { formik.setFieldValue('maxScore', e.target.value) }} />
                    </FormControl>
                    < FormControl fullWidth className="w-33">
                        <InputLabel id="demo-simple-select-label" shrink={false}>Question Weightage</InputLabel>
                        <TextField
                            onBlur={(e) => {
                                const value = parseInt(e.target.value, 10);
                                if (isNaN(value) || value < 0) {
                                    alert('Number Should be positive')
                                    formik.setFieldValue('questionWeightage', '0');
                                }
                            }}
                            inputProps={{ min: 0 }} type="number" value={formik.values.questionWeightage} onChange={(e) => { formik.setFieldValue('questionWeightage', e.target.value) }} />
                    </FormControl>
                    <FormControl className="w-33" fullWidth>
                        <InputLabel shrink={false}>
                            Will this question have an upload Image/File option?
                        </InputLabel>
                        <Select
                            {...formik.getFieldProps("isPhotoRequire")}
                            onChange={(event) => formik.setFieldValue("isPhotoRequire", event.target.value === "true")}
                            value={formik.values.isPhotoRequire}
                        >
                            <MenuItem value={"true"}>Yes</MenuItem>
                            <MenuItem value={"false"}>No</MenuItem>
                        </Select>
                    </FormControl>
                    {formik.values.isPhotoRequire === true && (
                        <FormControl fullWidth className="w-33">
                            <InputLabel shrink={false}>Minimum Number of Images to UploadInputLabel</InputLabel>
                            <TextField
                                onBlur={(e) => {
                                    const value = parseInt(e.target.value, 10);
                                    if (isNaN(value) || value < 0) {
                                        alert('Number Should be positive')
                                        formik.setFieldValue('minChar', '0');
                                    }
                                }}
                                inputProps={{ min: 0 }} type="number" value={formik.values.minPhoto} onChange={(e) => { formik.setFieldValue('minPhoto', e.target.value) }} />

                        </FormControl>
                    )}
                    {formik.values.qType === "T" && (
                        <FormControl fullWidth className="w-33">
                            <InputLabel shrink={false}>Min. Characters for Comment</InputLabel>
                            <TextField
                                onBlur={(e) => {
                                    const value = parseInt(e.target.value, 10);
                                    if (isNaN(value) || value < 0) {
                                        alert('Number Should be positive')
                                        formik.setFieldValue('minChar', '0');
                                    }
                                }}
                                inputProps={{ min: 0 }} type="number" value={formik.values.minChar} onChange={(e) => { formik.setFieldValue('minChar', e.target.value) }} />

                        </FormControl>
                    )}
                    < FormControl fullWidth className="w-33">
                        <InputLabel id="demo-simple-select-label" shrink={false}>Description</InputLabel>
                        <TextField
                            value={formik.values.questionDescription} onChange={(e) => { formik.setFieldValue('questionDescription', e.target.value) }} />
                    </FormControl>
                    < FormControl fullWidth className="w-33">
                        <InputLabel id="demo-simple-select-label" shrink={false}>Hint</InputLabel>
                        <TextField
                            value={formik.values.questionHint} onChange={(e) => { formik.setFieldValue('questionHint', e.target.value) }} />
                    </FormControl>

                </Box>
                
                {
                    (formik.values.qType === "R") && (
                        <>
                            <Box
                                width="100%"
                                sx={{
                                    borderBottom: "3px dashed #D3D3D3",
                                    opacity: 1,
                                    marginY: 2,
                                }}
                            />
                            <Box className='d-flex justify-content-between w-100 align-items-center my-2 mb-5'>
                                <Typography variant="h6" >
                                    Options for Answers
                                </Typography>
                                <Button
                                    onClick={addOption}
                                    className="bg-grey transform text-light px-4"
                                >
                                    + New Option
                                </Button>
                            </Box>
                            <Grid container spacing={4}>
                                {formik.values.qOptionJson.map((option, index) => {
                                    const optionErrors = (formik.errors.qOptionJson?.[index] as any) || {};
                                    const optionTouched = (formik.touched.qOptionJson?.[index] as any) || {};
                                    return (

                                        <Grid item xs={12} sm={6} key={option.optionId + 1}>
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                                alignItems="stretch"

                                                borderRadius="5px"
                                                border='1px solid #a3a3a3'
                                            >
                                                <Box className="d-flex align-items-stretch">
                                                    <FormControl fullWidth sx={{ "& fieldset": "0px !important" }}>
                                                        <InputLabel shrink={false}>Option - {index + 1}</InputLabel>
                                                        <TextField
                                                            value={parseHtmlToText(option.value)}
                                                            onChange={(e) => {
                                                                const updatedOptions = formik.values.qOptionJson.map((opt) =>
                                                                    opt.optionId === option.optionId ? { ...opt, value: e.target.value } : opt
                                                                );
                                                                formik.setFieldValue("qOptionJson", updatedOptions);
                                                            }}
                                                            placeholder="Enter Option"
                                                            onBlur={() => formik.setFieldTouched(`qOptionJson[${index}].value`, true)}
                                                            error={optionTouched.value && !!optionErrors.value}
                                                        />
                                                        {ShowError && optionTouched.value && optionErrors.value && (
                                                            <Typography className="text-danger">{optionErrors.value}</Typography>
                                                        )}
                                                    </FormControl>
                                                    <Box gap={1} className="d-flex  bg-grey py-2 text-light px-4 align-items-center justify-content-between" width='auto'>
                                                        <Checkbox
                                                            checked={option.optionComment === "yes"}
                                                            onChange={() => {
                                                                const updatedOptions = formik.values.qOptionJson.map((opt) =>
                                                                    opt.optionId === option.optionId
                                                                        ? { ...opt, optionComment: opt.optionComment === "yes" ? "" : "yes" }
                                                                        : opt
                                                                );
                                                                formik.setFieldValue("qOptionJson", updatedOptions);
                                                            }}
                                                            sx={{ color: "white", p: 0 }}
                                                        />
                                                        <Typography fontSize="14px" whiteSpace='nowrap'>Enable Comment</Typography>
                                                    </Box>
                                                    {
                                                        index !== 0 && (
                                                            <IconButton
                                                                onClick={() => {
                                                                    const updatedOptions = formik.values.qOptionJson.filter(
                                                                        (opt) => opt.optionId !== option.optionId
                                                                    );
                                                                    formik.setFieldValue("qOptionJson", updatedOptions);
                                                                }}
                                                                className="bg-danger text-light p-2"
                                                                sx={{ borderRadius: "0px" }}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        )
                                                    }

                                                </Box>

                                                <Box border="1px solid #ccc" className="w-100 p-2">
                                                    <Box className="w-100 mt-4 d-flex" gap={2}>

                                                        < FormControl fullWidth className="w-50">
                                                            <InputLabel id="demo-simple-select-label" shrink={false}>Option Score</InputLabel>
                                                            <TextField
                                                                type="number"
                                                                onBlur={(e) => {
                                                                    const value = parseInt(e.target.value, 10);
                                                                    if (isNaN(value) || value < 0) {
                                                                        alert('Number Should be positive')
                                                                        const updatedOptions = formik.values.qOptionJson.map((opt) =>
                                                                            opt.optionId === option.optionId ? { ...opt, optionScore: 0 } : opt
                                                                        );
                                                                        formik.setFieldValue("qOptionJson", updatedOptions);

                                                                    }
                                                                }}
                                                                inputProps={{ min: 0 }}
                                                                value={option.optionScore}
                                                                onChange={(e) => {
                                                                    const updatedOptions = formik.values.qOptionJson.map((opt) =>
                                                                        opt.optionId === option.optionId ? { ...opt, optionScore: Number(e.target.value) || 0 } : opt
                                                                    );
                                                                    formik.setFieldValue("qOptionJson", updatedOptions);
                                                                }}
                                                                placeholder="Enter Score"
                                                                error={optionTouched.optionScore && !!optionErrors.optionScore}

                                                            />
                                                            {ShowError && optionTouched.optionScore && optionErrors.optionScore && (
                                                                <Typography className="text-danger">{optionErrors.optionScore}</Typography>
                                                            )}

                                                        </FormControl>
                                                        < FormControl fullWidth className="w-50">
                                                            <InputLabel id="demo-simple-select-label" shrink={false}>Minimum Images</InputLabel>
                                                            <TextField
                                                                type="number"
                                                                onBlur={(e) => {
                                                                    const value = parseInt(e.target.value, 10);
                                                                    if (isNaN(value) || value < 0) {
                                                                        alert('Number Should be positive')
                                                                        const updatedOptions = formik.values.qOptionJson.map((opt) =>
                                                                            opt.optionId === option.optionId ? { ...opt, optionImage: 0 } : opt
                                                                        );
                                                                        formik.setFieldValue("qOptionJson", updatedOptions);

                                                                    }
                                                                }}
                                                                inputProps={{ min: 0 }}
                                                                value={option.optionImage}
                                                                onChange={(e) => {
                                                                    const updatedOptions = formik.values.qOptionJson.map((opt) =>
                                                                        opt.optionId === option.optionId ? { ...opt, optionImage: Number(e.target.value) || 0 } : opt
                                                                    );
                                                                    formik.setFieldValue("qOptionJson", updatedOptions);
                                                                }}
                                                                placeholder="Enter Score"
                                                                error={optionTouched.optionImage && !!optionErrors.optionImage}

                                                            />
                                                            {ShowError && optionTouched.optionImage && optionErrors.optionImage && (
                                                                <Typography className="text-danger">{optionErrors.optionImage}</Typography>
                                                            )}

                                                        </FormControl>
                                                        {option.optionComment === 'yes' && (
                                                            <FormControl fullWidth className="w-50">
                                                                <InputLabel shrink={false}>Min. Characters for Comment</InputLabel>
                                                                <TextField
                                                                    type="number"
                                                                    onBlur={(e) => {
                                                                        const value = parseInt(e.target.value, 10);
                                                                        if (isNaN(value) || value < 0) {
                                                                            alert('Number Should be positive')
                                                                            const updatedOptions = formik.values.qOptionJson.map((opt) =>
                                                                                opt.optionId === option.optionId
                                                                                    ? { ...opt, minChar: 0 }
                                                                                    : opt
                                                                            );
                                                                            formik.setFieldValue("qOptionJson", updatedOptions);

                                                                        }
                                                                    }}
                                                                    inputProps={{ min: 0 }}
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    value={option.minChar}
                                                                    onChange={(e) => {
                                                                        const updatedOptions = formik.values.qOptionJson.map((opt) =>
                                                                            opt.optionId === option.optionId
                                                                                ? { ...opt, minChar: Number(e.target.value) || 0 }
                                                                                : opt
                                                                        );
                                                                        formik.setFieldValue("qOptionJson", updatedOptions);
                                                                    }}
                                                                    placeholder="50"
                                                                    error={optionTouched.minChar && !!optionErrors.minChar}

                                                                />
                                                                {ShowError && optionTouched.minChar && optionErrors.minChar && (
                                                                    <Typography className="text-danger">{optionErrors.minChar}</Typography>
                                                                )}
                                                            </FormControl>
                                                        )}
                                                    </Box>
                                                </Box>

                                            </Box>
                                        </Grid>
                                    )
                                })}

                            </Grid>
                        </>
                    )
                }
                <Box
                    width="100%"
                    sx={{
                        borderBottom: "3px dashed #D3D3D3",
                        opacity: 1,
                        marginY: 2,
                        mt: 4
                    }}
                />

            </form >

        </Box >
    );
};

export default CreateNewQuestion;
