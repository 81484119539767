import React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  FormControl,
  IconButton,
  Stack,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  DialogTitle,
  Checkbox,
  type SelectChangeEvent,
  Pagination,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import "react-datepicker/dist/react-datepicker.css";
import {
  ApiGetAllocations,
  ApiGetVisitTimes,
  ApiGetVenueList,
  ApiGetClientEntities,
  ApiGetAppliedAllocations,
  ApiPostUpdateAppliedAllocationStatus,
} from "../../../apiService/Core/Apicall.tsx";
import { toast } from "react-toastify";
import moment from "moment";
import useDebounce from "../CreateAllocations/UseDebounce.tsx";

interface JobAllocation {
  id: Number;
  name: string;
  address: string;
  postalCode: string;
  TradeHouse: string;
  webSite: string;
}

export default function ApproveAllocation() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [allocations, setAllocations] = useState<JobAllocation[]>([]);
  const [startDate, setStartDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");
  const [bulkCreateValue, setBulkCreateValue] = useState("Bulk Create");
  const [monthly, setMonthly] = useState([]);
  const [every, setEvery] = useState("");
  const [visitTimes, setVisitTimes] = useState<any>([]);
  const [selectedValues, setSelectedValues] = useState("");
  const [venueName, setVenueName] = useState<any>([]);
  const [venueNameValue, setVenueNameValue] = useState<any>(null);
  const [clientEntityList, setClientEntityList] = useState<any>([]);
  const [clientEntityId, setClientEntityId] = useState<string | null>(null);
  const [appliedAllocation, setAppliedAllocation] = useState<any>([]);
  const debouncedClientEntityId = useDebounce(clientEntityId, 500);
  const debouncedVenue = useDebounce(venueNameValue, 700);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  // Get the current slice of allocations for the page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentAllocations = allocations.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handlePrevMonth = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(
        prevDate.getFullYear(),
        prevDate.getMonth() - 1,
        1
      );
      const jobMonth = newDate.getMonth() + 1;
      const jobYear = newDate.getFullYear();
      //   ApiCallGetAllocations(jobMonth, jobYear)
      handleGetAppliedAllocation(jobMonth, jobYear);
      return newDate;
    });
  };

  const handleGetClientList = async () => {
    const data = "";
    try {
      const res: any = await ApiGetClientEntities(data);
      const response = res?.data.map((item) => ({
        label: item.entityName,
        value: item.clientEntityId,
      }));
      setClientEntityList(response);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const handleNextMonth = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(
        prevDate.getFullYear(),
        prevDate.getMonth() + 1,
        1
      );
      const jobMonth = newDate.getMonth() + 1;
      const jobYear = newDate.getFullYear();
      //   ApiCallGetAllocations(jobMonth, jobYear)
      handleGetAppliedAllocation(jobMonth, jobYear);
      return newDate;
    });
  };
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = new Date(event.target.value + "-01");
    setCurrentDate(newDate);
  };

  const formatDate = (date: Date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}`;
  };

  const mmOptions = ["MM", "ALDI", "BLOOMS"];

  const handleSelectChangeVenue = (e) => {
    setSelectedValues(e.target.value);
  };
  useEffect(() => {
    const fetchData = async () => {
      const currentDate = new Date();
      const jobMonth = currentDate.getMonth() + 1;
      const jobYear = currentDate.getFullYear();
      //   await ApiCallGetAllocations(jobMonth, jobYear, debouncedVenue)
      //await handleGetVisitTimeList();
      await ApiCallGetVenueList();
      await handleGetAppliedAllocation(jobMonth, jobYear);
      await handleGetClientList();
    };
    fetchData();
    return () => {};
  }, [debouncedVenue]);

  useEffect(() => {
    const fetchData = async () => {
      await handleGetVisitTimeList();
    };
    fetchData();
    return () => {};
  }, [appliedAllocation]);

  const ApiCallGetVenueList = async (debouncedClientEntityId?: any) => {
    const data = `?clientEntityId=${clientEntityId}&offset=0&limit=500`;
    const res = await ApiGetVenueList(data);
    const response = res?.data.map((item) => ({
      label: item.venueName,
      value: item.venueId,
    }));
    setVenueName(response);
  };

  const handleGetVisitTimeList = async (selectedVisitTime?: any) => {
    try {
      const response: any = await ApiGetVisitTimes();
      setVisitTimes(response?.data);
      console.log("response star ", response?.data);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };
  useEffect(() => {
    if (debouncedClientEntityId) {
      ApiCallGetVenueList(debouncedClientEntityId); // Call API only when debounced value changes
    }
    else {
      setVenueName([]); // Clear venue list when business is cleared
      setVenueNameValue(null); // Also clear selected venue
    }
  }, [debouncedClientEntityId]);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleAddedAllocations = async () => {
    if (selectedValues === "") {
      toast.error("Select Value");
      return;
    } else if (bulkCreateValue === "Bulk Create") {
      toast.error("Select Bulk Create");
      return;
    }
    if (bulkCreateValue === "Custom") {
      if (startDate === "") {
        toast.error("Select Start Date");
        return;
      }
    }

    const param = {
      year:
        bulkCreateValue === "Custom"
          ? parseInt(moment(startDate, "DD/MM/YYYY").format("YYYY"), 10)
          : bulkCreateValue,
      venueId: selectedValues,
      durationType: bulkCreateValue === "Custom" ? "custom_month" : "year",
      months: monthly,
    };
    try {
      console.log("Params", param);
      //   const res = await ApiPostCreateAllocations(param)
      toast.success("Allocations Added successfully!");
      setBulkCreateValue("Bulk Create");
      setMonthly([]);
      setEvery("");
    } catch (error: any) {
      console.log("Error during:", error);
      toast.error(error);
    }
  };
  const handleClearData = () => {
    handleAddedAllocations();
  };

  const handleGetAppliedAllocation = async (
    jobMonth?: number,
    jobYear?: number
  ) => {
    const data =
      `?offset=0&limit=10&allocationStatus=shopper_applied` +
      (venueNameValue ? `&venueId=${venueNameValue}` : "") +
      `&jobMonth=${jobMonth}&jobYear=${jobYear}`;

    try {
      const res: any = await ApiGetAllocations(data);
      setAllocations(res.data);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const handleGetShopperAppliedAllocation = async (allocation: any) => {
    const data = `?offset=0&limit=10&allocationStatus=shopper_applied&allocationId=${allocation.allocationId}`;
    try {
      const res: any = await ApiGetAppliedAllocations(data);
      setAppliedAllocation(res.data);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const handleUpdateAppliedAllocation = async (shopperApplied, status) => {
    const data = {
      userId: shopperApplied.userId.userId,
      allocationId: shopperApplied.allocationId.allocationId,
      status: status,
      modifiedBy: 1,
    };
    try {
      const res: any = await ApiPostUpdateAppliedAllocationStatus(data);
      handleClose();
      toast.success("Allocation Approved successfully");
      handleGetAppliedAllocation();
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const handelInvite = (stateData: any) => {
    sessionStorage.setItem("inviteShopperState", JSON.stringify(stateData));
    const url = `/admin/allocations/Invite-Shopper`;
    window.open(url, "_blank");
  };

  return (
    <div>
      <Box sx={{ p: 4 }} style={{ background: "white" }}>
        <Stack spacing={3}>
          <Box sx={{ width: "100%" }}>
            <h1 className="common-manage-h1" style={{ textAlign: "center" }}>
              Approve Allocation
            </h1>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "-70px",
                marginTop: "23px",
              }}
            >
              <Stack direction="row" spacing={2}>
                <FormControl size="small" sx={{ width: 180 }}>
                  <Autocomplete
                    disablePortal
                    id="beacon-autocomplete"
                    options={clientEntityList}
                    onChange={(event, newValue: any) => {
                      setClientEntityId(newValue?.value || null);
                      if (!newValue) {
                        // Clear venue when business is cleared
                        setVenueNameValue(null);
                      }
                    }}
                    value={
                      clientEntityList.find(
                        (option) => option.value === clientEntityId
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Select Business" />
                    )}
                    size="small"
                  />
                </FormControl>
                <FormControl size="small" sx={{ width: 180 }}>
                  <Autocomplete
                    disablePortal
                    id="mm-autocomplete"
                    options={venueName}
                    value={venueName.find((option) => option.value === venueNameValue) || null}
                    onChange={(event, newValue) => {
                      setVenueNameValue(newValue?.value);
                    }}
                    defaultValue=""
                    renderInput={(params) => (
                      <TextField {...params} label="Search Venue" />
                    )}
                    size="small"
                  />
                </FormControl>
              </Stack>
              <Stack direction="row" spacing={2}>
                <FormControl size="small" sx={{ width: 180 }}>
                  <Autocomplete
                    disablePortal
                    id="mm-autocomplete"
                    options={mmOptions}
                    defaultValue="MM"
                    renderInput={(params) => (
                      <TextField {...params} label="Select Client" />
                    )}
                    size="small"
                  />
                </FormControl>
              </Stack>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "70px",
            }}
          >
            <IconButton onClick={handlePrevMonth}>
              <ChevronLeftIcon />
            </IconButton>
            <Box sx={{ display: "flex", alignItems: "center", mx: 2 }}>
              <input
                type="month"
                value={formatDate(currentDate)}
                onChange={handleDateChange}
                className="primary-color"
                style={{
                  border: "none",
                  background: "none",
                  fontSize: "1.25rem",
                  fontWeight: "bold",
                  cursor: "pointer",
                  fontFamily: "inherit",
                }}
              />
            </Box>
            <IconButton onClick={handleNextMonth}>
              <ChevronRightIcon />
            </IconButton>
          </Box>

          <Grid
            container
            spacing={4}
            className="mt-5"
            style={{
              gap: "20px",
              paddingRight: "32px",
              flexWrap: "nowrap",
              gridTemplateColumns: "2fr 2fr",
              display: "grid",
            }}
          >
            {currentAllocations?.map((allocation: any, index) => (
              <Grid
                style={{
                  padding: "0px",
                  boxShadow: "none",
                  maxWidth: "100%",
                  width: "100%",
                }}
                item
                xs={12}
                md={6}
                key={index}
              >
                <Card
                  className="min-border-color"
                  style={{ boxShadow: "none" }}
                >
                  <CardContent>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ flexGrow: 1 }}>
                        <Typography variant="h6" gutterBottom>
                          {allocation?.venueId?.venueName}
                        </Typography>
                        <Typography>
                          {" "}
                          <b>Address:</b> {allocation?.venueId.address}
                        </Typography>
                        <Typography>
                          <b>Postcode:</b> {allocation?.venueId.postalCode}
                        </Typography>
                        <Typography>
                          <b>Trade House:</b> {allocation?.venueId.tradingHours}
                        </Typography>
                        <Typography>
                          <b>WebSite:</b> {allocation?.venueId.websiteURL}
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "16px",
                        }}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className="table-row-background"
                          style={{ marginBottom: "8px", width: "170px" }}
                          onClick={() => {
                            {
                              handleGetVisitTimeList(allocation);
                              handleGetShopperAppliedAllocation(allocation);
                              handleClickOpen();
                            }
                          }}
                        >
                          View Applications
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          size="small"
                          className="table-row-background"
                          style={{ width: "170px" }}
                          onClick={() => handelInvite(allocation)}
                        >
                          Invite Shopper
                        </Button>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Stack>

        <Dialog className="ApproveAllocation" open={open} onClose={handleClose}>
          <DialogContent className="dialog-content">
            <div
              style={{
                display: "flex ",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <DialogTitle className="dialog-title">
                Approve applications
              </DialogTitle>
              <div
                style={{
                  fontSize: "20px",
                  fontWeight: "700",
                  cursor: "pointer",
                }}
                onClick={handleClose}
              >
                X
              </div>
            </div>

            {/* Previous Shoppers Card */}
            <div className="previous-shoppers table-row-background">
              <h2 className="previous-shoppers__title">Previous Shoppers</h2>

              <div className="previous-shoppers__grid">
                <div className="previous-shoppers__item">
                  <div className="previous-shoppers__month">JAN-2025</div>
                  <div className="previous-shoppers__name">Josie</div>
                </div>
                <div className="previous-shoppers__item">
                  <div className="previous-shoppers__month">FEB-2025</div>
                  <div className="previous-shoppers__name">Christine1</div>
                </div>
              </div>
            </div>

            {/* Applications Table */}
            <div className="applications">
              <div className="applications__header table-row-background">
                <div className="applications__header-cell">Delete</div>
                <div className="applications__header-cell">Shopper Name</div>
                <div className="applications__header-cell">Date</div>
                <div className="applications__header-cell">Time</div>
                <div className="applications__header-cell">AirTasker?</div>
                <div className="applications__header-cell">Action</div>
              </div>

              {appliedAllocation.map((shopperApplied) => (
                <div className="applications__row">
                  <div className="applications__cell">
                    <button className="delete-button">X</button>
                  </div>
                  <div className="applications__cell ">
                    <span className="shopper-badge table-row-background">
                      {shopperApplied.userId.firstName}{" "}
                      {shopperApplied.userId.lastName}
                    </span>
                  </div>
                  <div
                    className="applications__cell"
                    style={{
                      background: "white",
                      textAlign: "center",
                      borderRadius: "5px",
                    }}
                  >
                    {new Date(
                      shopperApplied.selectedVisitDate
                    ).toLocaleDateString("en-GB")}
                  </div>
                  <div className="applications__cell">
                    {shopperApplied.selectedVisitTime.visitTimeInformation}
                  </div>
                  <div className="applications__cell">
                    <Checkbox />
                  </div>
                  <div className="applications__cell applications__actions">
                    <Button
                      className="action-button action-button--approve table-row-background"
                      onClick={() =>
                        handleUpdateAppliedAllocation(
                          shopperApplied,
                          "approved"
                        )
                      }
                    >
                      Approve
                    </Button>
                    <div style={{ position: "relative" }}>
                      <Button className="action-button action-button--approve table-row-background">
                        Re-Check
                      </Button>
                      {/* <span className='status-badge'>ON HOLD</span> */}
                    </div>
                    <div style={{ position: "relative" }}>
                      <Button className="action-button action-button--approve table-row-background">
                        On Hold
                      </Button>
                      {/* <span className='status-badge'>ON HOLD</span> */}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </DialogContent>
        </Dialog>

        {/* Pagination */}
        {allocations.length > itemsPerPage && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "20px",
            }}
          >
            <Pagination
              count={Math.ceil(allocations.length / itemsPerPage)}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        )}
      </Box>
    </div>
  );
}
