import React, { ReactNode, useState } from 'react'
import { Box, FormControl, Table, IconButton, InputLabel, MenuItem, Paper, Select, TableBody, TableCell, TableContainer, TableFooter, TablePagination, TableRow, Typography, useTheme, TableHead } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { Edit, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import edit from '../../../assets/images/editIcon.png'



interface Column {
    id: 'SiteName' | 'GroupName' | 'VenueName' | 'Month' | 'Createddate' | 'VisitTime' | 'Shopper' | 'Score' | 'Status' | 'Edit';
    label: string;
    width?: number;
    align?: 'center';
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    { id: 'SiteName', label: 'Site Name', width: 80 },
    { id: 'GroupName', label: 'Group Name', width: 350 },
    { id: 'VenueName', label: 'Venue Name', width: 300, },
    { id: 'Month', label: 'Month', width: 50, },
    { id: 'Createddate', label: 'Create date', width: 100, },
    { id: 'VisitTime', label: 'Visit Time', width: 150 },
    { id: 'Shopper', label: 'Shopper', width: 200 },
    { id: 'Score', label: 'Score', width: 50, },
    { id: 'Status', label: 'Status', width: 50, },
    { id: 'Edit', label: 'Edit', width: 50, },
];

interface Data {
    SiteName: string;
    GroupName: string;
    VenueName: string;
    Month: string;
    Createddate: string;
    VisitTime: string;
    Shopper: string;
    Score: number;
    Status: string;
    Edit: ReactNode;
}

function createData(
    SiteName: string,
    GroupName: string,
    VenueName: string,
    Month: string,
    Createddate: string,
    VisitTime: string,
    Shopper: string,
    Score: number,
    Status: string,
    Edit: ReactNode,
): Data {
    return {
        SiteName,
        GroupName,
        VenueName,
        Month,
        Createddate,
        VisitTime,
        Shopper,
        Score,
        Status,
        Edit,
    }
}

const rows = [
    createData('3/2025', 'Ravi Patel', 'Ravi Patel', '3/2025', '3/2025', '3/2025', '3/2025', 99, '3/2025', <Box className='w-50' component='img' src={edit}/>)
];



const AllReports = () => {
    const [selectedSite, setSelectedSite] = useState<string>('ravi')
    const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handlePrevMonth = () => {
        setSelectedDate((prev) => prev.subtract(1, "month"));
    };

    const handleNextMonth = () => {
        setSelectedDate((prev) => prev.add(1, "month"));
    };



    return (
        <Box className='extra bg-light' padding={3}>
            <Typography className='text-center' variant='h4' mb={5}>All Report Status</Typography>
            <Box className='row d-flex  justify-content-between'>
                <Box className='col-4 d-flex' gap={2}>
                    <FormControl className='col-6'>
                        <InputLabel id='site-name' shrink={false}>Site Name</InputLabel>
                        <Select labelId='site-name' value={selectedSite} fullWidth onChange={(e) => { setSelectedSite(e.target.value) }}>
                            <MenuItem value="ravi">Ravi</MenuItem>
                            <MenuItem value="raj">Raj</MenuItem>
                            <MenuItem value="jeel">Jeel</MenuItem>
                            <MenuItem value="avani">Avani</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl className='col-6'>
                        <InputLabel id='site-name' shrink={false}>Report Status</InputLabel>
                        <Select labelId='site-name' value={selectedSite} fullWidth onChange={(e) => { setSelectedSite(e.target.value) }}>
                            <MenuItem value="ravi">Ravi</MenuItem>
                            <MenuItem value="raj">Raj</MenuItem>
                            <MenuItem value="jeel">Jeel</MenuItem>
                            <MenuItem value="avani">Avani</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box
                        display="flex"
                        alignItems="center"
                        width='fit-content'
                        maxWidth='330px'
                        className='d-flex ms-auto mb-2 align-items-center month-calendar justify-content-start'
                    >
                        <IconButton onClick={handlePrevMonth} className='d-none d-md-block'>
                            <KeyboardArrowLeft />
                        </IconButton>

                        <DatePicker
                            views={["year", "month"]}
                            value={selectedDate}
                            onChange={(date) => date && setSelectedDate(date)}
                            slotProps={{
                                textField: { variant: 'standard' },
                            }}
                        />

                        <IconButton onClick={handleNextMonth} className='d-none d-md-block'>
                            <KeyboardArrowRight />
                        </IconButton>
                    </Box>
                </LocalizationProvider>
            </Box>
            <Paper sx={{ width: '100%', border:'1px solid transparent', overflow: 'hidden' }}>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        className='bg-grey text-light'
                                        key={column.id}
                                        align={column.align}
                                        style={{ width: column.width, }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.format && typeof value === 'number'
                                                            ? column.format(value)
                                                            : value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </Box>
    )
}

export default AllReports