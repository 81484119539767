import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import Switch from "@mui/material/Switch";
import {
  ApiGetAllRoles,
  ApiGetAdminList,
  ApiPutUpdateAdminRole,
  ApiPutUpdateAdminStatus,
  ApiGetShoppersApplication,
  ApiPutUpdateShopperApplicationStatus,
} from "../../../apiService/Core/Apicall.tsx";
import { toast } from "react-toastify";

const ShopperApplications: React.FC = () => {
  const [shopperApplications, setShopperApplications] = useState<any>([]);
  const navigate = useNavigate();
  const openEditAccessGroup = () => {
    navigate("/admin/edit-access-group");
  };
  const navigateToViewShopperApplication = (shopper) => {
    navigate("/admin/shoppers/view-shopper-application", {
      state: { shopperDetails: shopper },
    });
  };

  const updateApplicationStatus = async (shopper: any, updatedStatus: any) => {
    const data = {
      userId: shopper.userId,
      status: updatedStatus,
    };
    const response: any = await ApiPutUpdateShopperApplicationStatus(data);
    toast.success("Application Status updated successfully");
    await handleGetShoppers();
  };

  useEffect(() => {
    const fetchData = async () => {
      await handleGetShoppers();
    };
    fetchData();
    return () => {};
  }, []);

  const handleGetShoppers = async () => {
    const data: any = {};
    try {
      const response: any = await ApiGetShoppersApplication();
      setShopperApplications(response.data);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  return (
    <div className="manage-admin-container">
      <div className="manage-button">
        <h1 className="common-manage-h1">Shopper Applications</h1>
      </div>
      <Table>
        <TableHead>
          <TableRow className="table-row-background">
            {[
              "First Name",
              "Last Name",
              "Email",
              "View",
              "Approve",
              "Reject",
            ].map((item, index) => (
              <TableCell key={index} style={{ color: "#fff" }}>
                {item}
              </TableCell>
            ))}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {shopperApplications?.map((shopper) => (
            <TableRow>
              <TableCell>{shopper.firstName}</TableCell>
              <TableCell>{shopper.lastName}</TableCell>
              <TableCell>{shopper.emailId}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  className="table-row-background"
                  onClick={() => navigateToViewShopperApplication(shopper)}
                >
                  View Application
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  className="table-row-background"
                  onClick={() => updateApplicationStatus(shopper, "approve")}
                >
                  Approve
                </Button>
              </TableCell>

              <TableCell>
                <Button
                  variant="contained"
                  className="table-row-background"
                  onClick={() => updateApplicationStatus(shopper, "reject")}
                >
                  Reject
                </Button>
              </TableCell>
              <TableCell>
                <Button variant="contained" className="delete-transparent">
                  <DeleteIcon className="delete-icon" />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ShopperApplications;
