import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  Autocomplete,
  Box,
  Grid,
  FormControl
} from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
  ApiGetAllRoles,
  ApiGetClientEntities,
  ApiGetUserDetails,
  ApiPostCreateAdmin,
  ApiPutUpdateAdminDetails
} from '../../../apiService/Core/Apicall.tsx'

interface AddOrUpdateProps {
  initialValues?: {
    name: string
    username: string
    password: string
    email: string
    typeName: string
    type: string
    isActive: string
    groups: any[]
  }
  onSubmit: (values: any) => void
  mode: 'add' | 'update'
}
const AddOrUpdate: React.FC<AddOrUpdateProps> = ({
  initialValues = {
    name: '',
    username: '',
    password: '',
    email: '',
    type: '',
    typeName: '',
    isActive: 'active',
    groups: []
  },
  onSubmit
}) => {
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string().required('Name is required'),
      username: Yup.string().required('Username is required'),
      password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required'),
      email: Yup.string()
        .email('Invalid email address')
        .required('Email is required'),
      type: Yup.string().required('Type is required'),
      isActive: Yup.string().required('Status is required')
    }),
    onSubmit: values => {
      onSubmit(values)
      handleCallApi()
    }
  })

  const [statusId, setStatusId] = useState<number>()
  const [createdBy, setCreatedBy] = useState<any>()
  const [roles, setRoles] = useState<any>([])
  const [error, setError] = useState<any>()
  const [clientEntityList, setClientEntityList] = useState<any>([])
  const navigate = useNavigate()
  const { mode, id } = useParams<{ mode: 'add' | 'update'; id?: string }>()
  useEffect(() => {
    if (mode === 'update' && id) {
      console.log(`Fetching data for admin with ID: ${id}`)
    }
  }, [mode, id])
  const [userDetails, setDetails] = useState<any>({})
  const handleGetApiCallUsers = async () => {
    try {
      const response: any = await ApiGetUserDetails(id)
      if (response && response.data) {
        setDetails(response.data)
      }
    } catch (error) {
      console.error('Error during API call:', error)
    }
  }

  useEffect(() => {
    const storedUser: any = localStorage.getItem('userStatusId')
    if (storedUser) setStatusId(Number(storedUser))
    const createdUser: any = localStorage.getItem('createdBy')
    if (createdUser) setCreatedBy(createdUser)
    const fetchData = async () => {
      await handleGetApiCallRoles()
      await handleGetApiCallUsers()
      await handleGetClientList()
    }
    fetchData()
    return () => {}
  }, [])

  useEffect(() => {
    if (userDetails) {
      if (mode === 'update') {
        const entityIdsArray =
          userDetails.entityIds?.split(',').map((id: string) => {
            const matchedEntity = clientEntityList.find(
              (entity: any) => entity.clientEntityId === Number(id)
            )
            if (matchedEntity) {
              return {
                label: matchedEntity.entityName,
                roleId: matchedEntity.clientEntityId,
                value: matchedEntity.entityName
              }
            }
            return null
          }) || []

        const filteredEntities = entityIdsArray.filter(Boolean)
        formik.setValues({
          ...formik.values,
          name: userDetails.displayName,
          username: userDetails.userName,
          password: '',
          email: userDetails.emailId,
          type: userDetails?.role?.roleDisplayName,
          typeName: userDetails?.role?.roleId,
          isActive:
            userDetails?.userStatus?.userStatus === 'active' ? '1' : '0',
          groups: filteredEntities 
        })
      }
    }
  }, [userDetails, clientEntityList, mode])

  const typeOptions = roles.map(role => ({
    label: role.roleDisplayName,
    roleId: role.roleId,
    value: role.roleDisplayName
  }))

  const handleGetApiCallRoles = async () => {
    const data = {}
    try {
      const response: any = await ApiGetAllRoles(data)
      setRoles(response.data)
    } catch (error) {
      console.error('Error during API call:', error)
      toast.error('Error On fetching Roles List!')
    }
  }

  const handleCallApi = async () => {
    const valueNew:any[]  = formik.values.groups.map(item => item.roleId)
    const commaSeparated = valueNew.join(',')
    try {
      console.log(FormData)
      if (mode === 'add') {
        const FormData = {
          displayName: formik.values.name,
          userName: formik.values.username,
          password: formik.values.password,
          emailId: formik.values.email,
          userStatus: formik.values.isActive === 'active' ? statusId : 2,
          roleId: formik.values.typeName,
          createdBy: 11,
          entityIds: commaSeparated
        }
        const response: any = await ApiPostCreateAdmin(FormData)
        toast.success('Admin added successfully!')
        navigate(-1)
      } else {
        const FormData = {
          userId: id,
          displayName: formik.values.name,
          userName: formik.values.username,
          password: formik.values.password,
          emailId: formik.values.email,
          userStatus: formik.values.isActive === 'active' ? statusId : 2,
          role: formik.values.typeName,
          createdBy: 11,
          entityIds: commaSeparated,
          modifiedBy: 11
        }
        console.log('form data', FormData)
        const response: any = await ApiPutUpdateAdminDetails(FormData)
        toast.success('Admin updated successfully!')
        navigate(-1)
      }
    } catch (error) {
      console.error('Error during API call:', error)
      toast.error('Error On Add Or Update Admin!')
      setError(error?.response?.data.message)
    }
  }

  const handleGetClientList = async () => {
    const data = {}
    try {
      const response: any = await ApiGetClientEntities(data)
      setClientEntityList(response.data)
      console.log('rse store', response.data)
    } catch (error) {
      console.error('Error during API call:', error)
    }
  }

  const groupOptions = clientEntityList.map(role => ({
    label: role.entityName,
    roleId: role.clientEntityId,
    value: role.entityName
  }))
 
  return (
    <div>
      <Box
        component='form'
        onSubmit={formik.handleSubmit}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          maxWidth: 800,
          margin: 'auto',
          padding: 3,
          backgroundColor: '#f9f9f9',
          borderRadius: 2,
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)'
        }}
      >
        <h1 className='common-add'>
          {mode === 'add' ? 'Add New Admin' : 'Update Admin'}
        </h1>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label='Name'
              name='name'
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label='Username'
              name='username'
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.username && Boolean(formik.errors.username)}
              helperText={formik.touched.username && formik.errors.username}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              label='Password'
              name='password'
              type='password'
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label='Email'
              name='email'
              type='email'
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              options={typeOptions}
              getOptionLabel={option => option.label}
              value={
                typeOptions.find(
                  option =>
                    option.value === formik.values.type &&
                    option.roleId === formik.values.typeName
                ) || null
              }
              onChange={(event, value) => {
                formik.setFieldValue('type', value?.value || '')
                formik.setFieldValue('typeName', value?.roleId || '')
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Type'
                  error={formik.touched.type && Boolean(formik.errors.type)}
                  helperText={formik.touched.type && formik.errors.type}
                />
              )}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              multiple
              options={groupOptions}
              getOptionLabel={option => option.label}
              value={formik.values.groups}
              onChange={(event, value) => {
                const uniqueValue = value.filter(
                  (v, index, self) =>
                    index === self.findIndex(o => o.label === v.label)
                )
                formik.setFieldValue('groups', uniqueValue)
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Groups'
                  error={formik.touched.groups && Boolean(formik.errors.groups)}
                  helperText={formik.touched.groups && Boolean(formik.errors.groups)}
                />
              )}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl component='fieldset'>
              <FormLabel component='legend'>Active</FormLabel>
              <RadioGroup
                row
                name='isActive'
                value={formik.values.isActive}
                onChange={formik.handleChange}
              >
                <FormControlLabel
                  value='1'
                  control={<Radio />}
                  label='Active'
                />
                <FormControlLabel
                  value='0'
                  control={<Radio />}
                  label='Inactive'
                />
              </RadioGroup>
              {formik.touched.isActive && formik.errors.isActive && (
                <div style={{ color: 'red', fontSize: '0.8rem' }}>
                  {formik.errors.isActive}
                </div>
              )}
            </FormControl>
          </Grid>
        </Grid>
        <div style={{ color: 'red' }}>{error}</div>
        <Button
          type='submit'
          variant='contained'
          color='primary'
          className='table-row-background'
        >
          {mode === 'add' ? 'Add Admin' : 'Update Admin'}
        </Button>
      </Box>
    </div>
  )
}

export default AddOrUpdate
