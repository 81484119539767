export const visitDaysOptions = [
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
    { label: "Sunday", value: "Sunday" },
  ];

export const monthsData = [
    { id: 1, label: "January", value: "January" },
    { id: 2, label: "February", value: "February" },
    { id: 3, label: "March", value: "March" },
    { id: 4, label: "April", value: "April" },
    { id: 5, label: "May", value: "May" },
    { id: 6, label: "June", value: "June" },
    { id: 7, label: "July", value: "July" },
    { id: 8, label: "August", value: "August" },
    { id: 9, label: "September", value: "September" },
    { id: 10, label: "October", value: "October" },
    { id: 11, label: "November", value: "November" },
    { id: 12, label: "December", value: "December" },
  ];

  export const genderOptions = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
    { label: 'All', value: 'All' }
  ]

  export const jobTimeTypeOptions = [
    { label: 'Monthly', value: 'Monthly' },
    { label: 'Fortnightly', value: 'Fortnightly' }
  ]