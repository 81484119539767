let Domains;

Domains = {
  ALDI: 'aldi.complyco.com.au',
  BLOOMS: 'blooms.complyco.com.au',
  MM: 'complyco.com.au',
  ADMIN: 'admin.complyco.com.au',
  SHOPPER: 'shopper.complyco.com.au'
};

export default Domains;