import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { Button, Autocomplete, TextField } from "@mui/material";
import * as Yup from "yup";
import {
  ApiGetAdminList,
  ApiGetVenueList,
  ApiPutVenueUpdateEditors,
} from "../../../apiService/Core/Apicall.tsx";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface ViewEditorsVenueProps {
  onSubmit: (values: any) => void;
}

interface Editor {
  label: string;
  value: any;
}

interface Venue {
  label: string;
  value: string;
}

const ViewEditorsVenue: React.FC<ViewEditorsVenueProps> = ({ onSubmit }) => {
  const [venues, setVenues] = useState<Venue[]>([]);
  const [editors, setEditors] = useState<Editor[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  const venue = location?.state?.venue;
  const [venueId, setVenueId] = useState(venue?.venueId || "");

  const editorIdsString = venue?.editorIds;
  const editorIdsArray: number[] = editorIdsString
    ? editorIdsString.split(",").map((id) => parseInt(id.trim(), 10))
    : [];

  const [editorIds, setEditorIds] = useState(editorIdsArray || []);

  console.log("venue", venue);
  useEffect(() => {
    const handleGetEditType = async () => {
      try {
        const response: any = await ApiGetAdminList("");
        const transformedData = response.data.map((item: any) => ({
          value: item.userId,
          label: item.displayName,
        }));
        setEditors(transformedData);
      } catch (error) {
        console.error("Error during API call:", error);
      }
    };

    handleGetEditType();
    handleGetVenueList();
  }, []);

  const handleGetVenueList = async () => {
    const data = "?offset=0&limit=100";
    try {
      const response: any = await ApiGetVenueList(data);
      console.log("venue list", response);
      const transformedData = response.data.map((item: any) => ({
        value: item.venueId,
        label: item.venueName,
      }));
      setVenues(transformedData);
      console.log("Client entities:", response.data);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const validationSchema = Yup.object({
    business: Yup.string().required("Please select a business"),
    editors: Yup.array()
      .of(Yup.string())
      .min(1, "At least one editor must be selected"),
  });

  const handleSubmit = async (values: any) => {
    const commaSeparated =
      values.editors.length > 0 ? values.editors.join(",") : "";

    console.log("Editor IDs (comma separated):", commaSeparated);

    const data = {
      venueId: values.venue,
      editorIds: commaSeparated, // Send as a comma-separated string for the API
      createdBy: 11,
      modifiedBy: 11,
    };
    console.log(data);
    try {
      const res = await ApiPutVenueUpdateEditors(data);
      toast.success("View Editors updated successfully!");
      navigate(-1);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  return (
    <Formik
      initialValues={{
        venue: venueId,
        editors: editorIds,
      }}
      //   validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({ values, setFieldValue, errors, touched }) => (
        <Form style={{ background: "white", padding: "30px" }}>
          <h1 className="common-add">View Editors for Business</h1>

          {/* Business Selector */}
          <div className="form-group">
            <Autocomplete
              options={venues}
              value={
                venues.find((option) => option.value === values.venue) || null
              }
              getOptionLabel={(option) => option.label}
              onChange={(event, newValue) => {
                setFieldValue("business", newValue ? newValue.value : "");
              }}
              disabled
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Venue"
                  fullWidth
                  //   error={touched.business && Boolean(errors.business)}
                  //   helperText={touched.business && errors.business}
                />
              )}
            />
          </div>

          {/* Editors Multi-Select */}
          <div className="form-group" style={{ marginTop: "20px" }}>
            <Autocomplete
              multiple
              options={editors}
              getOptionLabel={(option) => option.label}
              value={editors.filter((editor) =>
                values.editors.includes(editor.value)
              )}
              onChange={(event, value) => {
                const selectedValues = value.map((editor) => editor.value);
                setFieldValue("editors", selectedValues); // Update editors array
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Editors"
                  fullWidth
                  error={touched.editors && Boolean(errors.editors)}
                  helperText={touched.editors && errors.editors}
                />
              )}
            />
          </div>

          {/* Submit Button */}
          <div className="text-center" style={{ marginTop: "30px" }}>
            <Button
              style={{ padding: "9px 70px" }}
              type="submit"
              variant="contained"
              color="primary"
              className="table-row-background"
            >
              Submit
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ViewEditorsVenue;
