import React, { useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Autocomplete,
  TextField
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'
import Switch from '@mui/material/Switch'
import {
  ApiGetAllRoles,
  ApiGetAdminList,
  ApiPutUpdateAdminRole,
  ApiPutUpdateAdminStatus
} from '../../../apiService/Core/Apicall.tsx'
import { toast } from 'react-toastify'
const ManageAdmin: React.FC = () => {
  const [adminUsers, setAdminUsers] = useState<any>([])
  const [roles, setRoles] = useState<any>([])
  const navigate = useNavigate()
  const openEditAccessGroup = () => {
    navigate('/admin/edit-access-group')
  }
  const addOrUpdateAdmin = (id?: number) => {
    if (id) {
      navigate(`/admin/add-or-update/update/${id}`)
    } else {
      navigate('/admin/add-or-update/add')
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      await handleGetApiCallRoles()
      await handleGetApiCall()
    }
    fetchData()
    return () => {}
  }, [])

  const handleGetApiCallRoles = async () => {
    const data: any = {}
    try {
      const response: any = await ApiGetAllRoles(data)
      setRoles(response.data)
    } catch (error) {
      console.error('Error during API call:', error)
    }
  }
  const handleGetApiCall = async () => {
    const data = '?offset=0&limit=100'
    try {
      const response: any = await ApiGetAdminList(data)
      setAdminUsers(response.data)
    } catch (error) {
      console.error('Error during API call:', error)
    }
  }

  const typeOptions = roles.map(role => ({
    label: role.roleDisplayName,
    value: role.roleName
  }))

  const handleChange = async (user, newStatus: string) => {
    try {
      const payload = {
        userId: user.userId,
        status: newStatus 
      }
      await ApiPutUpdateAdminStatus(payload)
      await handleGetApiCall()
    } catch (error) {
      console.error('Error updating user status:', error)
      toast.error('Failed to update user status')
    }
  }
  const handleTypeChange = async (
    event: any,
    newValue: any,
    userId: number
  ) => {
    if (newValue) {
      try {
        const payload = {
          userId: userId,
          role: newValue.value 
        }
        const response = await ApiPutUpdateAdminRole(payload)
        console.log('Role updated successfully:', response.data)
        toast.success('Role updated successfully')
        await handleGetApiCall()
      } catch (error) {
        console.error('Error updating role:', error)
      }
    }
  }
  return (
    <div className='manage-admin-container'>
      <div className='manage-button'>
        <h1 className='common-manage-h1'>Admin Users</h1>
        <div className='add-button common-manage-button'>
          <Button
            variant='contained'
            onClick={() => addOrUpdateAdmin()}
            className='table-row-background '
          >
            Add New Admin
          </Button>
        </div>
      </div>
      <Table>
        <TableHead>
          <TableRow className='table-row-background'>
            {["Group","Name","UserName","Password","Email","Type","Active"].map((item,index)=>(<TableCell key={index} style={{ color: '#fff' }}>{item}</TableCell>))}
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {adminUsers?.map(user => (
            <TableRow key={user.id}>
              <TableCell>
                <Button
                  variant='contained'
                  onClick={openEditAccessGroup}
                  className='table-row-background'
                >
                  Group
                </Button>
              </TableCell>
              <TableCell>{user.displayName}</TableCell>
              <TableCell>{user.userName}</TableCell>
              <TableCell>****</TableCell>
              <TableCell>{user.emailId}</TableCell>
              <TableCell>
                <div className='w-200'>
                  <Autocomplete
                    options={typeOptions.filter((_, index) => index < 3)}
                    value={typeOptions.find(
                      option => option.label === user.role.roleDisplayName
                    )}
                    getOptionLabel={option => option.label}
                    onChange={(event, newValue) =>
                      handleTypeChange(event, newValue, user.userId)
                    } 
                    renderInput={params => (
                      <TextField {...params} label='Type' />
                    )}
                    fullWidth
                  />
                </div>
              </TableCell>

              <TableCell>
                <div className='switch-admin'>
                  <Switch
                    checked={user?.userStatus?.userStatus === 'active'}
                    onChange={e => {
                      const newStatus = e.target.checked ? 'active' : 'inActive'
                      handleChange(user, newStatus)
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                </div>
              </TableCell>

              <TableCell>
                <Button
                  variant='contained'
                  onClick={() => addOrUpdateAdmin(user.userId)}
                  className='table-row-background'
                >
                  Edit
                </Button>
              </TableCell>
              <TableCell>
                <Button variant='contained' className='delete-transparent'>
                  <DeleteIcon className='delete-icon' />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default ManageAdmin
