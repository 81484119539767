import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, TextField, Box, Grid } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ApiPostVisitTimes, ApiPutVisitTimes } from "../../../apiService/Core/Apicall.tsx";

interface AddOrUpdateManageVisitProps {
  initialValues?: {
    visitTimeInformation: string;
  };
  onSubmit: (values: any) => void;
  mode: "add" | "update";
}

const AddOrUpdateManageVisit: React.FC<AddOrUpdateManageVisitProps> = ({
  initialValues = {
    visitTimeInformation: "",
  },
  onSubmit,
}) => {
  const location = useLocation();
  const visitTime = location?.state?.visitTime;

  const formik = useFormik({
    initialValues: {
      visitTimeInformation: visitTime?.visitTimeInformation || "",
    },
    validationSchema: Yup.object({
      visitTimeInformation: Yup.string().required("Visit Time is required"),
    }),
    enableReinitialize: true, // This ensures reinitialization when visitTime changes
    onSubmit: (values) => {
      onSubmit(values);
      handleCallApi();
      toast.success(
        mode === "add"
          ? "Visit added successfully!"
          : "Visit updated successfully!"
      );
      navigate(-1);
    },
  });

  const handleCallApi = async () => {
    if (mode === "add") {
      const FormData = {
        visitTimeInformation: formik.values.visitTimeInformation,
        isActive: true,
        createdBy: 1,
      };
      const response: any = await ApiPostVisitTimes(FormData);
    }
    else{
      const FormData = {
        visitTimeId: visitTime.visitTimeId,
        visitTimeInformation: formik.values.visitTimeInformation,
        modifiedBy: 1
      };
      const response: any = await ApiPutVisitTimes(FormData);
    }
  };

  const navigate = useNavigate();
  const { mode, id } = useParams<{ mode: "add" | "update"; id?: string }>();
  return (
    <div>
      <Box
        component="form"
        onSubmit={formik.handleSubmit}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          maxWidth: 400,
          margin: "auto",
          padding: 3,
          backgroundColor: "#f9f9f9",
          borderRadius: 2,
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h1>{mode === "add" ? "Add New Visit" : "Update Visit"}</h1>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Visit Time"
              name="visitTimeInformation"
              value={formik.values.visitTimeInformation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.visitTimeInformation &&
                Boolean(formik.errors.visitTimeInformation)
              }
              fullWidth
            />
          </Grid>
        </Grid>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="table-row-background"
        >
          {mode === "add" ? "Add Visit" : "Update Visit"}
        </Button>
      </Box>
    </div>
  );
};

export default AddOrUpdateManageVisit;
