import './bootstrap-icons.css'
import { useEffect, useState } from 'react'
import { loadDomainSpecificCSS } from './global/GlobalCss.tsx'
import './App.css'
import React from 'react'
//import AppRoutes from './routes/AppRoutes.tsx'
import { useSelector } from 'react-redux'
import AppRoutes from './Routes/AppRoutes.tsx'

function App () {
  const isLoading = useSelector((store: any) => store.isLoading)
  const loader = useSelector((store: any) => store.redirect)
  useEffect(() => {
    loadDomainSpecificCSS()
  }, [])
  return (
    <div className='App'>
      <AppRoutes />
      {isLoading && (
        <div
          className='loader-overlay'
          style={{ display: `${loader ? 'flex' : 'none'}` }}
        >
          <div className='spinner'>
            <div className='clip-loader'></div>
          </div>
        </div>
      )}
    </div>
  )
}
export default App
