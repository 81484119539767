import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import {
  ApiGetClients,
  ApiPutDeleteClient,
} from "../../../apiService/Core/Apicall.tsx";

const ManageAdmin: React.FC = () => {
  const navigate = useNavigate();
  const [clients, setClients] = useState<any>([]);
  const addOrUpdateClient = (id?: any) => {
    if (id?.ClientID) {
      navigate(`/admin/clients-update/update/${id?.ClientID}`, {
        state: { clients: id },
      });
    } else {
      navigate("/admin/clients-update/add");
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      await handleGetApiCall();
    };
    fetchData();
    return () => {};
  }, []);
  const handleGetApiCall = async () => {
    try {
      const response: any = await ApiGetClients();
      setClients(response.data);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };
  const deleteClient = async (user: any, newStatus: string) => {
    try {
      const response: any = await ApiPutDeleteClient(user?.ClientID);
      toast.success("Client Deleted successfully!");
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  return (
    <div className="manage-admin-container">
      <div className="manage-button">
        <h1 className="common-manage-h1">Manage Clients</h1>
        <div className="add-button common-manage-button">
          <Button
            variant="contained"
            onClick={() => addOrUpdateClient()}
            className="table-row-background "
          >
            Add New Clients
          </Button>
        </div>
      </div>
      <Table>
        <TableHead>
          <TableRow className="table-row-background">
            {[
              "Client Code",
              "Client Name",
              "Owner Name",
              "Owner Email ID",
              "",
              "",
            ].map((item) => (
              <TableCell style={{ color: "#fff" }}>{item}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {clients.map((user) => (
            <TableRow key={user.id}>
              <TableCell>{user.ClientCode}</TableCell>
              <TableCell>{user.ClientName}</TableCell>
              <TableCell>{user.OwnerName}</TableCell>
              <TableCell>{user.OwnerEmailID}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  onClick={() => addOrUpdateClient(user)}
                  className="table-row-background"
                >
                  Edit
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  className="delete-transparent"
                  onClick={() => {
                    const isConfirmed = window.confirm(
                      "Are you sure you want to delete the client?"
                    );
                    if (isConfirmed) {
                      deleteClient(user, "delete"); // Call the API or action
                    }
                  }}
                >
                  <DeleteIcon className="delete-icon" />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ManageAdmin;
