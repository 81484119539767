import { LogOut, Ban, ClipboardList, User } from 'lucide-react'
import { Button, type SelectChangeEvent } from '@mui/material'
import React from 'react'
import dayjs from "dayjs";

const Dcrage = ({ loanData, handleClose }) => {
  return (
    <div
      className='loanData-model'
       onMouseLeave={handleClose}
    >
      <div className='profile-container'>
        <div className='profile-card'>
          <div className='profile-header'>
            <div className='profile-title'>
              <h2>{loanData?.firstName} {loanData?.lastName} </h2>
              {/* <span className='status-badge'>Incomplete Profile</span> */}
            </div>
            <hr></hr>
            <div className='action-buttons'>
              <Button className='icon-button table-row-background'>
                <LogOut size={20} color='white' />
              </Button>
              <Button className='icon-button table-row-background'>
                <Ban size={20} color='white' />
              </Button>
              <Button className='icon-button table-row-background'>
                <ClipboardList size={20} color='white' />
              </Button>
              <Button className='icon-button table-row-background'>
                <User size={20} color='white' />
              </Button>
            </div>
          </div>
          <h5>Contact Details:</h5>
          <div className='contact-details table-row-background'>
            <div className='contact-item'>
              <div className='label'>Age:</div>
              <div className='value'>{dayjs().diff(dayjs(loanData?.dob), "year")} </div>
            </div>
            <div className='contact-item'>
              <div className='label'>Email:</div>
              <div className='value'>{loanData?.emailId}</div>
            </div>
            <div className='contact-item'>
              <div className='label'>Phone:</div>
              <div className='value'>{loanData?.contactNo}</div>
            </div>
          </div>
          <hr></hr>
          <div className='allocations-section '>
            <h3>This month allocations</h3>
            <div className='allocations-grid min-border-color'>
              <table>
                <thead className='table-row-background'>
                  {['MM', 'BLOOMS', 'ALDI', 'Transport'].map(header => (
                    <th>{header}</th>
                  ))}
                </thead>
                <tbody>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tbody>
              </table>
            </div>
          </div>
          <hr></hr>
          <div className='history-section'>
            <h5>Shopper History:</h5>
            <div className='reports-card table-row-background'>
              <div className='reports-vs'>
                <div className='reports-total'>5 Reports</div>
                <div className='reports-grid'>
                  <div className='allocations-grid min-border-color'>
                    <table>
                      <thead className='table-row-background'>
                        {['ALDI', 'BLOOMS', 'MM', 'Beacon'].map(header => (
                          <th>{header}</th>
                        ))}
                      </thead>
                      <tbody>
                        <td>4</td>
                        <td>1</td>
                        <td>0</td>
                        <td>0</td>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className='member-since'>
                <div className='label'>Member Since:</div>
                <div className='value'>44 Months</div>
              </div>
              <div className='member-since'>
                <div className='label'>Last Report Rating:</div>
                <div className='value'>Blooms -- 11/2024 --06/11/2024--92/100  11/2024 --06/11/2024--92/100 </div>
              </div>
              <div className='member-since'>
                <div className='label'>Last Month:</div>
                <div className='value'>0 Report</div>
              </div>
              <div className='member-since'>
                <div className='label'>Overall Rating:</div>
                <div className='value'>11/100</div>
              </div>
              <div className='member-since'>
                <div className='label'>Editors comment:</div>
                <div className='value'>44 Months</div>
              </div>
            </div>
          </div>
          <div className='notes-section '>
            <div className='notes-container  min-border-color'>
              <h3 className='table-row-background'>Shopper Notes</h3>{' '}
              <div style={{ padding: '10px' }}>
                <textarea placeholder='Enter shopper notes...' />
                <Button className='save-button table-row-background'>
                  Save shopper notes
                </Button>
              </div>
            </div>
            <div className='notes-container  min-border-color'>
              <h3 className='table-row-background'>Training Notes</h3>{' '}
              <div style={{ padding: '10px' }}>
                <textarea placeholder='Enter training notes...' />
                <Button className='save-button table-row-background'>
                  Save training notes
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Dcrage
