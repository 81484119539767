import React, { useEffect, useState } from 'react'
import { Box, MenuItem, Select, Typography, Button, Divider, IconButton, Dialog, DialogContent, FormControl, TextField, InputLabel } from '@mui/material'
import edit from '../Template Listing/editicon.png';
import deleteicon from '../Template Listing/deleteicon.png'
import { AddQuestionTemplate, DeleteQuestionTemplate, GetEntities, GetQuestionTemplate, UpdateQuestionTemplate } from '../../../apiService/Core/Apicall.tsx';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import { CancelOutlined, } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';


interface FormValues {
    templateName: string;
    entityTypeId: number;
}
function TemplateListing() {
    const [selectedentity, setselectedentity] = useState<number | string>(0)
    const [OpenEdit, setOpenEdit] = useState<boolean>(false)
    const [showerror, setshowerror] = useState<boolean>(false)
    const [isDelete, setisDelete] = useState<boolean>(false)
    const [filteredTemplate, setFilteredtemplate] = useState<any>('')
    const [entity, setentity] = useState<any>('')
    const [Template, setTemplate] = useState<any>('')
    const [TemplateType, setTemplateType] = useState<string>('')
    const navigate= useNavigate()

    useEffect(() => {
        const fetchentities = async () => {
            const entities = await GetEntities()
            setentity(entities.data)
        }
        const GetTemplate = async () => {
            const response = await GetQuestionTemplate()
            console.log(response.data)
            setTemplate(response.data)
        }
        GetTemplate()
        fetchentities()
    }, [isDelete, OpenEdit, showerror])


    const initialValues: FormValues = ({
        templateName: '',
        entityTypeId: 0,
    })
    const validationSchema = Yup.object({
        templateName: Yup.string()
            .required("Template Name is required"),
        entityTypeId: Yup.number()
            .required("Entity is required")
            .positive("Entity must be a positive number")
            .integer("Entity must be an integer"),
    });

    const onSubmit = async (Values: FormValues, { resetForm }: { resetForm: () => void }) => {
        callTemplateAPis(Values)
        resetForm()
    }

    const callTemplateAPis = async (Values: any) => {
        try {
            const response = await (TemplateType === 'Create' ? AddQuestionTemplate(Values) : UpdateQuestionTemplate(Values))
            alert(response.message)
            setshowerror(true)
            setOpenEdit(false)
            console.log("Send Values", Values)
        }
        catch (error) {
            alert(error)
        }
    }

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit
    })

    const updateTemplate = (item: any) => {
        formik.setValues({
            ...formik.values,
            templateName: item.templateName,
            entityTypeId: item.entityTypeId.entityTypeId,
            ...(item.entityTypeTemplateId ? { entityTypeTemplateId: item.entityTypeTemplateId } : {}),
        })
        setOpenEdit(true);
        setTemplateType('update')
    }
    const deleteTemplate = async (item: any) => {
        console.log(item)
        setTemplateType('update')
        const isDelete = window.confirm('Are You Sure ?')
        if (isDelete) {
            setisDelete(true);
            const response= await DeleteQuestionTemplate(item.entityTypeTemplateId)
            alert(response.message)

        } else {
            setisDelete(false);
        }
    }

    useEffect(() => {
        let filtered = Template;

        if (selectedentity) {
            filtered = filtered.filter(q => q.entityTypeId.entityTypeId === selectedentity);
        }

        setFilteredtemplate(filtered);
    }, [selectedentity, Template]);
    return (
        <Box className='cotnaier-fluid h- extra'>
            <Box className='p-2 px-3 d-flex align-items-center justify-content-between'>
                <Typography variant='h6'>Template Listing</Typography>
                <Box className='d-flex col-4 p-0 justify-content-end align-items-stretch' gap={2}>
                    <Select
                        className='col-3'
                        id="demo-simple-select"
                        value={selectedentity}
                        onChange={(e) => setselectedentity(e.target.value)}
                    >
                        <MenuItem value={0} disabled>Select Type</MenuItem>

                        {entity.length > 0 &&
                            entity.map((cat) => (
                                <MenuItem value={cat.entityTypeId} key={cat.entityTypeId}>
                                    {cat.entityTypeName}
                                </MenuItem>
                            ))
                        }
                    </Select>
                    <Button className='bg-grey text-light px-4 py-1 transform m-0' onClick={() => { setOpenEdit(true); setTemplateType('Create') }}>Add Template</Button>
                </Box>
            </Box>
            <Divider className='my-2' />
            <Box className='p-3'>
                <Box className='d-flex align-items-center flex-wrap' justifyContent={'start'} gap={2}>
                    {filteredTemplate.length > 0 && filteredTemplate.map((item,index) => (
                            <Box key={index} className='p-2 d-flex w-49 align-items-center text-dark justify-content-between' sx={{borderRadius:"10px", border:'1px solid black'}}>
                                <Box onClick={()=>{navigate(`/admin/create-new-template`, { state: {templateData: item} }) }}  flex='1'>
                                <Typography  variant='subtitle1'  className='fw-bold'>{item.templateName}</Typography>
                                </Box>
                                <Box className='d-flex align-items-center'>
                                    <Typography borderRadius='5px' className='px-3 py-1 align-items-center d-flex text-center bg-light-primary'>{item.entityTypeId.entityTypeName}</Typography>
                                    <IconButton className='mr-0' onClick={() => { updateTemplate(item) }}>
                                        <Box component='img' height='32px' src={edit}></Box>
                                    </IconButton>
                                    <IconButton className='pl-0 ml-0' onClick={() => { deleteTemplate(item) }}>
                                        <Box component='img' height='32px' src={deleteicon}></Box>
                                    </IconButton>
                                </Box>
                            </Box>
                    ))}
                </Box>
            </Box>
            <Dialog open={OpenEdit} className='extra' fullWidth>
                <DialogContent >
                    <Box className='d-flex justify-content-end'>
                        <IconButton onClick={() => { setOpenEdit(false) }}><CancelOutlined /></IconButton>
                    </Box>
                    <Typography variant='h5' className='text-center my-2 fw-bold'>{TemplateType} Template</Typography>
                    <form onSubmit={formik.handleSubmit}>
                        <Box className='d-flex flex-column pt-4' height='40vh' gap={5}>
                            <FormControl>
                                <InputLabel>Template Name</InputLabel>
                                <TextField value={formik.values.templateName} onChange={(e) => { formik.setFieldValue('templateName', e.target.value) }} />
                                {formik.errors.templateName && <Typography className='text-danger' variant='caption'>{formik.errors.templateName}</Typography>}
                            </FormControl>
                            <FormControl>
                                <InputLabel id="entity" shrink={false}>Business Type</InputLabel>
                                <Select
                                    labelId='entity'
                                    id="demo-simple-select"
                                    value={formik.values.entityTypeId}
                                    onChange={(e) => formik.setFieldValue("entityTypeId", e.target.value)}
                                >
                                    <MenuItem value={0} disabled>Select Type</MenuItem>

                                    {entity.length > 0 &&
                                        entity.map((cat) => (
                                            <MenuItem value={cat.entityTypeId} key={cat.entityTypeId}>
                                                {cat.entityTypeName}
                                            </MenuItem>
                                        ))
                                    }
                                </Select>
                                {showerror && formik.errors.entityTypeId && <Typography className='text-danger' variant='caption'>{formik.errors.entityTypeId}</Typography>}

                            </FormControl>
                            <Button type='submit' className='px-3 bg-grey transform text-light mt-auto'>Save</Button>
                        </Box>
                    </form>
                </DialogContent>
            </Dialog>
        </Box>
    )
}

export default TemplateListing
