import React, { useEffect, useState } from "react";
import {
  Table,
  Box,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Switch,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import MapIcon from "@mui/icons-material/Room";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useNavigate } from "react-router-dom";
import edit from '../../../assets/images/editIcon.png'
import {
  ApiPutVenueStatus,
  ApiGetVenueList,
} from "../../../apiService/Core/Apicall.tsx";
import { toast } from "react-toastify";
const ManageVenue: React.FC = () => {
  const [venues, setVenues] = useState<any[]>([]);
  const [status, setStatus] = useState({});

  const navigate = useNavigate();

  const addOrUpdateVenue = (venue?: any) => {
    if (venue) {
      navigate(`/admin/add-or-update-venue/update/${venue?.venueId}`, {
        state: { venueData: venue },
      });
    } else {
      navigate("/admin/add-or-update-venue/add");
    }
  };

  const [isDialogOpen, setDialogOpen] = useState(false);

  const [selectedVenue, setSelectedVenue] = useState<any>(null);
  const handleOpenMap = (venue) => {
    setSelectedVenue(venue);
    setDialogOpen(true);
  };

  const openMapInNewTab = (venue: any) => {
    const url = "/venue-map?id=1&lat=-25.2744&lng=133.7751";
    window.open(url, "_blank"); // Open map page in a new tab
  };

  // const openMapInNewTab = (venue: any) => {
  //   const url = `https://www.google.com/maps?q=-25.2744,133.7751&z=15`;
  //   window.open(url, "_blank"); // Open map page in a new tab
  // };

  useEffect(() => {
    const fetchData = async () => {
      await handleGetVenueList();
    };
    fetchData();
    return () => {};
  }, []);

  const handleGetVenueList = async () => {
    const data = "?offset=0&limit=100";
    try {
      const response: any = await ApiGetVenueList(data);
      setVenues(response.data);
      console.log("venue list", response.data);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const deleteVenue = async (venue: any, newStatus: string) => {
    try {
      const body = {
        venueId: venue?.venueId,
        status: newStatus,
      };
      const response: any = await ApiPutVenueStatus(body);
      toast.success("Site Deleted successfully!");
      console.log("Status updated successfully:", response);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const navigateToViewJobCriteriaVenue = (venue?: any) => {
    navigate("/admin/view-venue-jobcriteria", {
      state: { venue },
    });
  };
  const navigateToVenueSiteQuestion = (venue?: any) => {
    navigate("/admin/create-venue-site-template", {
      state: { venue },
    });
  };

  const navigateToViewEditors = (venue?: any) => {
    navigate("/admin/view-editor-site", {
      state: { venue },
    });
  };

  const handleChange = async (venue: any, newStatus: string) => {
    setStatus((prevStatus) => ({
      ...prevStatus,
      [venue.venueId]: newStatus,
    }));

    setVenues((prevList) =>
      prevList.map((item) =>
        item.venueId === venue.venueId
          ? {
              ...item,
              venueStatus: {
                ...item.userStatus,
                userStatus: newStatus,
              },
            }
          : item
      )
    );

    try {
      const body = {
        venueId: venue?.venueId,
        status: newStatus,
      };
      const response: any = await ApiPutVenueStatus(body);
      console.log("Status updated successfully:", response);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };
  return (
    <div className="manage-admin-container">
      <div className="manage-button">
        <h1 className="common-manage-h1">Manage Sites</h1>
        <div className="add-button common-manage-button">
          <Button
            variant="contained"
            onClick={() => addOrUpdateVenue()}
            className="table-row-background "
          >
            Add New Site
          </Button>
          {/* <Button
          variant='contained'
          onClick={() => addOrUpdateVenue()}
          className='table-row-background '
          style={{ marginLeft: '10px' }}
        >
          Add New Report
        </Button> */}
        </div>{" "}
      </div>
      <Table>
        <TableHead>
          <TableRow className="table-row-background">
            {/* <TableCell style={{ color: "#fff" }}>XLS</TableCell> */}
            <TableCell style={{ color: "#fff" }}>Venue Name</TableCell>
            <TableCell style={{ color: "#fff" }}>Venue Code</TableCell>
            <TableCell style={{ color: "#fff" }}>State</TableCell>
            <TableCell style={{ color: "#fff" }}>Editor</TableCell>
            <TableCell style={{ color: "#fff" }}>Job Criteria</TableCell>
            <TableCell style={{ color: "#fff" }}>Questions</TableCell>
            <TableCell style={{ color: "#fff" }}>Active</TableCell>
            {/* <TableCell style={{ color: "#fff" }}>Copy Brief</TableCell>
            <TableCell style={{ color: "#fff" }}></TableCell> */}
            <TableCell style={{ color: "#fff" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {venues.map((venue) => (
            <TableRow key={venue.id}>
              
              {/* <TableCell>
                <Button variant="contained" className="table-row-background">
                  {" "}
                  Upload
                </Button>
              </TableCell> */}
              <TableCell>{venue.venueName}</TableCell>
              <TableCell>{venue.venueCode}</TableCell>
              <TableCell>{venue.state}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  onClick={() => navigateToViewEditors(venue)}
                  className="table-row-background"
                >
                  View Editors
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  onClick={() => navigateToViewJobCriteriaVenue(venue)}
                  className="table-row-background"
                >
                  Job Criteria
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  onClick={() => navigateToVenueSiteQuestion(venue)}
                  className="table-row-background"
                >
                  Questions
                </Button>
              </TableCell>
              <TableCell>
                <div className="switch-admin">
                  <Switch
                    checked={venue?.venueStatus?.userStatus === "active"}
                    onChange={(e) => {
                      const newStatus = e.target.checked
                        ? "active"
                        : "inActive";
                      handleChange(venue, newStatus); // Pass the store and the new status
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />{" "}
                </div>
              </TableCell>
              <TableCell className="d-flex justify-content-start">
              <Button
                  // variant="contained"
                  startIcon={<ContentCopyIcon />}
                  // className="table-row-background"
                ></Button>
              <Button
                  // variant="contained"
                  // className="table-row-background"
                  onClick={() => addOrUpdateVenue(venue)}
                >
                  <Box component='img' className="w-50" src={edit}></Box>
                </Button>
                <Button
                  variant="contained"
                  className="delete-transparent"
                  onClick={() => {
                    const isConfirmed = window.confirm(
                      "Are you sure you want to delete the Site?"
                    );
                    if (isConfirmed) {
                      deleteVenue(venue, "delete"); // Call the API or action
                    }
                  }}
                >
                  <DeleteIcon className="delete-icon" />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ManageVenue;
