import React, { useEffect, useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { Button } from '@mui/material'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import {
  ApiPutBriefJson,
  ApiPostBriefJson
} from '../../../apiService/Core/Apicall.tsx'
interface AddOrUpdateBriefJsonProps {
  onSubmit?: (values: any) => void
  mode: 'add' | 'update'
}
const AddOrUpdateBriefJson: React.FC<AddOrUpdateBriefJsonProps> = () => {
  debugger
  const location = useLocation()
  const briefEntity = location?.state?.brief
  const navigate = useNavigate()
  const { mode } = useParams<{ mode: 'add' | 'update'; id?: string }>()
  const [formValues, setFormValues] = useState({
    title: '',
    minWord: 0,
    maxWord: 0,
    briefJson: ''
  })
  useEffect(() => {
    if (mode === 'update' && briefEntity) {
      setFormValues({
        title: briefEntity.briefJsonTitle || '',
        minWord: briefEntity.minNoOfWords || 0,
        maxWord: briefEntity.maxNoOfWords || 0,
        briefJson: briefEntity?.briefJsonDescription || ''
      })
    }
  }, [mode, briefEntity])

  const validationSchema = Yup.object({
    title: Yup.string().required('Title is required'),
    minWord: Yup.number()
      .min(0, 'Minimum words must be 0 or greater')
      .required('Minimum words are required'),
    maxWord: Yup.number()
      .min(
        Yup.ref('minWord'),
        'Maximum words must be greater than minimum words'
      )
      .required('Maximum words are required'),
    briefJson: Yup.string().required('Brief JSON is required')
  })

  const handleSubmit = async (values: any) => {
    const FormData = {
      briefJsonId: briefEntity?.briefJsonId,
      briefJsonTitle: values.title,
      minNoOfWords: values.minWord,
      maxNoOfWords: values.maxWord,
      briefJsonDescription: values.briefJson,
      modifiedBy: '11'
    }
    try {
      if (mode === 'add') {
        await ApiPostBriefJson(FormData)
        toast.success('Brief added successfully!')
      } else {
        await ApiPutBriefJson(FormData)
        toast.success('Brief updated successfully!')
      }
      navigate(-1)
    } catch (error) {
      console.error('Error updating brief:', error)
      toast.error('Failed to update brief')
    }
  }

  return (
    <Formik
      initialValues={formValues}
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ handleChange, handleBlur, values }) => (
        <Form className='add-update-form'>
          <h1 className='common-add'>
            {mode === 'add'
              ? 'Add New Report Overview Prompts'
              : 'Update Report Overview Prompts'}
          </h1>
          <div className='Form-BriefJson'>
            {[
              { label: 'Title', name: 'title', type: 'text' },
              { label: 'Min. Words', name: 'minWord', type: 'number' },
              { label: 'Max. Words', name: 'maxWord', type: 'number' },
              { label: 'Description', name: 'briefJson', type: 'textarea' }
            ].map(({ label, name, type }) => (
              <div key={name} className='BriefJson-flex'>
                <label htmlFor={name} className='BriefJson-Label'>
                  {label}:
                </label>
                {type === 'textarea' ? (
                  <Field
                    as='textarea'
                    id={name}
                    name={name}
                    className='BriefJson-height'
                    value={values[name]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : (
                  <Field
                    type={type}
                    id={name}
                    name={name}
                    className='BriefJson-Field'
                    value={values[name]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                )}
              </div>
            ))}
          </div>
          <div className='text-center'>
            <Button
              type='submit'
              variant='contained'
              color='primary'
              className='table-row-background add-update-button'
            >
              {mode === 'add'
                ? 'Add Report Overview Promts'
                : 'Update Report Overview Promts'}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AddOrUpdateBriefJson
