import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, Button, Autocomplete, Box } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import {  get } from "../../../apiService/apiUtils.tsx";

interface AreaFormProps {
  initialValues?: {
    areaName: string;
    username: string;
    password: string;
    venueList: string[];
  };
  onSubmit: (values: any) => void;
  mode: "add" | "update";
}
const AddOrUpdateAreaManager: React.FC<AreaFormProps> = ({
  initialValues = {
    areaName: "",
    username: "",
    password: "",
    venueList: [],
  },
  onSubmit,
}) => {
  const [venues, setVenues] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { mode, id } = useParams<{ mode: "add" | "update"; id?: string }>();

  useEffect(() => {
    if (mode === "update" && id) {
      console.log(`Fetching data for area with ID: ${id}`);
    }
  }, [mode, id]);

  useEffect(() => {
    const fetchVenues = async () => {
      try {
        const response: any = await get(
          "api/v1/venue/venue-list?offset=0&limit=10"
        );
        const transformedVenues = response.data.map((venue: any) => ({
          label: venue.venueName,
          value: venue.venueId,
        }));
        setVenues(transformedVenues); // Ensure options are set correctly
      } catch (error) {
        console.error("Error fetching venues:", error);
      }
    };
    fetchVenues();
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      areaName: Yup.string().required("Area Name is required"),
      venueList: Yup.array().min(1, "At least one venue must be selected"),
    }),
    onSubmit: async (values) => {
      const formData = {
        areaName: values.areaName,
        username: values.username,
        password: values.password,
        venueList: values.venueList,
      };

      try {
    
      } catch (error) {
        console.error("Error during API call:", error);
        setError(error?.response?.data.message || "An error occurred");
      }
    },
  });

 

  const typeOptions = [
    {
      label: "Store",
      value: "Store",
    },
    {
      label: "Store1",
      value: "Store1",
    },
  ];

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        maxWidth: 800,
        margin: "auto",
        padding: 3,
        backgroundColor: "#f9f9f9",
        borderRadius: 2,
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <h1>{mode === "add" ? "Add New Area Manager" : "Update Area Manager"}</h1>

      <div>
        <Autocomplete
          options={typeOptions}
          value={typeOptions.find((option) => option.value === "")}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField {...params} label="Select Store" />
          )}
          fullWidth
        />
      </div>
      <TextField
        label="Area Name"
        name="areaName"
        value={formik.values.areaName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched.areaName && Boolean(formik.errors.areaName)}
        helperText={formik.touched.areaName && formik.errors.areaName}
        fullWidth
      />
      <Autocomplete
        multiple
        options={venues}
        getOptionLabel={(option) => option.label}
        value={venues.filter((venue) =>
          formik.values.venueList.includes(venue.value)
        )}
        onChange={(event, value) => {
          const selectedValues = value.map((item) => item.value); 
          formik.setFieldValue("venueList", selectedValues); 
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Venue List"
            error={formik.touched.venueList && Boolean(formik.errors.venueList)}
            helperText={formik.touched.venueList && formik.errors.venueList}
          />
        )}
        fullWidth
      />

      <div style={{ color: "red" }}>{error}</div>
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className="table-row-background"
      >
        {mode === "add" ? "Add Area" : "Update Area"}
      </Button>
    </Box>
  );
};

export default AddOrUpdateAreaManager;
