import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Autocomplete,
  Rating,
  Divider,
  Toolbar,
  Tooltip,
  Badge,
  Popover,
} from "@mui/material";
import {
  Star,
  CardGiftcard,
  Warning,
  Refresh,
  CheckCircle,
  Copyright,
  Send,
  Info,
  Description,
  Edit,
  Chat,
  KeyboardArrowDown,
  Close,
} from "@mui/icons-material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ApiGetClientEntities } from "../../../apiService/Core/Apicall.tsx";
import emoji0 from "../../../assets/images/emoji0.svg";
import emoji1 from "../../../assets/images/emoji1.svg";
import emoji2 from "../../../assets/images/emoji2.svg";
import emoji3 from "../../../assets/images/emoji3.svg";
import emoji4 from "../../../assets/images/emoji4.svg";
import emoji5 from "../../../assets/images/emoji5.svg";
import PatchedReactQuill from "../ManageVenue/PatchedReactQuill.tsx";
import Dcrage from "../OrphanAllocation/Dcrage.tsx";

interface Business {
  label: string;
  value: string;
}

// Custom styled button with olive green background
const OliveButton = ({
  children,
  ...props
}: React.ComponentProps<typeof Button>) => (
  <Button
    {...props}
    sx={{
      backgroundColor: "#7a7d52",
      color: "white",
      "&:hover": {
        backgroundColor: "#5e6040",
      },
      textTransform: "none",
      ...props.sx,
    }}
    variant="contained"
  >
    {children}
  </Button>
);

// Action button component for the grid
const ActionButton = ({
  icon,
  label,
  onClick,
}: {
  icon: React.ReactNode;
  label: string;
  onClick?: () => void;
}) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
      p: 1,
      border: "1px solid #ccc",
      height: "100%",
      cursor: onClick ? "pointer" : "default",
    }}
    onClick={onClick}
  >
    <Box sx={{ mr: 1 }}>{icon}</Box>
    <Typography variant="body2">{label}</Typography>
  </Box>
);

const emojiMap: { [key: number]: string } = {
  1: emoji1,
  2: emoji2,
  3: emoji3,
  4: emoji4,
  5: emoji5,
};

// Emoji component based on rating
const RatingEmoji = ({ rating }: { rating: number }) => {
  const emojiSrc = emojiMap[rating] || emoji0; // Default to lowest rating emoji

  return (
    <img src={emojiSrc} alt={`Rating: ${rating}`} width={60} height={60} />
  );
};

export default function ManageReports() {
  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
  const [displayStatus, setDisplayStatus] = useState("unconfirmed");
  const [selectedRow, setSelectedRow] = useState<boolean>(false);
  const [bonusDialogOpen, setBonusDialogOpen] = useState(false);
  const [penaltyDialogOpen, setPenaltyDialogOpen] = useState(false);
  const [bonusAmount, setBonusAmount] = useState("");
  const [bonusReason, setBonusReason] = useState("");
  const [businesses, setBusinesses] = useState<Business[]>([]);
  const [penaltyAmount, setPenaltyAmount] = useState("");
  const [penaltyReason, setPenaltyReason] = useState("");
  const [editorSummeryDialogOpen, setEditorSummeryDialogOpen] = useState(false);
  const [editorSummery, setEditorSummery] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [loanData, setLoanData] = useState({
    userId: 92,
    firstName: "mm",
    lastName: "shhoper1",
    displayName: "",
    profilePicUrl: "https://mmfiles.s3.ap-southeast-2.amazonaws.com/ShopperFiles/ProfilePictures/profilepic-image_cropper_1740989210357.jpg",
    contactNo: "9856366598",
    emailId: "mmshhoper1@yopmail.com",
    gender: "Male",
    dob: "2002-02-15",
    latitude: "-34.594196300",
    longitude: "138.744008100",
    distanceInKm: 0.509475129311689
  });

  // Shopper Rating Dialog States
  const [ratingDialogOpen, setRatingDialogOpen] = useState(false);
  const [ratings, setRatings] = useState({
    overallReport: 0,
    information: 0,
    quality: 0,
    spellingAndGrammar: 0,
    photoQuality: 0,
  });
  const [ratingComment, setRatingComment] = useState("Thanks for completing");

  useEffect(() => {
    const handleGetClientList = async () => {
      const data = "";
      try {
        const response: any = await ApiGetClientEntities(data);
        const transformedData = response.data.map((item: any) => ({
          value: item.clientEntityId,
          label: item.entityName,
        }));
        setBusinesses(transformedData);
      } catch (error) {
        console.error("Error during API call:", error);
      }
    };

    handleGetClientList();
  }, []);

  const handleHover = (event, index) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenBonusDialog = () => {
    setBonusDialogOpen(true);
  };

  const handleCloseBonusDialog = () => {
    setBonusDialogOpen(false);
  };

  const handleOpenPenaltyDialog = () => {
    setPenaltyDialogOpen(true);
  };

  const handleClosePenaltyDialog = () => {
    setPenaltyDialogOpen(false);
  };

  const handleOpeneditorSummeryDialog = () => {
    setEditorSummeryDialogOpen(true);
  };

  const handleCloseEditorSummeryDialog = () => {
    setEditorSummeryDialogOpen(false);
  };

  const handleSaveBonus = () => {
    setBonusDialogOpen(false);
    // Reset form fields
    //setBonusAmount("");
    //setBonusReason("");
  };

  const handleSavePenalty = () => {
    setPenaltyDialogOpen(false);
    // Reset form fields
  };

  const handleSaveEditorSummery = () => {
    setEditorSummeryDialogOpen(false);
    // Reset form fields
  }; 

  // Shopper Rating Dialog Handlers
  const handleOpenRatingDialog = () => {
    setRatingDialogOpen(true);
  };

  const handleCloseRatingDialog = () => {
    setRatingDialogOpen(false);
  };

  const handleSaveRating = () => {
    console.log("Rating saved:", { ratings, comment: ratingComment });
    setRatingDialogOpen(false);
  };

  const handleRatingChange = (category: string, value: number) => {
    setRatings({
      ...ratings,
      [category]: value,
    });
  };

  return (
    <Box sx={{ maxWidth: "100%", overflow: "hidden" }}>
      {/* Header */}
      <Box sx={{ p: 3, textAlign: "center", mb: 2 }}>
        <Typography variant="h4" component="h1" fontWeight="bold">
          Manage Reports
        </Typography>
      </Box>

      {/* Controls */}
      <Box
        sx={{ display: "flex", justifyContent: "space-between", mb: 2, px: 2 }}
      >
        <OliveButton>All Report Status</OliveButton>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            views={["month", "year"]}
            label=""
            value={selectedDate}
            onChange={(newDate) => setSelectedDate(newDate)}
            format="MM/yyyy"
            sx={{
              width: "400px",
              "& .MuiOutlinedInput-root": {
                borderRadius: 1,
              },
            }}
          />
        </LocalizationProvider>

        <OliveButton>DOWNLOAD AS XLS</OliveButton>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
          px: 2,
        }}
      >
        {/* Left: Autocomplete Field */}
        <Box sx={{ flex: 1, mr: 2 }}>
          <Autocomplete
            options={businesses}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField {...params} label="Select Business" />
            )}
            sx={{ width: "20%" }}
          />
        </Box>

        {/* Right: Update Button */}
        <Box>
          <OliveButton>Update</OliveButton>
        </Box>
      </Box>

      {/* Display dropdown */}
      <Box
        sx={{
          p: 2,
          border: "1px solid #e0e0e0",
          borderRadius: 1,
          mx: 2,
          mb: 2,
        }}
      >
        <Typography variant="body2" sx={{ mb: 1 }}>
          Display...
        </Typography>
        <FormControl fullWidth>
          <Select
            value={displayStatus}
            onChange={(e) => setDisplayStatus(e.target.value as string)}
            displayEmpty
            sx={{ borderRadius: 1 }}
            IconComponent={KeyboardArrowDown}
          >
            <MenuItem value="unconfirmed">
              Unconfirmed (not yet ready for approval)
            </MenuItem>
            <MenuItem value="pending">
              Confirmed(Ready for your approval)
            </MenuItem>
            <MenuItem value="approved">
              Approved(Already sent to venue administrator)
            </MenuItem>
            <MenuItem value="released">Released(Venue admin has release to venues)</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* Release Selected button */}
      <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 2, px: 2 }}>
        <OliveButton>Release Selected</OliveButton>
      </Box>

      {/* Action buttons grid */}
      <Box
        sx={{
          border: "1px solid #ccc",
          borderRadius: 1,
          mx: 2,
          mb: 2,
          overflow: "hidden",
        }}
      >
        <Grid container>
          <Grid item xs={2}>
            <ActionButton icon={<Star />} label="Shopper Ratings" />
          </Grid>
          <Grid item xs={2}>
            <ActionButton icon={<CardGiftcard />} label="Bonus" />
          </Grid>
          <Grid item xs={2}>
            <ActionButton icon={<Warning />} label="Penalty" />
          </Grid>
          <Grid item xs={2}>
            <ActionButton icon={<Refresh />} label="RollBack" />
          </Grid>
          <Grid item xs={2}>
            <ActionButton icon={<CheckCircle />} label="Approve" />
          </Grid>
          <Grid item xs={2}>
            <ActionButton icon={<Copyright />} label="Redcape Report" />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={2}>
            <ActionButton icon={<Send />} label="Release" />
          </Grid>
          <Grid item xs={2}>
            <ActionButton icon={<Info />} label="Update Shopper" />
          </Grid>
          <Grid item xs={2}>
            <ActionButton icon={<Description />} label="PDF DOWNLOAD" />
          </Grid>
          <Grid item xs={2}>
            <ActionButton icon={<Edit />} label="Edit Report" />
          </Grid>
          <Grid item xs={2}>
            <ActionButton icon={<Chat />} label="Editor Summary" />
          </Grid>
        </Grid>
      </Box>

      {/* Delete Selected Reports button */}
      <Box sx={{ mb: 2, px: 2 }}>
        <OliveButton>Delete Selected Reports</OliveButton>
      </Box>

      {/* Reports Table */}
      <TableContainer
        component={Paper}
        sx={{ mx: 2, mb: 4, border: "1px solid #ccc" }}
      >
        <Table>
          <TableHead sx={{ bgcolor: "#7a7d52" }}>
            <TableRow>
              <TableCell padding="checkbox" sx={{ color: "white" }}></TableCell>
              <TableCell sx={{ color: "white" }}>(HH:MM:SS)</TableCell>
              <TableCell sx={{ color: "white" }}>Date entered</TableCell>
              <TableCell sx={{ color: "white" }}>Venue</TableCell>
              <TableCell sx={{ color: "white" }}>Month</TableCell>
              <TableCell sx={{ color: "white" }}>Entered by</TableCell>
              <TableCell sx={{ color: "white" }}>Approved by</TableCell>
              <TableCell sx={{ color: "white" }}>Score</TableCell>
              <TableCell sx={{ color: "white" }}>PDF</TableCell>
              <TableCell sx={{ color: "white" }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  checked={selectedRow}
                  onChange={() => setSelectedRow(!selectedRow)}
                />
              </TableCell>
              <TableCell>07:09:46</TableCell>
              <TableCell>12/12/2024</TableCell>
              <TableCell>
                <Typography
                  variant="body2"
                  color="primary"
                  sx={{ cursor: "pointer" }}
                >
                  D-Mart Mumbai
                </Typography>
              </TableCell>
              <TableCell>
                {/* {selectedDate ? format(selectedDate, "MM/yyyy") : ""} */}
                03/2025
              </TableCell>
              <TableCell style={{cursor: "pointer"}} onMouseEnter={(e) => handleHover(e, 0)}>appgalleryshopper</TableCell>
              <TableCell></TableCell>
              <TableCell sx={{ bgcolor: "#e8f5e9" }}>0.00</TableCell>
              <TableCell>
                <IconButton size="small">
                  <Description />
                </IconButton>
              </TableCell>
              <TableCell>
                <Box sx={{ display: "flex", gap: 0.5 }}>
                  <IconButton size="small" className="iconColor">
                    <Copyright />
                  </IconButton>
                  <IconButton
                    size="small"
                    className="iconColor"
                    onClick={handleOpenRatingDialog}
                  >
                    <Star />
                  </IconButton>
                  <IconButton size="small" className="iconColor" onClick={handleOpeneditorSummeryDialog}>
                    <Chat />
                  </IconButton>
                  <Badge
                    color="error"
                    variant="dot"
                    invisible={!bonusAmount} // Hides badge if bonusAmount is 0 or undefined
                  >
                    <IconButton
                      size="small"
                      className="iconColor"
                      onClick={handleOpenBonusDialog}
                    >
                      <CardGiftcard />
                    </IconButton>
                  </Badge>
                  <IconButton
                    size="small"
                    className="iconColor"
                    onClick={handleOpenPenaltyDialog}
                  >
                    <Warning
                      sx={{ color: penaltyAmount ? "red" : "inherit" }}
                    />
                  </IconButton>
                  <Tooltip title="Rollback" arrow>
                    <IconButton
                      size="small"
                      className="iconColor"
                      sx={{ transform: "scaleX(-1)" }}
                    >
                      <Refresh />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Approve" arrow>
                    <IconButton size="small" className="iconColor">
                      <CheckCircle />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Release" arrow>
                    <IconButton size="small" className="iconColor">
                      <Send />
                    </IconButton>
                  </Tooltip>
                  <IconButton size="small" className="iconColor">
                    <Info />
                  </IconButton>
                  <IconButton size="small" className="iconColor">
                    <Edit />
                  </IconButton>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Dcrage handleClose={handleClose} loanData={loanData} />
      </Popover>

      {/* Bonus Dialog */}
      <Dialog
        open={bonusDialogOpen}
        onClose={handleCloseBonusDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pb: 1,
          }}
        >
          <Typography variant="h6">Bonus</Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseBonusDialog}
            aria-label="close"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ mb: 3 }}>
            <Typography variant="body1" sx={{ mb: 1, fontWeight: "medium" }}>
              Amount:
            </Typography>
            <TextField
              fullWidth
              value={bonusAmount}
              onChange={(e) => setBonusAmount(e.target.value)}
              variant="outlined"
              type="number"
              inputProps={{ min: 0, step: 0.01 }}
            />
          </Box>
          <Box>
            <Typography variant="body1" sx={{ mb: 1, fontWeight: "medium" }}>
              Reason:
            </Typography>
            <TextField
              fullWidth
              value={bonusReason}
              onChange={(e) => setBonusReason(e.target.value)}
              variant="outlined"
              multiline
              rows={4}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", py: 2 }}>
          <OliveButton onClick={handleSaveBonus}>Save</OliveButton>
        </DialogActions>
      </Dialog>

      {/* Panelty Dialog */}
      <Dialog
        open={penaltyDialogOpen}
        onClose={handleClosePenaltyDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pb: 1,
          }}
        >
          <Typography variant="h6">Penalty</Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClosePenaltyDialog}
            aria-label="close"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ mb: 3 }}>
            <Typography variant="body1" sx={{ mb: 1, fontWeight: "medium" }}>
              Amount:
            </Typography>
            <TextField
              fullWidth
              value={penaltyAmount}
              onChange={(e) => setPenaltyAmount(e.target.value)}
              variant="outlined"
              type="number"
              inputProps={{ min: 0, step: 0.01 }}
            />
          </Box>
          <Box>
            <Typography variant="body1" sx={{ mb: 1, fontWeight: "medium" }}>
              Reason:
            </Typography>
            <TextField
              fullWidth
              value={penaltyReason}
              onChange={(e) => setPenaltyReason(e.target.value)}
              variant="outlined"
              multiline
              rows={4}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", py: 2 }}>
          <OliveButton onClick={handleSavePenalty}>Save</OliveButton>
        </DialogActions>
      </Dialog>

      {/* Editor Summery Dialog */}
      <Dialog
        open={editorSummeryDialogOpen}
        onClose={handleCloseEditorSummeryDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pb: 1,
          }}
        >
          <Typography variant="h6">Editor Summery:</Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseEditorSummeryDialog}
            aria-label="close"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box>
            <TextField
              fullWidth
              value={editorSummery}
              onChange={(e) => setEditorSummery(e.target.value)}
              variant="outlined"
              multiline
              rows={4}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", py: 2 }}>
          <OliveButton onClick={handleSaveEditorSummery}>Save</OliveButton>
        </DialogActions>
      </Dialog>

      {/* Shopper Rating Dialog */}
      <Dialog
        open={ratingDialogOpen}
        onClose={handleCloseRatingDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            pb: 1,
          }}
        >
          <Typography variant="h6">Shopper Rating</Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleCloseRatingDialog}
            aria-label="close"
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Box sx={{ mb: 3 }}>
            {/* Top row ratings */}
            <Grid container spacing={3}>
              {/* Overall Report */}
              <Grid item xs={4}>
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    Overall Report:
                  </Typography>
                  <RatingEmoji rating={ratings.overallReport} />
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Rating
                      name="overall-report"
                      value={ratings.overallReport}
                      onChange={(_, newValue) => {
                        if (newValue !== null) {
                          handleRatingChange("overallReport", newValue);
                        }
                      }}
                    />
                  </Box>
                </Box>
              </Grid>

              {/* Information */}
              <Grid item xs={4}>
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    Information:
                  </Typography>
                  <RatingEmoji rating={ratings.information} />
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Rating
                      name="information"
                      value={ratings.information}
                      onChange={(_, newValue) => {
                        if (newValue !== null) {
                          handleRatingChange("information", newValue);
                        }
                      }}
                    />
                  </Box>
                </Box>
              </Grid>

              {/* Quality */}
              <Grid item xs={4}>
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    Quality:
                  </Typography>
                  <RatingEmoji rating={ratings.quality} />
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Rating
                      name="quality"
                      value={ratings.quality}
                      onChange={(_, newValue) => {
                        if (newValue !== null) {
                          handleRatingChange("quality", newValue);
                        }
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <Divider sx={{ my: 3 }} />

            {/* Bottom row ratings */}
            <Grid container spacing={3}>
              {/* Spelling and Grammar */}
              <Grid item xs={6}>
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    Spelling and Grammar:
                  </Typography>
                  <RatingEmoji rating={ratings.spellingAndGrammar} />
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Rating
                      name="spelling-grammar"
                      value={ratings.spellingAndGrammar}
                      onChange={(_, newValue) => {
                        if (newValue !== null) {
                          handleRatingChange("spellingAndGrammar", newValue);
                        }
                      }}
                    />
                  </Box>
                </Box>
              </Grid>

              {/* Photo Quality */}
              <Grid item xs={6}>
                <Box sx={{ textAlign: "center" }}>
                  <Typography variant="body1" sx={{ mb: 1 }}>
                    Photo Quality:
                  </Typography>
                  <RatingEmoji rating={ratings.photoQuality} />
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Rating
                      name="photo-quality"
                      value={ratings.photoQuality}
                      onChange={(_, newValue) => {
                        if (newValue !== null) {
                          handleRatingChange("photoQuality", newValue);
                        }
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>

            {/* Comments Section */}
            <Box sx={{ mt: 4 }}>
              <Typography variant="body1" sx={{ mb: 1, fontWeight: "medium" }}>
                Comments:
              </Typography>

              {/* Rich Text Editor */}
              <PatchedReactQuill
                setEditorValue={setRatingComment}
                editorValue={ratingComment}
              />
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", py: 2 }}>
          <OliveButton onClick={handleSaveRating}>
            Save & Inform shopper
          </OliveButton>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
