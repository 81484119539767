import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Grid,
  Paper,
  styled,
  Button,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Upload, CalendarToday } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { parseISO } from "date-fns";
import { ApiPutUpdateShopperApplicationStatus } from "../../../apiService/Core/Apicall.tsx";
import { toast } from "react-toastify";

// Styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  maxWidth: 1200,
  margin: "0 auto",
}));

const UploadBox = styled(Box)(({ theme }) => ({
  border: `1px dashed ${theme.palette.grey[400]}`,
  height: 150,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  cursor: "pointer",
  borderRadius: theme.shape.borderRadius,
}));

const DocumentUploadBox = styled(Box)(({ theme }) => ({
  border: `1px dashed ${theme.palette.grey[400]}`,
  height: 200,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  cursor: "pointer",
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(3),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(1),
}));

const SectionDescription = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: theme.palette.text.secondary,
}));

const ViewShopperApplication = () => {
  const [gender, setGender] = useState("");
  const [aldiEmployee, setAldiEmployee] = useState("");
  const [bloomsEmployee, setBloomsEmployee] = useState("");
  const [hotelEmployee, setHotelEmployee] = useState("");
  const [dob, setDob] = useState<Date | null>(null);
  const [profileImage, setProfileImage] = useState<string | null>(null);
  const [documentImage, setDocumentImage] = useState<string | null>(null);
  const [taxOption, setTaxOption] = useState("");
  const [taxDocument, setTaxDocument] = useState<string | null>(null);

  const navigate = useNavigate();

  const location = useLocation();
  const shopperDetails = location?.state?.shopperDetails;
  console.log("shopper details", shopperDetails);

  const handleProfileImageUpload = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setProfileImage(e.target?.result as string);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleDocumentUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setDocumentImage(e.target?.result as string);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleTaxDocumentUpload = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setTaxDocument(e.target?.result as string);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const updateApplicationStatus = async (shopper: any, updatedStatus: any) => {
      const data = {
        userId: shopper.userId,
        status: updatedStatus,
      };
      const response: any = await ApiPutUpdateShopperApplicationStatus(data);
      toast.success("Application Status updated successfully");
      navigate(-1);
    };

  return (
    <StyledPaper elevation={0}>
      <SectionTitle variant="h6">1. Personal Details</SectionTitle>
      <SectionDescription variant="body2">
        Tell us a little about yourself.
      </SectionDescription>

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          {/* Left column - Form fields */}
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="First Name"
                required
                value={shopperDetails.firstName || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Last Name"
                required
                value={shopperDetails.lastName || ""}
                disabled
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Email"
                required
                type="email"
                value={shopperDetails.emailId || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Mobile"
                required
                value={shopperDetails.contactNo || ""}
                disabled
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Address"
                required
                value={shopperDetails.address || ""}
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="D.O.B"
                  value={
                    shopperDetails.dob ? parseISO(shopperDetails.dob) : null
                  }
                  onChange={(newValue) => setDob(newValue)}
                  slots={{
                    openPickerIcon: CalendarToday,
                  }}
                  slotProps={{
                    textField: {
                      fullWidth: true,
                      required: true,
                    },
                  }}
                  disabled
                />
              </LocalizationProvider>
            </Grid>

            <Grid item xs={12}>
              <FormControl>
                <FormLabel>Gender</FormLabel>
                <RadioGroup
                  row
                  value={shopperDetails.gender || ""}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <FormControlLabel
                    value="Male"
                    control={<Radio />}
                    label="Male"
                    disabled
                  />
                  <FormControlLabel
                    value="Female"
                    control={<Radio />}
                    disabled
                    label="Female"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        {/* Right column - Profile Image */}
        <Grid item xs={12} md={4}>
          <Box sx={{ height: "100%" }}>
            <Typography variant="subtitle1" gutterBottom required>
              Profile Image
            </Typography>
            <UploadBox
              component="label"
              sx={{
                height: "300px",
                marginTop: 0,
              }}
            >
              {shopperDetails.profilePicUrl ? (
                <img
                  src={shopperDetails.profilePicUrl || "/placeholder.svg"}
                  alt="Profile"
                  style={{
                    maxHeight: "100%",
                    maxWidth: "100%",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <>
                  <Upload />
                  <Typography variant="body2">Upload Here</Typography>
                  <input
                    type="file"
                    hidden
                    onChange={handleProfileImageUpload}
                    accept="image/*"
                  />
                </>
              )}
            </UploadBox>
          </Box>
        </Grid>
      </Grid>

      {/* Other Details Section */}
      <SectionTitle variant="h6">2. Other Details</SectionTitle>
      <SectionDescription variant="body2">
        Additional details to complete profile.
      </SectionDescription>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl>
            <FormLabel>
              Do you or an immediate family member or close friend work for
              ALDI?
            </FormLabel>
            <RadioGroup
              row
              value={shopperDetails.workForAldi}
              onChange={(e) => setAldiEmployee(e.target.value)}
            >
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Yes"
                disabled
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="No"
                disabled
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel>
              Do you or an immediate family member or close friend work for
              Blooms the Chemist?
            </FormLabel>
            <RadioGroup
              row
              value={shopperDetails.workForBlooms}
              onChange={(e) => setBloomsEmployee(e.target.value)}
            >
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Yes"
                disabled
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="No"
                disabled
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <FormControl>
            <FormLabel>
              Do you or an immediate family member or close friend work for a
              hotel/pub chain?
            </FormLabel>
            <RadioGroup
              row
              value={shopperDetails.workForHotelpub}
              onChange={(e) => setHotelEmployee(e.target.value)}
            >
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Yes"
                disabled
              />
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="No"
                disabled
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Hotel/Pub Name"
            value={shopperDetails.hotelPubName}
            disabled
          />
        </Grid>
      </Grid>

      {/* Document Details Section */}
      <SectionTitle variant="h6">3. Document Details</SectionTitle>
      <SectionDescription variant="body2">
        Upload documents for verification.
      </SectionDescription>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box sx={{ height: "100%" }}>
            <Typography variant="subtitle1" gutterBottom required>
              Driver's License or Photo ID
            </Typography>
            <DocumentUploadBox
              component="label"
              sx={{
                height: "300px",
                marginTop: 0,
              }}
            >
              {shopperDetails.driving_licence ? (
                <img
                  src={shopperDetails.driving_licence || "/placeholder.svg"}
                  alt="Document"
                  style={{
                    maxHeight: "100%",
                    maxWidth: "100%",
                    objectFit: "cover",
                  }}
                />
              ) : (
                <>
                  <Upload />
                  <Typography variant="body2">
                    Add your Driver's License or Photo ID here*
                  </Typography>
                  <input
                    type="file"
                    hidden
                    onChange={handleDocumentUpload}
                    accept="image/*"
                    disabled
                  />
                </>
              )}
            </DocumentUploadBox>
          </Box>
        </Grid>
      </Grid>

      {/* Write a Review Section */}
      <SectionTitle variant="h6">4. Write a Review</SectionTitle>
      <SectionDescription variant="body2">
        Please write a 100-200 word review about the customer service experience
        you have had at a store, restaurant or hotel. This should be fact-based.
      </SectionDescription>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={4}
            placeholder="Write your review here"
            value={shopperDetails?.shopperOtherDetails[0]?.shoppperDetail || ""}
            disabled
          />
        </Grid>
      </Grid>
      {/* <div style={{ display: "flex", justifyContent: "center", gap: "10px", margin: "10px" }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="table-row-background"
        >
          Approve
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="table-row-background"
        >
          Reject
        </Button>
      </div> */}
    </StyledPaper>
  );
};

export default ViewShopperApplication;
