import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'

const AreaManager: React.FC = () => {
  const AreaManagers = [
    {
      id: 1,
      AreaManager: 'Admin 1',
      UserName: 'admin',
      Password: 'password',
      Email: 'areamanager@example.com'
    },
    {
      id: 2,
      AreaManager: 'Admin 1',
      UserName: 'admin',
      Password: 'password',
      Email: 'areamanager@example.com'
    }
  ]

  const navigate = useNavigate()

  const addOrUpdateAreaManager = (id?: any) => {
    if (id) {
      navigate(`/admin/add-or-update-areamanager/update/${id}`)
    } else {
      navigate('/admin/add-or-update-areamanager/add')
    }
  }
  return (
    <div className='manage-admin-container'>
      <div className='manage-button'>
        <h1 className='common-manage-h1'>Area Manager</h1>
        <div className='add-button common-manage-button'>
          <Button
            variant='contained'
            onClick={() => addOrUpdateAreaManager()}
            className='table-row-background '
          >
            Add New Area Manager
          </Button>
        </div>
      </div>
      <Table>
        <TableHead>
          <TableRow className='table-row-background'>
            {["","Area Manager","UserName","Password","Email",""].map((item)=>(<TableCell  style={{ color: '#fff' }} >{item}</TableCell>))}
          </TableRow>
        </TableHead>
        <TableBody>
          {AreaManagers.map(user => (
            <TableRow key={user.id}>
              <TableCell>
                <Button variant='contained' className='delete-transparent'>
                  <DeleteIcon className='delete-icon' />
                </Button>
              </TableCell>
              <TableCell>{user.AreaManager}</TableCell>
              <TableCell>{user.UserName}</TableCell>
              <TableCell>{user.Password}</TableCell>
              <TableCell>{user.Email}</TableCell>
              <TableCell>
                <Button
                  variant='contained'
                  onClick={() => addOrUpdateAreaManager(user.id)}
                  className='table-row-background'
                >
                  Edit
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default AreaManager
