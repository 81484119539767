import { Button, TextField } from '@mui/material'
import React from 'react'

const ImportExportQuestions: React.FC = () => {
  return (
    <div className='manage-admin-container'>
      <h1 className='common-manage-h1'>Import Export Questions</h1>

      <div className='import-export-flex mt-4'>
        <div className='import-export-box'>
          <h5>Export All Questions</h5>
          <Button variant='contained' className='table-row-background mt-3'>
            Export All
          </Button>
        </div>
        <div className='import-export-box'>
          <h5>Import Questions</h5>
          <TextField type='file' className='m-3 p-0 import-file' variant='outlined' />
          <Button variant='contained' className='table-row-background m-3'>
            Upload
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ImportExportQuestions
