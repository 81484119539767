import React from "react"
import { useEffect, useState } from "react"
import Icons from "../../../global/constants/icons.ts"
import { TextField, MenuItem, Select, FormControl, InputLabel, InputAdornment } from "@mui/material"
import { ApiGetAllocations } from "../../../apiService/Core/Apicall.tsx"

interface EventCardProps {
  company: string
  product: string
  time: string
  userName: string
}

const MonthlyCalendar: React.FC<EventCardProps> = ({}) => {
  const [search, setSearch] = useState("")
  const [selectedClient, setSelectedClient] = useState("")
  const [selectedBusiness, setSelectedBusiness] = useState("")
  const [selectedVenue, setSelectedVenue] = useState("")
  const [selectedState, setSelectedState] = useState("")
  const [allocations, setAllocations] = useState([])

  const clients = ["Client A", "Client B", "Client C"]
  const businesses = ["Business X", "Business Y", "Business Z"]
  const venues = ["Venue 1", "Venue 2", "Venue 3"]
  const states = ["California", "Texas", "New York"]
  const date = new Date()
  const day = date.toLocaleDateString("en-US", { weekday: "long" })

  // Generate an array of all days in March
  const marchDays = Array.from({ length: 31 }, (_, i) => {
    const date = new Date(2025, 2, i + 1) // March is month 2 in JS (0-based index)
    return {
      fullDate: date.toLocaleDateString("en-US", { month: "short", day: "numeric" }),
      weekday: date.toLocaleDateString("en-US", { weekday: "long" }),
      isoDate: date.toISOString(), // Store the ISO date string for comparison
      dayOfMonth: i + 1, // Store the day of month for easier comparison
    }
  })

  const events = [
    {
      id: 1,
      company: "Glenmark",
      product: "Altonamidoz",
      time: "3 PM Onwards",
      userName: "John McClane",
      status: "yellow",
      selectedVisitDate: "2025-03-11T00:00:00.000Z", // Added the selectedVisitDate property
    },
  ]

  useEffect(() => {
    const fetchData = async () => {
      const currentDate = new Date()
      const jobMonth = currentDate.getMonth() + 1
      const jobYear = currentDate.getFullYear()
      //   await ApiCallGetAllocations(jobMonth, jobYear, debouncedVenue)
      await handleGetAllocation()
    }
    fetchData()
    return () => {}
  }, [])

  const handleGetAllocation = async () => {
    const data = "?offset=0&limit=10&allocationStatus=approved"
    try {
      const res: any = await ApiGetAllocations(data)
      setAllocations(res.data)
    } catch (error) {
      console.error("Error during API call:", error)
    }
  }

  console.log(allocations)

  return (
    <div style={{ backgroundColor: "white" }}>
      <div className="scheduleContainer">
        <div className="scheduleLeft-slide"></div>
        <div className="scheduleRight-slide">
          <div className="card-container">
            <div
              style={{
                display: "flex",
                gap: "16px",
                width: "100%",
                justifyContent: "space-between",
                margin: "auto",
              }}
            >
              <div style={{ width: "50%" }}>
                <TextField
                  variant="outlined"
                  placeholder="Search here..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  sx={{
                    borderRadius: "10px",
                    "& fieldset": {
                      borderRadius: "10px",
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img src={Icons.searchNormal || "/placeholder.svg"} />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "16px",
                  width: "100%",
                  justifyContent: "space-between",
                  margin: "auto",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel>Select Client</InputLabel>
                  <Select
                    value={selectedClient}
                    onChange={(e) => setSelectedClient(e.target.value)}
                    sx={{
                      borderRadius: "10px",
                      "& fieldset": {
                        borderRadius: "10px",
                      },
                    }}
                  >
                    {clients.map((client, index) => (
                      <MenuItem key={index} value={client}>
                        {client}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel>Select Business</InputLabel>

                  <Select
                    value={selectedBusiness}
                    onChange={(e) => setSelectedBusiness(e.target.value)}
                    sx={{
                      borderRadius: "10px",
                      "& fieldset": {
                        borderRadius: "10px",
                      },
                    }}
                  >
                    {businesses.map((business, index) => (
                      <MenuItem key={index} value={business}>
                        {business}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel>Select Venue</InputLabel>

                  <Select
                    value={selectedVenue}
                    onChange={(e) => setSelectedVenue(e.target.value)}
                    sx={{
                      borderRadius: "10px",
                      "& fieldset": {
                        borderRadius: "10px",
                      },
                    }}
                  >
                    {venues.map((venue, index) => (
                      <MenuItem key={index} value={venue}>
                        {venue}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel>Select State</InputLabel>
                  <Select
                    value={selectedState}
                    onChange={(e) => setSelectedState(e.target.value)}
                    sx={{
                      borderRadius: "10px",
                      "& fieldset": {
                        borderRadius: "10px",
                      },
                    }}
                  >
                    {states.map((state, index) => (
                      <MenuItem key={index} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
      </div>

      {marchDays.map(({ fullDate, weekday, dayOfMonth }, index) => (
        <div key={index} className="scheduleContainer">
          <div className="scheduleLeft-slide">
            {fullDate} <br />
            {weekday}
          </div>
          <div className="scheduleRight-slide">
            <div className="card-container">
              {/* Filter events to only show on the matching date (March 11th) */}
              {allocations
                .filter((allocation: any) => {
                  // Extract the day from the selectedVisitDate
                  const eventDate = new Date(allocation?.appliedData?.selectedVisitDate)
                  const eventDay = eventDate.getDate()
                  // Only show events where the day matches the current day in the loop
                  return eventDay === dayOfMonth
                })
                .map((allocation:any, i) => (
                  <div
                    key={i}
                    className="event-card"
                    style={{
                      backgroundColor: "#FFF1B3",
                      border: "1px solid #CDCDCD",
                      //borderLeft: event.status === "yellow" ? "10px solid #FFC94B" : "",
                    }}
                  >
                    <div className="event-info">
                      <img src={Icons.GlenMark || "/placeholder.svg"} alt="Company Logo" className="company-logo" />
                      <div>
                        <p className="company-name">
                          {allocation?.venueId?.venueName} <span className="product-name">| {allocation?.appliedData?.userName}</span>
                        </p>
                        <p className="event-time">{allocation?.appliedData?.selectedVisitTime?.visitTimeInformation}</p>
                      </div>
                    </div>
                    <div className="event-footer">
                      <div className="user-info">
                        {/* <img src={Icons.user || "/placeholder.svg"} alt="user" /> */}
                        <p className="user-name">{allocation?.venueId?.shopTypeId?.shopTypeName}</p>
                      </div>
                      <div className="calendar-icons">
                        {/* <img src={Icons.calendar || "/placeholder.svg"} alt="calendar" className="calendar-icon" />
                        <img src={Icons.calendar || "/placeholder.svg"} alt="calendar" className="calendar-icon" />
                        <img src={Icons.calendar || "/placeholder.svg"} alt="calendar" className="calendar-icon" /> */}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default MonthlyCalendar

