import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Login from '../screens/front/Login/Login.tsx'
import ProtectedRoute from './ProtectedRoutes.tsx'
import Unauthorized from './UnAuthorized.tsx'
import AdminDashboard from '../screens/admin/AdminDashboard/AdminDashboard.tsx'
import ManageAdmin from '../screens/admin/ManageAdmin/ManageAdmin.tsx' // Import ManageAdmin component
import Home from '../screens/front/Home/Home.tsx'
import Layout from '../Components/Layout/Layout.tsx'
import EditAccessGroup from '../screens/admin/ManageAdmin/EditAccessGroup.tsx'
import AddOrUpdate from '../screens/admin/ManageAdmin/AddOrUpdateAdmin.tsx'
import ManageStore from '../screens/admin/ManageStore/ManageStore.tsx'
import AddOrUpdateStore from '../screens/admin/ManageStore/AddOrUpdateStore.tsx'
import ManageClients from '../screens/admin/ManageClients/ManageClients.tsx'
import AddOrUpdateClients from '../screens/admin/ManageClients/AddOrUpdateClients.tsx'
import ManageVenue from '../screens/admin/ManageVenue/ManageVenue.tsx'
import AddOrUpdateVenue from '../screens/admin/ManageVenue/AddOrUpdateVenue.tsx'
import ImportExportQuestions from '../screens/admin/ImportExportQuestions/ImportExportQuestions.tsx'
import ImportExportQuestionsManager from '../screens/admin/ImportExportQuestionsManager/ImporttExportQuestionsManager.tsx'
import AreaManager from '../screens/admin/AreaManager/AreaManager.tsx'
import AddOrUpdateAreaManager from '../screens/admin/AreaManager/AddOrUpdateAreaManager.tsx'
import VenueMap from '../screens/admin/ManageVenue/VenueMap.tsx'
import BriefJson from '../screens/admin/BriefJson/BriefJson.tsx'
import AddOrUpdateBriefJson from '../screens/admin/BriefJson/AddOrUpdateBriefJson.tsx'
import ViewEditorsBusiness from '../screens/admin/ManageStore/ViewEditorsBusiness.tsx'
import ViewEditorsVenue from '../screens/admin/ManageVenue/ViewEditorsVenue.tsx'
import TermsAndConditions from '../components/TermsAndConditions/TermsAndConditions.tsx'
import TrainingCalendar from '../screens/admin/TrainingCalendar/TrainingCalendar.tsx'
import ManageVisitTime from '../screens/admin/ManageVisitTime/ManageVisitTime.tsx'
import AddOrUpdateManageVisit from '../screens/admin/ManageVisitTime/AddOrUpdateManageVisit.tsx'
import ViewJobCriteriaBusiness from '../screens/admin/ManageStore/ViewJobCriteriaBusiness.tsx'
import ViewJobCriteriaVenue from '../screens/admin/ManageVenue/ViewJobCriteriaVenue.tsx'
import CreateAllocations from '../screens/admin/CreateAllocations/CreateAllocations.tsx'
import ApproveAllocation from '../screens/admin/ApproveAllocation/ApproveAllocation.tsx'
import ShopperApplications from '../screens/admin/ShopperApplications/ShopperApplications.tsx'
import ViewShopperApplication from '../screens/admin/ShopperApplications/ViewShopperApplication.tsx'
import OrphanAllocation from '../screens/admin/OrphanAllocation/OrphanAllocation.tsx'
import InviteShopper from '../screens/admin/OrphanAllocation/InviteShopper.tsx'
import MonthlyCalendar from '../screens/admin/MonthlyCalendar/MonthlyCalendar.tsx';
import CreateNewTemplate from '../screens/admin/CreateNewTemplate/CreateNewTemplate.tsx'
import QuestionBank from '../screens/admin/QuestionBank/QuestionBank.tsx'
import CreateNewQuestion from '../screens/admin/CreateNewQuestion/CreateNewQuestion.tsx'
import TemplateListing from '../screens/admin/Template Listing/TemplateListing.tsx'
import CreateClientTemplate from '../screens/admin/CreateClientTemplate/CreateClientTemplate.tsx'
import CreateVenueSiteTemplate from '../screens/admin/CreateVenueSiteTemplate copy/CreateVenueSiteTemplate.tsx'
import ManageReports from '../screens/admin/ManageReports/ManageReports.tsx'
import AllReports from '../screens/admin/Manage Reports/AllReports.tsx'
const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/login' element={<Login />} />
      <Route path='/unauthorized' element={<Unauthorized />} />
      <Route path='/venue-map' element={<VenueMap />} />

      {/* Protected Route for Admin Dashboard */}
      <Route
        path='/admin/dashboard'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <AdminDashboard />
            </Layout>
          </ProtectedRoute>
        }
      />

      {/* Protected Route for Manage Admin */}
      <Route
        path='/admin/manage-admin'
        element={
          <ProtectedRoute allowedRoles={['1']}>
            <Layout>
              <ManageAdmin /> {/* Use ManageAdmin component here */}
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path='/admin/manage-store'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <ManageStore />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path='/admin/manage-clients'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <ManageClients />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path='/admin/venue-managers'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <ManageVenue />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path='/admin/import-export'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <ImportExportQuestions />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path='/admin/import-export-manager'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <ImportExportQuestionsManager />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path='/admin/question-bank'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <QuestionBank/>
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path='/admin/create-new-question'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <CreateNewQuestion/>
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path='/admin/brief-json'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <BriefJson />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path='/admin/create-new-template'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <CreateNewTemplate />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path='/admin/create-client-template'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <CreateClientTemplate />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path='/admin/create-venue-site-template'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <CreateVenueSiteTemplate/>
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path='/admin/manage-reports'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <ManageReports/>
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path='/admin/all-reports'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <AllReports/>
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path='/admin/area-managers'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <AreaManager />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path='/admin/training-calendar'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <TrainingCalendar />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path='/admin/clients-update/:mode/:id?'
        element={
          <ProtectedRoute allowedRoles={['1']}>
            <Layout>
              <AddOrUpdateClients
                onSubmit={function (values: any): void { }}
                mode={'add'}
              />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path='/admin/add-or-update/:mode/:id?'
        element={
          <ProtectedRoute allowedRoles={['1']}>
            <Layout>
              <AddOrUpdate
                onSubmit={function (values: any): void { }}
                mode={'add'}
              />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path='/admin/add-or-update-store/:mode/:id?'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <AddOrUpdateStore
                onSubmit={function (values: any): void { }}
                mode={'add'}
              />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path='/admin/add-or-update-venue/:mode/:id?'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <AddOrUpdateVenue
                onSubmit={function (values: any): void { }}
                mode={'add'}
              />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path='/admin/add-or-update-areamanager/:mode/:id?'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <AddOrUpdateAreaManager
                onSubmit={function (values: any): void { }}
                mode={'add'}
              />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path='/admin/add-or-update-briefjson/:mode/:id?'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <AddOrUpdateBriefJson
                onSubmit={function (values: any): void { }}
                mode={'add'}
              />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path='/admin/edit-access-group'
        element={
          <ProtectedRoute allowedRoles={['1']}>
            <Layout>
              <EditAccessGroup />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path='/admin/view-editor-business'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <ViewEditorsBusiness
                onSubmit={function (values: any): void {
                  throw new Error('Function not implemented.')
                }}
              />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path='/admin/view-editor-site'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <ViewEditorsVenue
                onSubmit={function (values: any): void {
                  throw new Error('Function not implemented.')
                }}
              />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path='/admin/view-business-jobcriteria'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <ViewJobCriteriaBusiness
                onSubmit={function (values: any): void {
                  throw new Error('Function not implemented.')
                }}
              />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path='/admin/view-venue-jobcriteria'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <ViewJobCriteriaVenue
                onSubmit={function (values: any): void {
                  throw new Error('Function not implemented.')
                }}
              />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path='/admin/allocations/manage-visit-times'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <ManageVisitTime />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path='/admin/allocations/add-or-update-visittime/:mode/:id?'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <AddOrUpdateManageVisit
                onSubmit={function (values: any): void { }}
                mode={'add'}
              />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path='/admin/allocations/create-monthly-allocation'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <CreateAllocations />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path='/admin/allocations/approve-allocation'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <ApproveAllocation />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path='/admin/shoppers/shopper-application'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <ShopperApplications />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path='/admin/shoppers/view-shopper-application'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <ViewShopperApplication />
            </Layout>
          </ProtectedRoute>
        }
      />

      <Route
        path='/admin/allocations/orphan-allocation'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <OrphanAllocation />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path='/admin/allocations/Invite-Shopper'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <InviteShopper />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path='admin/allocations/monthly-calendar'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <MonthlyCalendar />
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path='/admin/template-listing'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <TemplateListing/>
            </Layout>
          </ProtectedRoute>
        }
      />
      <Route
        path='/admin/manage-reports'
        element={
          <ProtectedRoute allowedRoles={['1', '2']}>
            <Layout>
              <ManageReports />
            </Layout>
          </ProtectedRoute>
        }
      />
    </Routes>
    
  )
}

export default AppRoutes
