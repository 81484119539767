import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { TextField, Button, Box, Grid, MenuItem } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ApiPutUpdateClient,
  ApiPostCreateClient,
  ApiGetClientDbName,
} from "../../../apiService/Core/Apicall.tsx";

const AddOrUpdateClients = ({
  initialValues = {
    clientCode: "",
    clientName: "",
    ownerName: "",
    ownerEmail: "",
    isActive: "1",
    clientGroupName: "",
  },
  onSubmit,
}) => {
  const [clientGroupOptions, setClientGroupOptions] = useState([]);
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      clientCode: Yup.string().required("Client Code is required"),
      clientName: Yup.string().required("Client Name is required"),
      ownerName: Yup.string().required("Owner Name is required"),
      ownerEmail: Yup.string()
        .email("Invalid email address")
        .required("Owner Email ID is required"),
      isActive: Yup.string().required("Status is required"),
      clientGroupName: Yup.string().required("Client Group Name is required")
    }),
    onSubmit: (values) => {
      onSubmit(values);
      handleCallApi();
    },
  });

  const navigate = useNavigate();
  const location = useLocation();
  const clients = location?.state?.clients;
  const { mode, id } = useParams();
  const [error, setError] = useState(null);

  useEffect(() => {
    if (mode === "update" && id) {
      console.log(`Fetching data for client with ID: ${id}`);
    }
    handleCallGetClientDbName();
  }, [mode, id]);

  const handleCallGetClientDbName = async () => {
    try {
      {
        const response = await ApiGetClientDbName();
        setClientGroupOptions(response.data);
      }
    } catch (error) {
      console.error("Error during API call:", error);
      toast.error(`${error}`);
    }
  };
  console.log(clientGroupOptions);

  useEffect(() => {
    if (clients) {
      console.log("response getUserDetails", clients?.ClientName);

      if (mode === "update") {
        formik.setValues({
          ...formik.values,
          clientCode: clients.ClientCode,
          clientName: clients.ClientName,
          ownerName: clients.OwnerName,
          ownerEmail: clients.OwnerEmailID,
          clientGroupName: clients.ClientGroupName.clientGroupName || "",
        });
      }
    }
  }, [clients, mode]);

  const handleCallApi = async () => {
    const FormData = {
      ClientCode: formik.values.clientCode,
      ClientName: formik.values.clientName,
      OwnerName: formik.values.ownerName,
      OwnerEmailID: formik.values.ownerEmail,
      ClientGroupName: formik.values.clientGroupName,
      CreatedBy: 11,
    };
    const FormDataUpdata = {
      ClientID: clients?.ClientID,
      ClientCode: formik.values.clientCode,
      ClientName: formik.values.clientName,
      OwnerName: formik.values.ownerName,
      OwnerEmailID: formik.values.ownerEmail,
      ClientGroupName: formik.values.clientGroupName,
      ModifiedBy: 11,
    };

    try {
      if (mode === "add") {
        await ApiPostCreateClient(FormData);
        toast.success("Client added successfully!");
      } else {
        await ApiPutUpdateClient(FormDataUpdata);
        toast.success("Client updated successfully!");
      }
      navigate(-1);
    } catch (error) {
      console.error("Error during API call:", error);
      toast.error(`${error}`);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={formik.handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        maxWidth: 800,
        margin: "auto",
        padding: 3,
        backgroundColor: "#f9f9f9",
        borderRadius: 2,
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <h1>{mode === "add" ? "Add New Client" : "Update Client"}</h1>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Client Code"
            name="clientCode"
            value={formik.values.clientCode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.clientCode && Boolean(formik.errors.clientCode)
            }
            helperText={formik.touched.clientCode && formik.errors.clientCode}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Client Name"
            name="clientName"
            value={formik.values.clientName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.clientName && Boolean(formik.errors.clientName)
            }
            helperText={formik.touched.clientName && formik.errors.clientName}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Owner Name"
            name="ownerName"
            value={formik.values.ownerName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.ownerName && Boolean(formik.errors.ownerName)}
            helperText={formik.touched.ownerName && formik.errors.ownerName}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            label="Owner Email ID"
            name="ownerEmail"
            type="email"
            value={formik.values.ownerEmail}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.ownerEmail && Boolean(formik.errors.ownerEmail)
            }
            helperText={formik.touched.ownerEmail && formik.errors.ownerEmail}
            fullWidth
          />
        </Grid>
      </Grid>
      <TextField
        select
        label="Client Group Name"
        name="clientGroupName"
        value={formik.values.clientGroupName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          formik.touched.clientGroupName &&
          Boolean(formik.errors.clientGroupName)
        }
        helperText={
          formik.touched.clientGroupName && formik.errors.clientGroupName
        }
        fullWidth
      >
        {clientGroupOptions.map((option: any) => (
          <MenuItem
            key={option.clientGroupDBName}
            value={option.clientGroupName}
          >
            {option.clientGroupName}
          </MenuItem>
        ))}
      </TextField>

      <Button
        className="table-row-background"
        type="submit"
        variant="contained"
        color="primary"
        style={{ marginTop: "20px" }}
      >
        {mode === "add" ? "Add Client" : "Update Client"}
      </Button>
    </Box>
  );
};

export default AddOrUpdateClients;
