import React from "react";
import { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
  FormControl,
  IconButton,
  Stack,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Pagination,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {
  ApiGetAllocations,
  ApiGetVisitTimes,
  ApiGetVenueList,
  ApiPutUpdateAllocation,
  ApiPostCreateAllocations,
  ApiGetClientEntities,
  ApiPutCancelJob,
} from "../../../apiService/Core/Apicall.tsx";
import { toast } from "react-toastify";
import moment from "moment";
import useDebounce from "./UseDebounce.tsx";
import { useDispatch } from "react-redux";
import {
  visitDaysOptions,
  monthsData,
} from "../../../global/constants/globalContants.ts";

interface JobAllocation {
  id: Number;
  venue: string;
  address: string;
  postcode: string;
  tradeHours: string;
  time: string;
}

export default function CreateAllocations() {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [allocations, setAllocations] = useState<JobAllocation[]>([]);
  const [startDate, setStartDate] = useState<any>("");
  const [endDate, setEndDate] = useState<any>("");
  const [bulkCreateValue, setBulkCreateValue] = useState("Bulk Create");
  const [monthly, setMonthly] = useState([]);
  const [every, setEvery] = useState("");
  const [visitTimes, setVisitTimes] = useState<any>([]);
  const [venus, setVenus] = useState([]);
  const [visitDays, setVisitDays] = useState<any>("");
  const [visitTimesValue, setVisitTimesValue] = useState<any>("");
  const [allocation, setAllocation] = useState<any>();
  const [allocationStatus, setAllocationStatus] = useState<any>();
  const [selectedValues, setSelectedValues] = useState("");
  const [venueName, setVenueName] = useState([]);
  const [venueNameValue, setVenueNameValue] = useState<any>(null);
  const [clientEntityList, setClientEntityList] = useState<any>([]);
  const [clientEntityId, setClientEntityId] = useState<string | null>(null);
  const [addComment, setAddComment] = useState("");
  const debouncedClientEntityId = useDebounce(clientEntityId, 500);
  const debouncedVenue = useDebounce(venueNameValue, 700);
  const [minAge, setMinAge] = useState();
  const [maxAge, setMaxAge] = useState();
  const [gender, setGender] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6;

  // Get the current slice of allocations for the page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentAllocations = allocations.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const date = new Date();
  const currentMonthYear = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}`;
  const [values, setValues] = useState({
    fromDate: "",
    toDate: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleCommentChange = (e) => {
    setAddComment(e.target.value);
  };

  // const handlePrevMonth = () => {
  //   setCurrentDate(
  //     prevDate => new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, 1)
  //   )
  // }

  const handlePrevMonth = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(
        prevDate.getFullYear(),
        prevDate.getMonth() - 1,
        1
      );
      const jobMonth = newDate.getMonth() + 1; // Convert to 1-based month
      const jobYear = newDate.getFullYear();

      // Call the API with the new month and year
      ApiCallGetAllocations(jobMonth, jobYear);

      return newDate; // Update the state with the new date
    });
  };

  const handleNextMonth = () => {
    setCurrentDate((prevDate) => {
      const newDate = new Date(
        prevDate.getFullYear(),
        prevDate.getMonth() + 1,
        1
      );
      const jobMonth = newDate.getMonth() + 1; // Convert to 1-based month
      const jobYear = newDate.getFullYear();

      // Call the API with the new month and year
      ApiCallGetAllocations(jobMonth, jobYear);

      return newDate; // Update the state with the new date
    });
  };
  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = new Date(event.target.value + "-01");
    setCurrentDate(newDate);
  };

  const formatDate = (date: Date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}`;
  };

  const mmOptions = ["MM", "ALDI", "BLOOMS"];

  const handleSelectChangeVenue = (e) => {
    setSelectedValues(e.target.value);
  };
  useEffect(() => {
    const fetchData = async () => {
      const currentDate = new Date();
      const jobMonth = currentDate.getMonth() + 1;
      const jobYear = currentDate.getFullYear();
      await ApiCallGetAllocations(jobMonth, jobYear, debouncedVenue);
      await handleGetVisitTimeList();
      await ApiCallGetVenueList();
      await handleGetClientList();
    };
    fetchData();
    return () => {};
  }, [debouncedVenue]);
  const dispatch = useDispatch();
  const ApiCallGetAllocations = async (
    jobMonth: number,
    jobYear: number,
    debouncedVenue?: any
  ) => {
    //const data = `?offset=0&limit=100&venueId=${venueNameValue}&jobMonth=${jobMonth}&jobYear=${jobYear}`;
    const data =
      `?offset=0&limit=100&` +
      (venueNameValue ? `&venueId=${venueNameValue}` : "") +
      `&jobMonth=${jobMonth}&jobYear=${jobYear}`;
    // const data = `?offset=0&limit=100&venueName=${venueNameValue}`
    const res = await ApiGetAllocations(data);
    setAllocations(res?.data);
  };

  const ApiCallGetVenueList = async (debouncedClientEntityId?: any) => {
    const data = `?clientEntityId=${clientEntityId}&offset=0&limit=500`;
    const res = await ApiGetVenueList(data);
    setVenus(res?.data);
    const response = res?.data.map((item) => ({
      label: item.venueName,
      value: item.venueId,
    }));
    setVenueName(response);
  };
  const handleGetVisitTimeList = async () => {
    try {
      const response: any = await ApiGetVisitTimes();
      setVisitTimes(response?.data);
      console.log("response star ", response?.data);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };
  useEffect(() => {
    if (debouncedClientEntityId) {
      ApiCallGetVenueList(debouncedClientEntityId); // Call API only when debounced value changes
    } else {
      setVenueName([]); // Clear venue list when business is cleared
      setVenueNameValue(null); // Also clear selected venue
    }
  }, [debouncedClientEntityId]);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleUpdate = async () => {
    if (Number(maxAge) <= Number(minAge) || Number(minAge) < 18) {
      return;
    }

    const param = {
      allocationId: allocation?.allocationId,
      userId: allocation.userId,
      allocationStatusId: allocationStatus,
      visitDaysInformation: visitDays.join(", "),
      visitTimeInformation: visitTimesValue.join(", "),
      specialComment: addComment,
      // minDate: moment(values.fromDate, "DD").format("YYYY-MM-DD") || "",
      // maxDate: moment(values.toDate, "DD").format("YYYY-MM-DD") || "",
      minDate: moment(values.fromDate, "YYYY-MM-DD").format("DD"),
      maxDate: moment(values.toDate, "YYYY-MM-DD").format("DD"),
      minAge: minAge,
      maxAge: maxAge,
      gender: gender,
      modifiedBy: visitTimesValue.modifiedBy,
    };
    try {
      const res = await ApiPutUpdateAllocation(param);
      const jobMonth = currentDate.getMonth() + 1;
      const jobYear = currentDate.getFullYear();
      await ApiCallGetAllocations(jobMonth, jobYear);
      setOpen(false);
      toast.success("Allocations updated successfully!");
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };
  console.log(allocation)
  console.log(values)

  const handleAddedAllocations = async () => {
    if (selectedValues === "") {
      toast.error("Select Value");
      return;
    }
    // else if (monthly.length === 0) {
    //   toast.error('Select monthly')
    //   return
    // }
    else if (bulkCreateValue === "Bulk Create") {
      toast.error("Select Bulk Create");
      return;
    }
    if (bulkCreateValue === "Custom") {
      if (startDate === "") {
        toast.error("Select Start Date");
        return;
      }
    }

    const param = {
      year:
        bulkCreateValue === "Custom"
          ? parseInt(moment(startDate, "DD/MM/YYYY").format("YYYY"), 10)
          : bulkCreateValue,
      venueId: selectedValues,
      durationType: bulkCreateValue === "Custom" ? "custom_month" : "year",
      months: monthly,
    };
    try {
      console.log("Params", param);
      const res = await ApiPostCreateAllocations(param);
      toast.success("Allocations Added successfully!");
      setBulkCreateValue("Bulk Create");
      setMonthly([]);
      setEvery("");
      const jobMonth = currentDate.getMonth() + 1;
      const jobYear = currentDate.getFullYear();
      const response = ApiCallGetAllocations(jobMonth, jobYear);
    } catch (error: any) {
      console.log("Error during:", error);
      toast.error(error);
    }
  };
  const handleClearData = () => {
    handleAddedAllocations();
  };
  const handleGetClientList = async () => {
    const data = "";
    try {
      const res: any = await ApiGetClientEntities(data);
      const response = res?.data.map((item) => ({
        label: item.entityName,
        value: item.clientEntityId,
      }));
      setClientEntityList(response);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const handleCancelJob = async (allocationId) => {
    const data = `?allocationId=${allocationId}`;
    try {
      const response: any = await ApiPutCancelJob(data);
      toast.success("Allocation Cancelled successfully!");
      const currentDate = new Date();
      const jobMonth = currentDate.getMonth() + 1;
      const jobYear = currentDate.getFullYear();
      await ApiCallGetAllocations(jobMonth, jobYear);
    } catch (error) {
      console.error("Error during API call:", error);
      toast.error(error);
    }
  };

  return (
    <Box sx={{ p: 4 }} style={{ background: "white" }}>
      <Stack spacing={3}>
        <Box sx={{ width: "100%" }}>
          <h1 className="common-manage-h1" style={{ textAlign: "center" }}>
            Allocations
          </h1>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "-70px",
              marginTop: "23px",
            }}
          >
            <Stack direction="row" spacing={2}>
              <FormControl size="small" sx={{ width: 180 }}>
                <Autocomplete
                  disablePortal
                  id="beacon-autocomplete"
                  options={clientEntityList}
                  onChange={(event, newValue: any) => {
                    setClientEntityId(newValue?.value || null);
                    if (!newValue) {
                      // Clear venue when business is cleared
                      setVenueNameValue(null);
                    }
                  }}
                  value={
                    clientEntityList.find(
                      (option) => option.value === clientEntityId
                    ) || null
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Select Business" />
                  )}
                  size="small"
                />
              </FormControl>
              <FormControl size="small" sx={{ width: 180 }}>
                <Autocomplete
                  disablePortal
                  id="mm-autocomplete"
                  options={venueName}
                  value={
                    venueName.find(
                      (option) => option.value === venueNameValue
                    ) || null
                  }
                  onChange={(event, newValue) => {
                    setVenueNameValue(newValue?.value);
                  }}
                  defaultValue=""
                  renderInput={(params) => (
                    <TextField {...params} label="Search Venue" />
                  )}
                  size="small"
                />
              </FormControl>
            </Stack>
            <Stack direction="row" spacing={2}>
              <FormControl size="small" sx={{ width: 180 }}>
                <Autocomplete
                  disablePortal
                  id="mm-autocomplete"
                  options={mmOptions}
                  defaultValue="MM"
                  renderInput={(params) => (
                    <TextField {...params} label="Select Client" />
                  )}
                  size="small"
                />
              </FormControl>
            </Stack>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "70px",
          }}
        >
          <IconButton onClick={handlePrevMonth}>
            <ChevronLeftIcon />
          </IconButton>
          <Box sx={{ display: "flex", alignItems: "center", mx: 2 }}>
            <input
              type="month"
              value={formatDate(currentDate)}
              onChange={handleDateChange}
              className="primary-color"
              style={{
                border: "none",
                background: "none",
                fontSize: "1.25rem",
                fontWeight: "bold",
                cursor: "pointer",
                fontFamily: "inherit",
              }}
            />
          </Box>
          <IconButton onClick={handleNextMonth}>
            <ChevronRightIcon />
          </IconButton>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: 2,
            padding: "15px",
            borderRadius: "5px",
            alignItems: "start",
          }}
          className="min-border-color"
        >
          <FormControl size="small" sx={{ minWidth: 180 }}>
            <Select
              defaultValue=""
              displayEmpty
              value={selectedValues}
              onChange={handleSelectChangeVenue}
            >
              <MenuItem value="" disabled>
                Venue
              </MenuItem>
              {venus.map((item, index) => (
                <MenuItem key={item.venueId} value={item.venueId}>
                  {item.venueName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box sx={{ display: "grid", gap: "20px", marginLeft: "auto" }}>
            <Box sx={{ marginLeft: "auto", display: "flex", gap: 2 }}>
              <FormControl size="small" sx={{ minWidth: 150 }}>
                <Select
                  style={{ width: "150px" }}
                  multiple
                  value={monthly}
                  displayEmpty
                  onChange={(event) => {
                    {
                      setMonthly(event.target.value as string[]);
                    }
                  }}
                  renderValue={(selected) =>
                    selected.length === 0
                      ? "Monthly"
                      : selected
                          .map(
                            (id) => monthsData.find((m) => m.id === id)?.label
                          )
                          .join(", ")
                  }
                >
                  {monthsData.map((items) => (
                    <MenuItem key={items.id} value={items.id}>
                      {items.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl size="small" sx={{ minWidth: 150 }}>
                <Select
                  value={bulkCreateValue}
                  displayEmpty
                  onChange={(event) => setBulkCreateValue(event.target.value)}
                >
                  {[
                    {
                      label: "Bulk Create",
                      value: "Bulk Create",
                      disabled: true,
                    },
                    { label: "2024", value: "2024" },
                    { label: "2025", value: "2025" },
                    { label: "Custom", value: "Custom" },
                  ].map((items, index) => (
                    <MenuItem
                      value={items.value}
                      key={index}
                      disabled={items.disabled}
                    >
                      {items.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                onClick={handleClearData}
                variant="contained"
                className="table-row-background"
              >
                {/* Reset */}
                create
              </Button>
            </Box>
            {bulkCreateValue === "Custom" && (
              <div style={{ display: "flex", width: "100%" }}>
                <DatePicker
                  selected={startDate}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="Start Date"
                  showPopperArrow={false}
                  onChange={(date) => {
                    setStartDate(date);
                    setEndDate(date);
                  }}
                  showTimeSelect={false}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  customInput={
                    <div
                      className="custom-select-date"
                      style={{ marginRight: "10px" }}
                    >
                      <CalendarTodayIcon style={{ fontSize: "18px" }} />

                      <div className="fontSize16">
                        {startDate ? (
                          format(startDate, "dd/MM/yyyy")
                        ) : (
                          <div>Start Date</div>
                        )}
                      </div>
                    </div>
                  }
                />
                <DatePicker
                  selected={endDate}
                  dateFormat="yyyy/MM/dd"
                  placeholderText="Start Date"
                  showPopperArrow={false}
                  onChange={(date) => {
                    setEndDate(date);
                  }}
                  showTimeSelect={false}
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  customInput={
                    <div className="custom-select-date">
                      <CalendarTodayIcon style={{ fontSize: "18px" }} />
                      <div className="fontSize16">
                        {endDate ? (
                          format(endDate, "dd/MM/yyyy")
                        ) : (
                          <div>End Date</div>
                        )}
                      </div>
                    </div>
                  }
                />
              </div>
            )}
          </Box>
        </Box>

        <Grid
          container
          spacing={4}
          className="mt-5"
          style={{
            gap: "20px",
            paddingRight: "32px",
            flexWrap: "nowrap",
            gridTemplateColumns: "2fr 2fr",
            display: "grid",
          }}
        >
          {currentAllocations?.map((allocation: any, index) => (
            <Grid
              style={{
                padding: "0px",
                boxShadow: "none",
                maxWidth: "100%",
                width: "100%",
              }}
              item
              xs={12}
              md={6}
              key={index}
            >
              <Card className="min-border-color" style={{ boxShadow: "none" }}>
                <CardContent>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ flexGrow: 1 }}>
                      <Typography variant="h6" gutterBottom>
                        {allocation?.venueId?.venueName}
                      </Typography>
                      <Typography>{allocation?.venueId?.address}</Typography>
                      <Typography>
                        Postcode: {allocation?.venueId?.postalCode}
                      </Typography>
                      <Typography>
                        Visit Days: {allocation?.visitDaysInformation}
                      </Typography>
                      <Typography>
                        Visit Times: {allocation.time}
                        {[
                          ...new Set(
                            visitTimes
                              .filter((item) =>
                                allocation?.visitTimeInformation
                                  ?.split(",")
                                  .map((v) => v.trim())
                                  .includes(String(item.visitTimeId))
                              )
                              .flatMap((item) =>
                                item.visitTimeInformation
                                  .split(",")
                                  .map((v) => v.trim())
                              )
                          ),
                        ].join(", ")}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "16px",
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="table-row-background"
                        style={{ marginBottom: "8px", width: "80px" }}
                        onClick={() => {
                          {
                            handleClickOpen();
                            setVisitDays(
                              allocation?.visitDaysInformation
                                ? allocation.visitDaysInformation
                                    .split(",")
                                    .map((day) => day.trim())
                                : []
                            );
                            const visitTimeArray =
                              allocation?.visitTimeInformation
                                ? allocation.visitTimeInformation
                                    .split(",")
                                    .map(Number)
                                : [];
                            setVisitTimesValue(visitTimeArray);
                            setAllocation(allocation);
                            setAllocationStatus(
                              allocation?.allocationStatusId?.statusId
                            );
                            setAddComment(allocation?.specialComment || "");
                            setMinAge(allocation.minAge);
                            setMaxAge(allocation.maxAge);
                            setGender(allocation.gender);
                            setValues({
                              fromDate: `${currentMonthYear}-${String(allocation.minDate).padStart(2, "0")}`,
                              toDate: `${currentMonthYear}-${String(allocation.maxDate).padStart(2, "0")}`
                            });
                          }
                        }}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        //className="table-row-background"
                        style={{
                          marginBottom: "8px",
                          width: "80px",
                          backgroundColor: "goldenrod",
                        }}
                      >
                        {allocation?.allocationStatusId?.statusDesc}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        className="table-row-background"
                        style={{ width: "80px" }}
                        onClick={() => {
                          const isConfirmed = window.confirm(
                            "Are you sure you want to cancel the job?"
                          );
                          if (isConfirmed) {
                            handleCancelJob(allocation?.allocationId); // Call the API or action
                          }
                        }}
                      >
                        Revoke
                      </Button>
                    </div>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Stack>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle style={{ textAlign: "center" }}>
          Allocations UpDate
        </DialogTitle>
        <DialogContent>
          <div className="form-group" style={{ marginTop: "20px" }}>
            <label>Visit Days Information</label>
            <FormGroup
              style={{ display: "flex", flexDirection: "row", gap: "10px" }}
            >
              {visitDaysOptions.map((day) => (
                <FormControlLabel
                  key={day.value}
                  control={
                    <Checkbox
                      checked={visitDays.includes(day.value)}
                      onChange={(event) => {
                        const newVisitDays = event.target.checked
                          ? [...visitDays, day.value]
                          : visitDays.filter((val) => val !== day.value);
                        setVisitDays(newVisitDays);
                      }}
                    />
                  }
                  label={day.label}
                />
              ))}
            </FormGroup>
          </div>

          <div className="form-group" style={{ marginTop: "20px" }}>
            <label>Visit Time Information</label>
            <FormGroup
              style={{ display: "flex", flexDirection: "row", gap: "10px" }}
            >
              {visitTimes.map((time) => (
                <FormControlLabel
                  key={time.visitTimeId}
                  control={
                    <Checkbox
                      checked={visitTimesValue.includes(time.visitTimeId)}
                      onChange={(event) => {
                        const newVisitTimes = event.target.checked
                          ? [...visitTimesValue, time.visitTimeId]
                          : visitTimesValue.filter(
                              (val) => val !== time.visitTimeId
                            );
                        setVisitTimesValue(newVisitTimes);
                      }}
                    />
                  }
                  label={time.visitTimeInformation}
                />
              ))}
            </FormGroup>
          </div>

          <div className="form-group" style={{ marginTop: "20px" }}>
            <label>Date Change Information</label>
            <FormGroup
              style={{ display: "flex", flexDirection: "row", gap: "10px" }}
            >
              <div style={{ display: "flex", gap: "10px" }}>
                <div style={{ flex: 1 }}>
                  <TextField
                    fullWidth
                    label="From Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    name="fromDate"
                    value={values.fromDate}
                    onChange={handleChange}
                  />
                </div>

                <div style={{ flex: 1 }}>
                  <TextField
                    fullWidth
                    label="To Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    name="toDate"
                    value={values.toDate}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </FormGroup>
          </div>

          {/* Min Age and Max Age Fields Side by Side */}
          <div className="form-group" style={{ marginTop: "20px" }}>
            <label>Age Range</label>
            <FormGroup
              style={{ display: "flex", flexDirection: "row", gap: "10px" }}
            >
              <div style={{ display: "flex", gap: "10px", width: "100%" }}>
                <TextField
                  fullWidth
                  label="Min Age"
                  type="number"
                  value={minAge}
                  onChange={(e: any) => {
                    const value = e.target.value;
                    // Allow typing but validate after input
                    setMinAge(value);
                  }}
                  error={
                    minAge !== "" &&
                    (Number(minAge) < 18 ||
                      (maxAge !== "" && Number(minAge) >= Number(maxAge)))
                  }
                  helperText={
                    minAge !== "" && Number(minAge) < 18
                      ? "Min Age must be at least 18"
                      : minAge !== "" &&
                        maxAge !== "" &&
                        Number(minAge) >= Number(maxAge)
                      ? "Min Age must be less than Max Age"
                      : ""
                  }
                />

                <TextField
                  fullWidth
                  label="Max Age"
                  type="number"
                  value={maxAge}
                  onChange={(e: any) => {
                    const value = e.target.value;
                    // Allow typing but validate after input
                    setMaxAge(value);
                  }}
                  error={
                    maxAge !== "" &&
                    (Number(maxAge) <= Number(minAge) || Number(minAge) < 18)
                  }
                  helperText={
                    minAge !== "" && Number(minAge) < 18
                      ? "Min Age must be at least 18"
                      : maxAge !== "" && Number(maxAge) <= Number(minAge)
                      ? "Max Age must be greater than Min Age"
                      : ""
                  }
                />
              </div>
            </FormGroup>
          </div>

          {/* Gender Field */}
          <div className="form-group" style={{ marginTop: "20px" }}>
            <label>Gender</label>
            <TextField
              fullWidth
              select
              label="Gender"
              name="gender"
              value={gender}
              onChange={(e: any) => setGender(e.target.value)}
            >
              {["Male", "Female", "All"].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </div>

          {/* Add Comment Field */}
          <div className="form-group" style={{ marginTop: "20px" }}>
            <label>Add Comment</label>
            <TextField
              fullWidth
              multiline
              rows={3}
              variant="outlined"
              name="comment"
              value={addComment}
              onChange={handleCommentChange}
              placeholder="Enter your comment here..."
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="small"
              className="table-row-background"
              style={{ width: "200px", padding: "10px" }}
              onClick={handleUpdate}
            >
              Allocation Update
            </Button>
            <Button
              onClick={handleClose}
              variant="contained"
              color="primary"
              size="small"
              className="table-row-background"
              style={{ width: "200px", padding: "10px" }}
            >
              Close
            </Button>
          </div>
        </DialogContent>
      </Dialog>
      {/* Pagination */}
      {allocations.length > itemsPerPage && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "20px",
          }}
        >
          <Pagination
            count={Math.ceil(allocations.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      )}
    </Box>
  );
}
