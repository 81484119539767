import axios, { AxiosInstance, AxiosResponse } from 'axios';
import {
  setLoadingFalse,
  setLoadingTrue,
} from "../global/config/Action/loadingActions.ts";
import store from "../global/config/store.ts"; 
import { BASE_URL } from './apiUrls.tsx';
import { API_VERSION } from './apiUrls.tsx';

const apiClient: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  (config) => {
    store.dispatch(setLoadingTrue());
    const token = localStorage.getItem('authToken');
    if (token) {
      config.headers = config.headers || {};
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    store.dispatch(setLoadingFalse());
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response: AxiosResponse) => {
    store.dispatch(setLoadingFalse());
    return response;
  },
  (error) => {
    store.dispatch(setLoadingFalse());
    if (error.response && error.response.status === 401) {
      console.error('Unauthorized! Please log in again.');
    }
    return Promise.reject(error);
  }
);

export default apiClient;