const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

const api_version = `${API_BASE_URL}api/v1`
const route_mediator = 'manage'

export const CustomUploadProfilePic = formData => {
  const token = localStorage.getItem('token')

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`
    },
    body: formData
  }

  return fetch(
    `${api_version}/${route_mediator}/upload/upload-file`,
    requestOptions
  )
    .then(res => {
      if (!res.ok) {
        return res.json().then(err => {
          throw new Error(err.message || 'Network response was not ok')
        })
      }
      return res.json()
    })
    .catch(error => {
      throw new Error(
        'There was an error uploading the profile picture: ' + error.message
      )
    })
}
