import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import {
  Button,
  Autocomplete,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import {
  ApiGetClientEntitiTemplate,
  ApiGetVenueEntityTemplate,
  ApiGetVenueList,
  ApiGetVisitTimes,
  ApiPostVenueTemplate,
  ApiPutVenueTemplate,
} from "../../../apiService/Core/Apicall.tsx";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import moment from "moment";
import {
  visitDaysOptions,
  genderOptions,
  jobTimeTypeOptions,
} from "../../../global/constants/globalContants.ts";

interface ViewJobCriteriaVenueProps {
  onSubmit: (values: any) => void;
}

interface Venue {
  label: string;
  value: string;
}

const ViewJobCriteriaVenue: React.FC<ViewJobCriteriaVenueProps> = ({
  onSubmit,
}) => {
  const [venues, setVenues] = useState<Venue[]>([]);
  const location = useLocation();
  const navigate = useNavigate();
  const venue = location?.state?.venue;
  const [venueId, setVenueId] = useState(venue?.venueId || "");
  const [templateData, setTemplateData] = useState<any>();
  const [visitTimes, setVisitTimes] = useState<any>([]);
  const [venueTemplateData, setVenueTemplateData] = useState<any>();
  const [jobAddType, setJobAddType] = useState<any>();
  useEffect(() => {
    const fetchData = async () => {
      if (venue?.clientEntityId.clientEntityId) {
        const data = `?clientEntityId=${venue?.clientEntityId.clientEntityId}`;
        try {
          const response = await ApiGetClientEntitiTemplate(data);
          setTemplateData(response.data[0]); // Store data in state
        } catch (error) {
          console.error("Error fetching client entity template:", error);
        }
      }
      await handleGetVisitTimeList();
    };
    const fetchVenueTemplateId = async () => {
      if (venue?.venueId) {
        const data = `?venueId=${venue?.venueId}`;
        try {
          const response = await ApiGetVenueEntityTemplate(data);

          setVenueTemplateData(response.data[0]);
          setJobAddType(response.data[0]);
        } catch (error) {
          console.error("Error fetching client entity template:", error);
        }
      }
      await handleGetVisitTimeList();
    };
    fetchData();
    fetchVenueTemplateId();
  }, [venue?.clientEntityId.clientEntityId]);

  const handleGetVisitTimeList = async () => {
    try {
      const response: any = await ApiGetVisitTimes();
      setVisitTimes(response.data);
      console.log("visit times list", response.data);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const validationSchema = Yup.object({
    venue: Yup.string().required("Please select a venue"),
    jobTimeType: Yup.string().required("Please select a job time type"),
    visitDays: Yup.array()
      .min(1, "Please select at least one visit day")
      .required("Visit days are required"),
    visitTimes: Yup.array()
      .min(1, "Please select at least one visit time")
      .required("Visit times are required"),
    minAge: Yup.number()
      .typeError("Min Age must be a number")
      .positive("Min Age must be a positive number")
      .integer("Min Age must be an integer")
      .min(18, "Min Age must be at least 18") // Minimum value of 18
      .max(100, "Min Age cannot be more than 100") // Limit input to 100
      .required("Min Age is required"),
    maxAge: Yup.number()
      .typeError("Max Age must be a number")
      .positive("Max Age must be a positive number")
      .integer("Max Age must be an integer")
      .required("Max Age is required")
      .min(
        Yup.ref("minAge"),
        "Max Age must be greater than or equal to Min Age"
      ) //  Must be greater than minAge
      .max(100, "Max Age cannot be more than 100"), // imit input to 100
    gender: Yup.string().required("Please select a gender"),
    fromDate: Yup.date().required("From Date is required"),
    toDate: Yup.date()
      .required("To Date is required")
      .min(Yup.ref("fromDate"), "To Date must be after From Date"),
  });

  useEffect(() => {
    const handleGetVenueList = async () => {
      const data = "?offset=0&limit=100";
      try {
        const response: any = await ApiGetVenueList(data);
        const transformedData = response.data.map((item: any) => ({
          value: item.venueId,
          label: item.venueName,
        }));
        setVenues(transformedData);
      } catch (error) {
        console.error("Error during API call:", error);
      }
    };

    handleGetVenueList();
  }, []);

  const handleSubmit = async (values: any) => {
    const param = {
      venueTemplateId: venueTemplateData.venueTemplateId,
      otherVenuesIds: values.otherVenuesIds,
      jobTimeType: values.jobTimeType,
      repetitionCount: values.repetitionCount,
      venueId: venue.venueId,
      visitDaysInformation: values.visitDays ? values.visitDays.join(", ") : "",
      visitTimeInformation: values.visitTimes
        ? values.visitTimes.join(", ")
        : "",
      minAge: values.minAge,
      maxAge: values.maxAge,
      gender: values.gender,
      minDate: moment(values.fromDate, "YYYY-MM-DD").format("DD"),
      maxDate: moment(values.toDate, "YYYY-MM-DD").format("DD"),
      isUpdated: values.isUpdated,
      modifiedBy: 1,
    };
    const data = {
      clientEntityTemplateId: templateData.clientEntityTemplateId,
      jobTimeType: values.jobTimeType,
      repetitionCount: values.repetitionCount,
      venueId: venue.venueId,
      visitDaysInformation: values.visitDays ? values.visitDays.join(", ") : "",
      visitTimeInformation: values.visitTimes
        ? values.visitTimes.join(", ")
        : "",
      minAge: values.minAge,
      maxAge: values.maxAge,
      gender: values.gender,
      minDate: moment(values.fromDate, "YYYY-MM-DD").format("DD"),
      maxDate: moment(values.toDate, "YYYY-MM-DD").format("DD"),
      isUpdated: values.isUpdated,
      createdBy: 1,
    };
    try {
      if (jobAddType) {
        const response: any = await ApiPutVenueTemplate(param);
        toast.success("venue Template updated successfully!");
      } else {
        const response: any = await ApiPostVenueTemplate(data);
        toast.success("venue Template Added successfully!");
      }
      navigate(-1);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const visitTimeArray = venueTemplateData?.visitTimeInformation
    ? templateData?.visitTimeInformation.split(",").map(Number)
    : [];
  useEffect(() => {
    if (venueTemplateData) {
      setVenueTemplateData(venueTemplateData);
    } else {
      setVenueTemplateData(templateData);
    }
  });
  return (
    <Formik
      enableReinitialize
      initialValues={{
        venue: venueId,
        jobTimeType: venueTemplateData?.jobTimeType || "",
        repetitionCount: venueTemplateData?.repetitionCount || "",
        visitDays: venueTemplateData?.visitDaysInformation
          ? venueTemplateData.visitDaysInformation
              .split(",")
              .map((day) => day.trim())
          : [],
        visitTimes: visitTimeArray,
        minAge: venueTemplateData?.minAge || "",
        maxAge: venueTemplateData?.maxAge || "",
        gender: venueTemplateData?.gender || "",
        fromDate: moment(venueTemplateData?.minDate, "DD").format("YYYY-MM-DD"),
        toDate: moment(venueTemplateData?.maxDate, "DD").format("YYYY-MM-DD"),
        isUpdated: venueTemplateData?.isUpdated || false,
        otherVenuesIds: venueTemplateData?.otherVenuesIds || [],
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({ values, setFieldValue, errors, touched }) => (
        <Form style={{ background: "white", padding: "30px" }}>
          <h1 className="common-add">View Job Criteria for Venue</h1>

          <div
            className="form-group"
            style={{ display: "flex", gap: "20px", alignItems: "center" }}
          >
            <div style={{ flex: 1 }}>
              <Autocomplete
                options={venues}
                value={
                  venues.find((option) => option.value === values.venue) || null
                }
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) => {
                  setFieldValue("venue", newValue ? newValue.value : "");
                }}
                disabled
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Venue"
                    fullWidth
                    error={touched.venue && Boolean(errors.venue)}
                    helperText={touched.venue && errors.venue}
                  />
                )}
              />
            </div>

            {/* Job Time Type Selector */}
            <div style={{ flex: 1 }}>
              <Autocomplete
                options={jobTimeTypeOptions}
                value={
                  jobTimeTypeOptions.find(
                    (option) => option.value === values.jobTimeType
                  ) || null
                }
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) => {
                  setFieldValue("jobTimeType", newValue ? newValue.value : "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Job Time Type"
                    fullWidth
                    error={touched.jobTimeType && Boolean(errors.jobTimeType)}
                    helperText={touched.jobTimeType && errors.jobTimeType}
                  />
                )}
              />
            </div>

            {/* Repetition Count - Visible only when Job Time Type is selected */}
            {values.jobTimeType && (
              <div style={{ flex: 1 }}>
                <TextField
                  label="Repetition Count"
                  type="number"
                  fullWidth
                  value={values.repetitionCount || ""}
                  onChange={(event) => {
                    const value = event.target.value;
                    if (/^\d*$/.test(value)) {
                      setFieldValue("repetitionCount", value);
                    }
                  }}
                  error={
                    touched.repetitionCount && Boolean(errors.repetitionCount)
                  }
                  helperText={touched.repetitionCount && errors.repetitionCount}
                />
              </div>
            )}
          </div>

          {/* Visit Days Checkbox Group */}
          <div className="form-group" style={{ marginTop: "20px" }}>
            <label>Visit Days Information</label>
            <FormGroup
              style={{ display: "flex", flexDirection: "row", gap: "10px" }}
            >
              {visitDaysOptions.map((day) => (
                <FormControlLabel
                  key={day.value}
                  control={
                    <Checkbox
                      checked={values.visitDays.includes(day.value)}
                      onChange={(event) => {
                        const newVisitDays = event.target.checked
                          ? [...values.visitDays, day.value]
                          : values.visitDays.filter((val) => val !== day.value);
                        setFieldValue("visitDays", newVisitDays);
                      }}
                    />
                  }
                  label={day.label}
                />
              ))}
            </FormGroup>
            {touched.visitDays && errors.visitDays && (
              <div style={{ color: "red", marginTop: "5px" }}>
                {errors.visitDays}
              </div>
            )}
          </div>

          {/* Visit Time Checkbox Group */}
          <div className="form-group" style={{ marginTop: "20px" }}>
            <label>Visit Time Information</label>
            <FormGroup
              style={{ display: "flex", flexDirection: "row", gap: "10px" }}
            >
              {visitTimes.map((time) => (
                <FormControlLabel
                  key={time.visitTimeId}
                  control={
                    <Checkbox
                      checked={values.visitTimes.includes(time.visitTimeId)}
                      onChange={(event) => {
                        const newVisitTimes = event.target.checked
                          ? [...values.visitTimes, time.visitTimeId]
                          : values.visitTimes.filter(
                              (val) => val !== time.visitTimeId
                            );
                        setFieldValue("visitTimes", newVisitTimes);
                      }}
                    />
                  }
                  label={time.visitTimeInformation}
                />
              ))}
            </FormGroup>
            {touched.visitTimes && errors.visitTimes && (
              <div style={{ color: "red", marginTop: "5px" }}>
                {errors.visitTimes}
              </div>
            )}
          </div>
          <div
            className="form-group"
            style={{ marginTop: "20px", display: "flex", gap: "20px" }}
          >
            {/* Min Age Field */}
            <div style={{ flex: 1 }}>
              <TextField
                fullWidth
                label="Min Age"
                type="number"
                value={values.minAge}
                onChange={(e) => setFieldValue("minAge", e.target.value)}
                error={touched.minAge && Boolean(errors.minAge)}
                helperText={touched.minAge && errors.minAge}
              />
            </div>

            {/* Max Age Field */}
            <div style={{ flex: 1 }}>
              <TextField
                fullWidth
                label="Max Age"
                type="number"
                value={values.maxAge}
                onChange={(e) => setFieldValue("maxAge", e.target.value)}
                error={touched.maxAge && Boolean(errors.maxAge)}
                helperText={touched.maxAge && errors.maxAge}
              />
            </div>

            <div style={{ flex: 1 }}>
              <Autocomplete
                options={genderOptions}
                value={
                  genderOptions.find(
                    (option) => option.value === values.gender
                  ) || null
                }
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) => {
                  setFieldValue("gender", newValue ? newValue.value : "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Gender"
                    fullWidth
                    error={touched.gender && Boolean(errors.gender)}
                    helperText={touched.gender && errors.gender}
                  />
                )}
              />
            </div>
          </div>

          {/* From Date Field */}
          <div
            className="form-group"
            style={{ marginTop: "20px", display: "flex", gap: "20px" }}
          >
            <div style={{ flex: 1 }}>
              <TextField
                fullWidth
                label="From Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={values.fromDate}
                onChange={(e) => setFieldValue("fromDate", e.target.value)}
                error={touched.fromDate && Boolean(errors.fromDate)}
                helperText={touched.fromDate && errors.fromDate}
              />
            </div>

            {/* To Date Field */}
            <div style={{ flex: 1 }}>
              <TextField
                fullWidth
                label="To Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={values.toDate}
                onChange={(e) => setFieldValue("toDate", e.target.value)}
                error={touched.toDate && Boolean(errors.toDate)}
                helperText={touched.toDate && errors.toDate}
              />
            </div>
          </div>

          {/* IsUpdated Checkbox */}
          <div className="form-group" style={{ marginTop: "20px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={values.isUpdated}
                  onChange={(event) =>
                    setFieldValue("isUpdated", event.target.checked)
                  }
                />
              }
              label="Is Updated"
            />
          </div>
          <FormGroup
            style={{ display: "flex", flexDirection: "row", gap: "10px" }}
          >
            {venues.map((time) => (
              <FormControlLabel
                key={time.value}
                control={
                  <Checkbox
                    checked={values.otherVenuesIds.includes(time.value)}
                    onChange={(event) => {
                      const newVisitTimes = event.target.checked
                        ? [...values.otherVenuesIds, time.value]
                        : values.otherVenuesIds.filter(
                            (val) => val !== time.value
                          );
                      setFieldValue("otherVenuesIds", newVisitTimes);
                    }}
                  />
                }
                label={time.label}
              />
            ))}
          </FormGroup>
          {touched.otherVenuesIds && errors.otherVenuesIds && (
            <div style={{ color: "red", marginTop: "5px" }}>
              {errors.otherVenuesIds}
            </div>
          )}
          {/* Submit Button */}
          <div className="text-center" style={{ marginTop: "30px" }}>
            <Button
              style={{ padding: "9px 70px" }}
              type="submit"
              variant="contained"
              color="primary"
              className="table-row-background"
            >
              Submit
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ViewJobCriteriaVenue;
