import Domains from '../global/constants/domain.ts';

export const loadDomainSpecificCSS = () => {
    const domain = window.location.hostname;
  
    if (domain.includes(Domains.ALDI)) {
      import('../assets/aldi/css/style.css').catch((err) => {
        console.error("Error loading CSS for ALDI", err);
      });
    } else if (domain.includes(Domains.BLOOMS)) {
      import('../assets/blooms/css/style.css').catch((err) => {
        console.error("Error loading CSS for BLOOMS", err);
      });
    } else if (domain.includes(Domains.MM)) {
      import('../assets/common/css/style.css').catch((err) => {
        console.error("Error loading CSS for MM", err);
      });
    } else {
      import('../assets/common/css/style.css').catch((err) => {
        console.error("Error loading default CSS", err);
      });
    }
  };
  