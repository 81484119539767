import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, TextField, Select, MenuItem, Button, List, ListItem, InputAdornment, Divider, FormControl, InputLabel, IconButton, Pagination } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import './QuestionBankCss/QuestionBank.css';
import { GetCategories, GetEntities, GetQuestions } from "../../../apiService/Core/Apicall.tsx";
import edit from '../../../assets/images/editIcon.png'

const limit = 5;
const QuestionBank: React.FC = () => {
    const [questionBank, setquestionBank] = useState<any>([])
    const [category, setcategorey] = useState<any>('')
    const [selectedcategory, setSelectedcategorey] = useState<number| string>(0)
    const [entity, setentity] = useState<any>('')
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [selectedentity, setselectedentity] = useState<number | string>(0)
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);


    const navigate = useNavigate();

    // useEffect(() => {
    //     let filtered = questionBank;

    //     if (selectedcategory) {
    //         filtered = filtered.filter(q => q.categoryId.categoryId === selectedcategory);
    //     }
    //     if (selectedentity) {
    //         filtered = filtered.filter(q => q.entityTypeId.entityTypeId === selectedentity);
    //     }
    //     if (searchTerm.trim()) {
    //         filtered = filtered.filter(q => q.questions.toLowerCase().includes(searchTerm.toLowerCase()));
    //     }

    //     setFilteredQuestions(filtered);
    //     setCurrentPage(1);
    // }, [selectedcategory, selectedentity, searchTerm, questionBank]);


    useEffect(() => {
        const fetchcategory = async () => {
            try{
                const categories = await GetCategories()
                setcategorey(categories.data)
            }
            catch(error){
                alert(error)
            }

        }
        const fetchentities = async () => {
            try{
                const entities = await GetEntities()
                setentity(entities.data)
            }
            catch(error){
                alert(error)
            }
        }
        fetchcategory()
        fetchentities()
    }, [])

    useEffect(() => {
        const fetchquestionbank = async () => {
            const offset = (currentPage - 1) * limit;
            try{
                const response = await GetQuestions(offset, limit,selectedentity, selectedcategory,searchTerm)
                setquestionBank(response.data)
                setTotal(response.totalCount);
                console.log(response.data)
            }
            catch(error){
                alert(error)
            }
        }
        fetchquestionbank()
    }, [currentPage, selectedcategory, selectedentity, searchTerm])
    const totalPages = Math.ceil(total / limit);

    const filteredQuestions = questionBank.filter((q) =>
        q.questions.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Box className='container-fluid p-3 extra' bgcolor='white' minHeight='calc(100vh - 180px)'>
            {/* Top Bar */}
            <Box className="d-flex justify-content-between w-100 py-2">
                <Typography variant="h6"> {/* Push title to the left */}
                    Question Bank
                </Typography>
                <Box className=' justify-content-end col-5 d-flex align-items-stretch' gap={2}>
                < FormControl className='col-4'>
                        <Select
                            id="demo-simple-select"
                            value={selectedentity}
                            onChange={(e) => setselectedentity(e.target.value)}
                        >
                            <MenuItem value={0} selected>Select Type</MenuItem>

                            {entity.length > 0 &&
                                entity.map((cat) => (
                                    <MenuItem value={cat.entityTypeId} key={cat.entityTypeId}>
                                        {cat.entityTypeName}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                    <FormControl className="col-4">
                        <Select
                            id="demo-simple-select"
                            value={selectedcategory}
                            onChange={(e) => setSelectedcategorey(e.target.value)}
                        >
                            <MenuItem value={0}>Select Category</MenuItem>
                            {category.length > 0 &&
                                category.map((cat) => (
                                    <MenuItem value={cat.categoryId} key={cat.categoryId}>
                                        {cat.categoryName}
                                    </MenuItem>
                                ))
                            }
                        </Select>

                    </FormControl>
                    
                    <Button onClick={() => { navigate('/admin/create-new-question') }} className="bg-grey transform text-light px-4 py-1">
                        + Add New
                    </Button>
                </Box>
            </Box>
            <Divider className="w-100 my-2 mb-3" />
            <TextField
                className="search-bar  mb-3"
                placeholder="Search your Question here..."
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                fullWidth
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon style={{ color: "black" }} />
                        </InputAdornment>
                    ),
                }}
            />
            {filteredQuestions.length > 0 && filteredQuestions.map((question) => (
                <Box key={question.questionId} className="question-box">
                    <Box className="question-header">
                        <Box className='d-flex col-7 gap-5 align-items-center p-0'>
                            <Typography style={{ color: '#747247' }} > Question ID: #{question.questionId}</Typography>
                            <Typography> &nbsp; | &nbsp; </Typography>
                            <Typography sx={{ fontWeight: 'bold' }}>Category: {question.categoryId.categoryName}</Typography>
                            <Typography> &nbsp; | &nbsp; </Typography>
                            <Typography sx={{ fontWeight: 'bold' }}>Type: {question.qType.length > 0 ? question.qType === "R" ? "Radio" : "Text" : 'notFound'}</Typography>
                            <Typography> &nbsp; | &nbsp; </Typography>
                            <Typography sx={{ fontWeight: 'bold' }}>Max Score: {question.maxScore}</Typography>
                            <Typography> &nbsp; | &nbsp; </Typography>
                            <Typography sx={{ fontWeight: 'bold' }}>Weightage: {question.questionWeightage}</Typography>
                            <Typography> &nbsp; | &nbsp; </Typography>
                            <Typography className="d-flex align-items-center" gap={1} sx={{ fontWeight: 'bold' }}>color: <Box height='20px' width='50px' bgcolor={question.colorHex} className="border"> </Box></Typography>
                        </Box>
                        <Box className=' col-5 d-flex p-0 justify-content-end align-items-center'>
                            <Box className='ml-auto col-6'>
                                {question.isPhotoRequire && (
                                    <Box className="images-tag ml-auto px-4 py-2" width='fit-content'>
                                        <Typography>Images - Min: {question.minPhoto}</Typography>
                                    </Box>
                                )}
                            </Box>
                            <Box className="version-tag col-6 p-0 justify-content-end" >
                                <Box className='d-flex' bgcolor='#D3D3D3' borderRadius='5px'>
                                    <Typography className="p-2 px-3 text-center col-6" whiteSpace={'nowrap'}>{question.entityTypeId.entityTypeName || 'undefined'}</Typography>
                                </Box>
                                <IconButton className="col-2" onClick={() => { navigate(`/admin/create-new-question`, { state: { questionData: question } }) }}>
                                   <Box component='img' src={edit} className="w-50"></Box>
                                </IconButton>
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Typography className="d-flex" sx={{ fontWeight: 'bold' }} variant="body1" mt={1}>
                            Q: &nbsp; <span dangerouslySetInnerHTML={{ __html: question.questions }} />
                        </Typography>
                        {question.qOptionJson !== null && question.qOptionJson.trim() !== "" ? (
                            <List component='ol' className="question-options w-100 d-flex justify-content-between flex-wrap">
                                {(() => {
                                    try {
                                        const parsedOptions = JSON.parse(question.qOptionJson);
                                        return parsedOptions.map((option, index) => (
                                            <ListItem key={index} className="col-6 option-text">
                                                <span>{index + 1}. &nbsp;</span>
                                                <span dangerouslySetInnerHTML={{ __html: option.value }} />
                                            </ListItem>
                                        ));
                                    } catch (error) {
                                        console.error("Invalid JSON in qOptionJson:", error);
                                        return (
                                            <Typography className="option-text text-danger">
                                                Error: Invalid JSON format
                                            </Typography>
                                        );
                                    }
                                })()}
                            </List>
                        ) : (
                            <Typography className="option-text">
                                {question.options ? question.options[0] : "Answer will be a manual Text Entry."}
                            </Typography>
                        )}
                        <Typography className="d-flex">
                         Discription: &nbsp;<span dangerouslySetInnerHTML={{ __html: question.questionDescription}} />
                        </Typography>
                        <Typography className="d-flex">
                         Hint: &nbsp;<span dangerouslySetInnerHTML={{ __html: question.questionHint }} />
                        </Typography>

                    </Box>
                </Box>
            ))}
            <Pagination
                className="d-flex justify-content-center"
                count={totalPages} // Total pages
                page={currentPage} // Current page
                onChange={(event, value) => setCurrentPage(value)} // Page change handler
            />
        </Box>
    );
};

export default QuestionBank;

