import React, { useEffect, useRef, useState, forwardRef } from 'react'
import { Box, Button, Divider, FormControl, IconButton, Input, InputLabel, List, ListItem, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import '../../../assets/ExtraStyle.css'
import { CancelOutlined } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import QuestionList from '../QuestionLists/QuestionList.tsx';
import { AddBusinessQuestions, GetCategories, GetClientEntityQuestions, GetQuestionTemplate, GetTemplateQuestions, RemoveClientQuestions, RemoveTemplateQuestions, UpdateBusinessQuestions, UpdateVenueCategoryOrder, UpdateVenueQuestionOrder } from '../../../apiService/Core/Apicall.tsx';
import edit from '../../../assets/images/editIcon.png'
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';





interface DragItem {
    index: number;
}

const QuestionCard = forwardRef(({ question, index, moveQuestion, editId, colorHex,setcolorHex, removequestion, updatequestion, setEditId, setEditWeighted, editWeighted }: { question: any; editId: string | null; colorHex:string | number | undefined; index: number; moveQuestion: (from: number, to: number) => void; removequestion: (id: number) => void; updatequestion: (id: number) => void; setEditId: (id: string | null) => void; setEditWeighted: (weight: number | string) => void; setcolorHex: (colorHex: number | string) => void; editWeighted: any; }, ref) => {
    const dragRef = useRef<any>(null);
    const dropRef = useRef<any>(null);

    const [, drag] = useDrag({
        type: 'QUESTION',
        item: { index },
    });

    const [, drop] = useDrop({
        accept: 'QUESTION',
        hover: (draggedItem: DragItem) => {
            if (draggedItem.index !== index) {
                moveQuestion(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });

    drag(dragRef);
    drop(dropRef);

    return (
        <Box ref={(node) => {
            dragRef.current = node;
            dropRef.current = node;
        }} key={index} className='my-2' border='1px solid #E0E0E0' borderRadius='5px' padding='10px'>
            <Box className='w-100 d-flex justify-content-end'>
                <Typography variant='caption' className='text-end'>Index: {index}</Typography>
            </Box>
            <Box className='d-flex align-items-center justify-content-between my-3'>
                <Box className='d-flex align-items-center' gap={1}>
                    <Typography className='text-main fw-bold'>Question ID: #{question.questionId.questionId}</Typography>
                    <Typography className='text-main fw-bold'>|</Typography>
                    <Typography className='fw-bold'>Category: {question.questionId.categoryId?.categoryName}</Typography>
                    <Typography className='fw-bold'>|</Typography>
                    <Typography className='fw-bold'>Type: {question.questionId?.qType?.length > 0 ? question.questionId.qType === "R" ? "Radio" : "Text" : 'notFound'}</Typography>
                </Box>
                <Box className='d-flex align-items-center' gap={1}>
                    <Button className='px-4 py-1 bg-danger transform text-light' onClick={() => { removequestion(question.clientEntityQuestionId) }}>-Remove</Button>
                    {editId === question.questionId.questionId && <Button className='px-3 py-1 bg-grey transform text-light' onClick={() => { updatequestion(question.clientEntityQuestionId) }}>Save</Button>}
                </Box>
            </Box>
            <Box>
                <Typography component='div' className='fw-bold my-1 d-flex'>Q:&nbsp;<span dangerouslySetInnerHTML={{ __html: question.questionId.questions }} /></Typography>
                <Box className='d-flex align-items-center' gap={3}>
                    <Box className='d-flex align-items-center'>
                        {/* <Box className='px-4 py-1 text-main' borderRight='1px solid #747247' bgcolor='#7472471A'>
                        <Typography>Ver {question.questionId.verNo}</Typography>
                    </Box> */}
                        <Typography className='px-4 my-2 py-1 text-main bg-light-primary'>{question.questionId.entityTypeId?.entityTypeName || 'undefined'}</Typography>
                    </Box>
                    {question.questionId.isPhotoRequire && (
                        <Box className=" bg-light-primary px-4 py-2" borderRadius='20px' width='fit-content'>
                            <Typography>Images - Min: {question.questionId.minPhoto}</Typography>
                        </Box>
                    )}
                </Box>
                {question.questionId.qOptionJson !== null ? (
                    <List component='ol' className="question-options w-100 d-flex justify-content-between flex-wrap">
                        {(() => {
                            try {
                                const parsedOptions = JSON.parse(question.questionId.qOptionJson);
                                return parsedOptions.map((option, index) => (
                                    <ListItem key={index} className="col-6 option-text">
                                        <span>{index + 1}. &nbsp;</span>
                                        <span dangerouslySetInnerHTML={{ __html: option.value }} />
                                    </ListItem>
                                ));
                            } catch (error) {
                                console.error("Invalid JSON in qOptionJson:", error);
                                return (
                                    <Typography className="option-text text-danger">
                                        Error: Invalid JSON format
                                    </Typography>
                                );
                            }
                        })()}
                    </List>
                ) : (
                    <Typography className="option-text">
                        Answer will be a manual Text Entry
                    </Typography>
                )}
                <Box className='d-flex mb-2 align-items-stretch'>
                    <Box className='px-4 py-1 text-main align-items-center d-flex' width='fit-content' borderRight='1px solid #747247' bgcolor='#7472471A'>
                        <Typography>Score : &nbsp;{question.questionId.maxScore}</Typography>
                    </Box>
                    <Box className='px-4 d-flex align-items-center py-1 text-main' gap={1} width='fit-content'borderRight='1px solid #747247' bgcolor='#7472471A'>
                        <Typography component='div' width='fit-content' className='d-flex align-items-center'>Weightage : &nbsp;
                            <TextField
                                type='number'
                                onBlur={(e) => {
                                    const value = parseInt(e.target.value, 10);
                                    if (isNaN(value) || value < 0) {
                                        alert('Number Should be positive')
                                        setEditWeighted(question.questionWeightage)
                                    }
                                }}
                                inputProps={{ min: 0 }}
                                disabled={editId !== question.questionId.questionId}
                                sx={{
                                    width: '50px',
                                    "& .MuiOutlinedInput-input": {
                                        color: "#747247",
                                        border: 'none !important',
                                        padding: '0px !important'
                                    },
                                    textAlign: 'center', border: 'none'
                                }}
                                onChange={(e) => { setEditWeighted(e.target.value) }}
                                value={editId === question.questionId.questionId ? editWeighted ? editWeighted : question.questionWeightage : question.questionWeightage} />
                        </Typography>
                        <IconButton onClick={() => setEditId(question.questionId.questionId)}>
                            <Box component='img' src={edit} width='20px' height='20px'></Box>
                        </IconButton>
                    </Box>
                    <Box className='px-4 d-flex align-items-center py-1 text-main' gap={1} width='fit-content' bgcolor='#7472471A'>
                        <Typography component='div' width='fit-content' className='d-flex align-items-center'>color : &nbsp;
                            <TextField
                                type='color'
                                inputProps={{ min: 0 }}
                                disabled={editId !== question.questionId.questionId}
                                sx={{
                                    width: '50px',
                                    "& .MuiOutlinedInput-input": {
                                        color: "#747247",
                                        border: 'none !important',
                                        padding: '0px !important'
                                    },
                                    textAlign: 'center', border: 'none'
                                }}
                                onChange={(e) => { setcolorHex(e.target.value) }}
                                value={editId === question.questionId.questionId ? colorHex ? colorHex : question.colorHex: question.colorHex} />
                        </Typography>
                        <IconButton onClick={() => setEditId(question.questionId.questionId)}>
                            <Box component='img' src={edit} width='20px' height='20px'></Box>
                        </IconButton>
                    </Box>
                </Box>
                <Typography className="d-flex">
                    Discription: &nbsp;<span dangerouslySetInnerHTML={{ __html: question.questionId.questionDescription }} />
                </Typography>
                <Typography className="d-flex">
                    Hint: &nbsp;<span dangerouslySetInnerHTML={{ __html: question.questionId.questionHint }} />
                </Typography>

            </Box>
        </Box>
    );
});

const CategoryCard = forwardRef(({ filter, index, catId, moveCategories, categoryName, addedFilters, setAddedFilters }: { filter: any; index: number; categoryName: any; catId: number; addedFilters: number[]; setAddedFilters: (id: number[]) => void; moveCategories: (from: number, to: number) => void; }, ref) => {
    const dragRef = useRef<any>(null);
    const dropRef = useRef<any>(null);

    const [, drag] = useDrag({
        type: 'CATEGORY',
        item: { index },
    });

    const [, drop] = useDrop({
        accept: 'CATEGORY',
        hover: (draggedItem: DragItem) => {
            if (draggedItem.index !== index) {
                moveCategories(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });

    drag(dragRef);
    drop(dropRef);

    return (
        <ToggleButton
            ref={(node) => {
                dragRef.current = node;
                dropRef.current = node;
            }}
            className="transform d-flex align-items-center"
            sx={{ gap: 2, fontSize: '16px', whiteSpace: 'nowrap' }}
            key={index}
            value={filter}
        >
            {categoryName}

            <span
                className='d-flex align-items-center'
                onClick={() => setAddedFilters(addedFilters.filter((e) => e !== filter))}
            >
                <CancelOutlined sx={{ fontSize: '18px' }} />
            </span>
        </ToggleButton>
    );
});




function CreateClientTemplate() {

    const [category, setcategorey] = useState<any>([])
    const [activequestions, setactivequestions] = useState<any>([])
    const [selectedCategory, setselectedCategory] = useState<number | string>('')
    const [TemplateQuestions, setTemplateQuestions] = useState<any>()
    const [addedFilters, setAddedFilters] = useState<number[]>([]);
    const [alignment, setAlignment] = useState<number>(addedFilters && addedFilters[0]);
    const [SelectedTemplate, setSelectedTemplate] = useState<number | string>(0);
    const [reload, setreload] = useState<boolean>(false);
    const [editId, setEditId] = useState<string | null>(null);
    const [editWeighted, setEditWeighted] = useState<string | number>();
    const [editColorHex, setEditcolorHex] = useState<string | number>();
    const [template, setTemplate] = useState<any>([])
    const [refresh, setRefresh] = useState<number>(0);
    const [ischange, setischange] = useState<boolean>(false);
    const [iscategorychange, setiscategorychange] = useState<boolean>(false);


    const moveQuestion = (fromIndex, toIndex) => {
        const updatedQuestions = [...activequestions];
        const [movedQuestion] = updatedQuestions.splice(fromIndex, 1);
        updatedQuestions.splice(toIndex, 0, movedQuestion);
        setactivequestions(updatedQuestions);
        setischange(!ischange)
    };
    const moveCategories = (fromIndex, toIndex) => {
        const updatedCategories = [...addedFilters];
        const [movedCategory] = updatedCategories.splice(fromIndex, 1);
        updatedCategories.splice(toIndex, 0, movedCategory);
        setAddedFilters(updatedCategories);
        setiscategorychange(!iscategorychange)
    };

    const handleQuestionListUpdate = () => {
        setRefresh((prev) => prev + 1);
    };
 useEffect(()=>{
 console.log("ALignment",alignment);
 
 },[alignment])
    const location = useLocation();
    const clientData = location.state?.store;
    // console.log(clientData)
    useEffect(() => {
        if (category.length > 0) {
            setselectedCategory(category[category.length - 1].categoryId);
        }
    }, [selectedCategory, category]);
    useEffect(() => {
        const fetchcategory = async () => {
            const categories = await GetCategories()
            setcategorey(categories.data)
        }
        const GetTemplate = async () => {
            try {
                const response = await GetQuestionTemplate()
                setTemplate(response.data)

            }
            catch (error) {
                alert(error)
            }
        }
        GetTemplate()
        fetchcategory()
    }, [])

    useEffect(() => {
        const fetchtselectedquestions = async () => {
            try {
                const response = await GetClientEntityQuestions(clientData?.clientEntityId)
                console.log("CLient Questions", response);
                let filteredquestions: any[] = [];
                let filteredcategories: any[] = [];
                setreload(false)
                const categoryIds = response.categoryDisplayOrderIds
                    ? response.categoryDisplayOrderIds.split(',').filter((id) => id.trim() !== '').map((id) => parseInt(id.trim(), 10))
                    : [];

                console.log('Category IDs:', categoryIds);
                categoryIds.map((orderId) => {
                    response.data.map((question) => {
                        console.log(question.questionId.categoryId.categoryId, ":", orderId);

                        if (question.questionId.categoryId.categoryId === orderId) {
                            console.log(question.questionId.categoryId.categoryId);
                            filteredquestions = [...filteredquestions, question];
                        }
                    })
                })
                console.log("Filtered Questions", filteredquestions);

                filteredquestions.length > 0 ? setTemplateQuestions(filteredquestions) : setTemplateQuestions(response.data)
                


                filteredquestions.map(item => {
                    filteredcategories = [...new Set([...filteredcategories, item.questionId.categoryId.categoryId])];
                })

                if (filteredcategories.length > 0 && JSON.stringify(filteredcategories) !== JSON.stringify(addedFilters)) {
                    setAddedFilters(filteredcategories);
                    setAlignment(filteredcategories[0])
                }
                else {
                    response.data.map(item => {
                        console.log("(item.questionId.categoryId.categoryId",item.questionId.categoryId.categoryId);               
                        setAddedFilters(prevFilters =>
                            prevFilters.includes(item.questionId.categoryId.categoryId)
                                ? prevFilters
                                : [...prevFilters, item.questionId.categoryId.categoryId]
                        );
                        setAlignment(response.data[0].questionId.categoryId.categoryId)


                    })
                }
                console.log("Added Filters", addedFilters)
            }
            catch (error) {
                alert(error)
            }


        }
        fetchtselectedquestions()
    }, [reload, refresh, iscategorychange])

    const handleChange2 = (event) => {
        setAddedFilters([...new Set([...addedFilters, event])])
    };
    console.log(addedFilters);

    const handleAlignment = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: number,
    ) => {
        setAlignment(newAlignment);
    };

    const removequestion = async (clientEntityQuestionId) => {
        try {
            const response = await RemoveClientQuestions(clientEntityQuestionId, clientData.clientEntityId)
            alert(response.message)
            setreload(true)
        }
        catch (error) {
            alert(error)
        }

    }
    const updatequestion = async (clientEntityQuestionId) => {
        const data = {
            clientEntityQuestionId: clientEntityQuestionId,
            ...(editWeighted && { questionWeightage: Number(editWeighted) }),
            ...(editColorHex && { colorHex: editColorHex })
            
        }
        try {
            const response = await UpdateBusinessQuestions(data)
            // console.log(editColorHex);
            alert(response.message)
            setreload(true)
            setEditId(null)
        }
        catch (error) {
            alert(error)
        }

    }

    const AddQuestions = async () => {
        const isAdd = window.confirm("Are you sure? It will delete the current question");
    
        if (!isAdd) return; // If user cancels, exit the function
    
        try {
            await removequestion(true); // First function runs
    
            const data = {
                clientEntityId: clientData.clientEntityId,
                entityTypeTemplateId: SelectedTemplate,
                createdBy: 1
            };
    
            const response = await AddBusinessQuestions(data); // Second function runs after first is completed
    
            alert(response.message);
            setreload(true);
        } catch (error) {
            alert(error);
        }
    };


    useEffect(() => {
        if (TemplateQuestions) {
            const activeCategory = TemplateQuestions.filter(q => q.questionId.categoryId.categoryId === alignment)
                .sort((a, b) => a.displayOrder - b.displayOrder);
            setactivequestions(activeCategory);
        }
    }, [alignment, TemplateQuestions]);

    useEffect(() => {
        if (TemplateQuestions) {
            if (activequestions) {
                const updatequestionOrder = async () => {
                    const updatedMap = {
                        type: "client_entity_questions",
                        questionOrders: activequestions?.map((question, index) => ({
                            clientEntityQuestionId: question.clientEntityQuestionId,
                            displayOrder: index
                        })) || []
                    };
                    try {
                        const response = await UpdateVenueQuestionOrder(updatedMap)
                        // alert(response.message)
                    }
                    catch (error) {
                        alert(error)
                    }
                }
                updatequestionOrder()
            }
        }
    }, [ischange]);

    useEffect(() => {
        if (TemplateQuestions && addedFilters) {
            const updatequestionOrder = async () => {
                const updatedCategory = {
                    type: "client_entity_questions",
                    clientEntityId: clientData.clientEntityId,
                    categoryDisplayOrderIds: addedFilters?.length > 0 && addedFilters.map((filter, index) => {
                        const matchedCategory = category.find((cat) => cat.categoryId === filter);
                        const categoryId = matchedCategory && matchedCategory.categoryId;
                        return categoryId
                    })
                        .join(",")
                };
                try {
                    const response = await UpdateVenueCategoryOrder(updatedCategory)
                    // alert(response.message)
                }
                catch (error) {
                    alert(error)
                }
            }
            updatequestionOrder()

        }
    }, [iscategorychange]);
    return (
        <Box className='container-fluid bg-light create-new-template extra' boxShadow='0px 0px 3px #000000' minHeight='calc(100vh - 165px)' padding='20px'>
            <Box className='d-flex align-items-center justify-content-between'>
                <Typography className='fs-18'>{clientData.entityName}</Typography>
                <Button className='bg-grey col-1 text-light whitespace px-3 py-2' onClick={() => { removequestion(true) }}>Clear All</Button>

            </Box>
            <Divider className='mt-3 mb-4' />
            {/* <Typography className='m-0 p-0' variant='subtitle1'>{templateData?.entityTypeTemplateId || 0}&nbsp;{templateData?.templateName || "Undefine"}</Typography> */}
            <Box className='d-flex mt-5 align-items-stretch justify-content-between' gap={2}>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label" shrink={false}>Select Business Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={SelectedTemplate}
                        onChange={(e) => { setSelectedTemplate(e.target.value) }}
                    >
                        <MenuItem value={0} selected>Select Template</MenuItem>
                        {template.length > 0 &&
                            template.map((temp, index) => (
                                <MenuItem value={temp.entityTypeTemplateId} key={index}>
                                    {temp.templateName}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <Button className='bg-grey col-1 text-light whitespace px-3 py-1' onClick={AddQuestions}>Add Questions</Button>
            </Box>
            <Divider className='my-3' />
            <Box className='d-flex' gap={2}>
                <Box className='w-50 p-2' border='1px solid #E0E0E0'>
                    <QuestionList venueId={null} entityTypeTemplateId={SelectedTemplate}
                        onUpdate={handleQuestionListUpdate}
                        clientEntityId={clientData.clientEntityId} />
                </Box>
                <Box className='w-50 p-2' border='1px solid #E0E0E0'>
                    <Typography className='fs-18 fw-bold mb-2 mt-1'>Template Finalised Questions</Typography>
                    <Box className='d-flex align-items-stretch'>
                        <FormControl className='col-2'>
                            <Select value={selectedCategory ?? ''} onChange={(e) => { setselectedCategory(Number(e.target.value)); handleChange2(e.target.value) }}>

                                {category.length > 0 &&
                                    category.map((cat, index) => (
                                        <MenuItem value={cat.categoryId} key={index}>
                                            {cat.categoryName}
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <ToggleButtonGroup
                            sx={{ overflow: 'auto' }}
                            color="primary"
                            value={alignment || 0}
                            exclusive
                            onChange={handleAlignment}
                            aria-label="Platform"
                        >
                            {/* {addedFilters.map((filter, index) => {
                                const matchedCategory = category.find((cat) => cat.categoryId === filter);
                                const categoryName = matchedCategory ? matchedCategory.categoryName : "Unknown";
                                return (
                                    <ToggleButton
                                        className="transform d-flex align-items-center"
                                        sx={{ gap: 2, fontSize: '16px', whiteSpace: 'nowrap' }}
                                        key={index}
                                        value={filter}
                                    >
                                        {categoryName}
                                        <span
                                            className='d-flex align-items-center'
                                            onClick={() => setAddedFilters(addedFilters.filter((e) => e !== filter))}
                                        >
                                            <CancelOutlined sx={{ fontSize: '18px' }} />
                                        </span>
                                    </ToggleButton>
                                )
                            })} */}
                            <DndProvider backend={HTML5Backend}>
                                {addedFilters?.length > 0 && addedFilters.map((filter, index) => {
                                    const matchedCategory = category.find((cat) => cat.categoryId === filter);
                                    const categoryName = matchedCategory ? matchedCategory.categoryName : "Unknown";
                                    const catId = matchedCategory && matchedCategory.categoryId;
                                    return (

                                        <CategoryCard key={index} catId={catId} categoryName={categoryName} addedFilters={addedFilters} setAddedFilters={setAddedFilters} filter={filter} index={index} moveCategories={moveCategories} />
                                    )
                                })}
                            </DndProvider>
                        </ToggleButtonGroup>

                    </Box>

                    <DndProvider backend={HTML5Backend}>
                        {/* {TemplateQuestions?.length > 0 && TemplateQuestions.map((question, index) => (
                            alignment === question.questionId.categoryId?.categoryId && (

                                <QuestionCard key={index} setEditId={setEditId} removequestion={removequestion} updatequestion={updatequestion} editWeighted={editWeighted} setEditWeighted={setEditWeighted} editId={editId} question={question} index={index} moveQuestion={moveQuestion} />
                            )))} */}
                        {
                            activequestions?.length > 0 && activequestions.map((question, index) => (
                                <QuestionCard colorHex={editColorHex} setcolorHex={setEditcolorHex} key={index} setEditId={setEditId} removequestion={removequestion} updatequestion={updatequestion} editWeighted={editWeighted} setEditWeighted={setEditWeighted} editId={editId} question={question} index={index} moveQuestion={moveQuestion} />
                            ))}
                    </DndProvider>
                </Box>
            </Box>

        </Box>
    )
}

export default CreateClientTemplate
