import React, { useState } from 'react'
import ReactQuill from 'react-quill-new'
import 'react-quill-new/dist/quill.snow.css'

interface TextEditorProps {
  editorValue: string
  setEditorValue: React.Dispatch<React.SetStateAction<string>>
}

const PatchedReactQuill: React.FC<TextEditorProps> = ({
  editorValue,
  setEditorValue
}) => {
  const handleEditorChange = (value: string) => {
    setEditorValue(value)
  }
  return (
    <div style={{ width: '100%', paddingRight: '10px', marginBottom: '40px' }}>
      <ReactQuill
        value={editorValue}
        onChange={handleEditorChange}
        style={{ height: "200px"}}
        modules={{
          toolbar: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['bold', 'italic', 'underline'],
            ['link'],
            // ['image'],
            [{ align: [] }],
            ['clean'],
            [{ color: [] }, { background: [] }]
          ]
        }}
      />
    </div>
  )
}

export default PatchedReactQuill
