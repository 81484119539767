import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { ApiGetVisitTimes } from "../../../apiService/Core/Apicall.tsx";

const ManageVisitTime: React.FC = () => {
  // Static data for visit times
  const [visitTimes, setVisitTimes] = useState<any>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      await handleGetVisitTimeList();
    };
    fetchData();
    return () => {};
  }, []);

  const handleGetVisitTimeList = async () => {
    try {
      const response: any = await ApiGetVisitTimes();
      setVisitTimes(response.data);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  // Function to navigate to the "Add/Edit Visit Time" page
  const addOrUpdateVisitTime = (visitTime?: any) => {
    if (visitTime) {
      navigate(
        `/admin/allocations/add-or-update-visittime/update/${visitTime.visitTimeId}`,
        {
          state: { visitTime: visitTime },
        }
      );
    } else {
      navigate("/admin/allocations/add-or-update-visittime/add");
    }
  };

  // Function to handle deletion of a time slot
  const deleteVisitTime = (id: number) => {
    setVisitTimes((prevTimes) => prevTimes.filter((time) => time.id !== id));
  };

  return (
    <div className="manage-admin-container">
      <h1 className="common-manage-h1">Manage Visit Times</h1>
      <div className="add-button common-manage-button">
        <Button
          variant="contained"
          onClick={() => addOrUpdateVisitTime()}
          className="table-row-background"
        >
          Add New Visit Time
        </Button>
      </div>
      <Table>
        <TableHead>
          <TableRow className="table-row-background">
            <TableCell style={{ color: "#fff" }}>Time Slot</TableCell>
            <TableCell style={{ color: "#fff" }}>Edit</TableCell>
            <TableCell style={{ color: "#fff" }}>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {visitTimes.map((time) => (
            <TableRow key={time.visitTimeId}>
              <TableCell>{time.visitTimeInformation}</TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  onClick={() => addOrUpdateVisitTime(time)}
                  className="table-row-background"
                >
                  Edit
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  className="delete-transparent"
                  onClick={() => deleteVisitTime(time.visitTimeId)}
                >
                  <DeleteIcon className="delete-icon" />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ManageVisitTime;
