import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import {
  Button,
  Autocomplete,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import * as Yup from "yup";
import {
  ApiGetClientEntities,
  ApiGetClientEntitiTemplate,
  ApiGetVisitTimes,
  ApiPostClientEntityTemplate,
  ApiPostVisitTimes,
  ApiPutClientEntityTemplate,
} from "../../../apiService/Core/Apicall.tsx";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import {
  visitDaysOptions,
  genderOptions,
  jobTimeTypeOptions,
} from "../../../global/constants/globalContants.ts";

interface ViewJobCriteriaBusinessProps {
  onSubmit: (values: any) => void;
}

interface Business {
  label: string;
  value: string;
}

const ViewJobCriteriaBusiness: React.FC<ViewJobCriteriaBusinessProps> = ({
  onSubmit,
}) => {
  const [businesses, setBusinesses] = useState<Business[]>([]);
  const [templateData, setTemplateData] = useState<any>();
  const location = useLocation();
  const navigate = useNavigate();
  const store = location?.state?.store;
  const [clientEntityId, setClientEntityId] = useState(
    store?.clientEntityId || ""
  );
  const [visitTimes, setVisitTimes] = useState<any>([]);
  const [selectedValue, setSelectedValue] = useState(null);

  console.log("visitTimes", visitTimes);
  useEffect(() => {
    const fetchData = async () => {
      if (store?.clientEntityId) {
        const data = `?clientEntityId=${store.clientEntityId}`;
        try {
          const response = await ApiGetClientEntitiTemplate(data);
          console.log("Fetched Client Template Data:", response.data);
          setTemplateData(response.data[0]); // Store data in state
        } catch (error) {
          console.error("Error fetching client entity template:", error);
        }
      }
      await handleGetVisitTimeList();
    };
    fetchData();
  }, [store?.clientEntityId]);

  const handleGetVisitTimeList = async () => {
    try {
      const response: any = await ApiGetVisitTimes();
      setVisitTimes(response.data);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  useEffect(() => {
    const handleGetClientList = async () => {
      const data = "";
      try {
        const response: any = await ApiGetClientEntities(data);
        const transformedData = response.data.map((item: any) => ({
          value: item.clientEntityId,
          label: item.entityName,
        }));
        setBusinesses(transformedData);
      } catch (error) {
        console.error("Error during API call:", error);
      }
    };

    handleGetClientList();
  }, []);

  const validationSchema = Yup.object({
    business: Yup.string().required("Please select a business"),
    jobTimeType: Yup.string().required("Please select a job time type"),
    visitDays: Yup.array()
      .min(1, "Please select at least one visit day")
      .required("Visit days are required"),
    visitTimes: Yup.array()
      .min(1, "Please select at least one visit time")
      .required("Visit times are required"),
    minAge: Yup.number()
      .typeError("Min Age must be a number")
      .positive("Min Age must be a positive number")
      .integer("Min Age must be an integer")
      .min(18, "Min Age must be at least 18") // Minimum value of 18
      .max(100, "Min Age cannot be more than 100") // Limit input to 100
      .required("Min Age is required"),
    maxAge: Yup.number()
      .typeError("Max Age must be a number")
      .positive("Max Age must be a positive number")
      .integer("Max Age must be an integer")
      .required("Max Age is required")
      .min(
        Yup.ref("minAge"),
        "Max Age must be greater than or equal to Min Age"
      ) //  Must be greater than minAge
      .max(100, "Max Age cannot be more than 100"), // imit input to 100
    gender: Yup.string().required("Please select a gender"),
    fromDate: Yup.date().required("From Date is required"),
    toDate: Yup.date()
      .required("To Date is required")
      .min(Yup.ref("fromDate"), "To Date must be after From Date"),
  });

  const handleSubmit = async (values: any) => {
    if (templateData) {
      const data = {
        clientEntityTemplateId: templateData.clientEntityTemplateId,
        clientEntityId: clientEntityId,
        jobTimeType: values.jobTimeType,
        repetitionCount: values.repetitionCount,
        visitDaysInformation: values.visitDays
          ? values.visitDays.join(", ")
          : "",
        visitTimeInformation: values.visitTimes
          ? values.visitTimes.join(", ")
          : "",
        minAge: values.minAge,
        maxAge: values.maxAge,
        gender: values.gender,
        minDate: moment(values.fromDate, "YYYY-MM-DD").format("DD"),
        maxDate: moment(values.toDate, "YYYY-MM-DD").format("DD"),
        modifiedBy: 1,
      };
      try {
        console.log("clientitytemplate payload", data);
        const response: any = await ApiPutClientEntityTemplate(data);
        toast.success("Visit updated successfully!");
        navigate(-1);
      } catch (error) {
        console.error("Error during API call:", error);
      }
    } else {
      const data = {
        clientEntityId: clientEntityId,
        jobTimeType: values.jobTimeType,
        repetitionCount: values.repetitionCount,
        visitDaysInformation: values.visitDays
          ? values.visitDays.join(", ")
          : "",
        visitTimeInformation: values.visitTimes
          ? values.visitTimes.join(", ")
          : "",
        minAge: values.minAge,
        maxAge: values.maxAge,
        gender: values.gender,
        minDate: moment(values.fromDate, "YYYY-MM-DD").format("DD"),
        maxDate: moment(values.toDate, "YYYY-MM-DD").format("DD"),
        createdBy: 1,
      };

      try {
        console.log("clientitytemplate payload", data);
        const response: any = await ApiPostClientEntityTemplate(data);
        toast.success("Visit Added successfully!");
        navigate(-1);
      } catch (error) {
        console.error("Error during API call:", error);
      }
    }
  };
  const visitTimeArray = templateData?.visitTimeInformation
    ? templateData.visitTimeInformation.split(",").map(Number)
    : [];

  return (
    <Formik
      enableReinitialize
      initialValues={{
        business: clientEntityId,
        repetitionCount: templateData?.repetitionCount || "",
        jobTimeType: templateData?.jobTimeType || "",
        visitDays: templateData?.visitDaysInformation
          ? templateData.visitDaysInformation
              .split(",")
              .map((day) => day.trim()) // Trim extra spaces
          : [],
        visitTimes: visitTimeArray || [],
        minAge: templateData?.minAge || "",
        maxAge: templateData?.maxAge || "",
        gender: templateData?.gender || "",
        fromDate:
          moment(templateData?.minDate, "DD").format("YYYY-MM-DD") || "",
        toDate: moment(templateData?.maxDate, "DD").format("YYYY-MM-DD") || "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({ values, setFieldValue, errors, touched }) => (
        <Form style={{ background: "white", padding: "30px" }}>
          <h1 className="common-add">View Job Criteria for Business</h1>

          <div
            className="form-group"
            style={{ display: "flex", gap: "20px", alignItems: "center" }}
          >
            {/* Business Selector */}
            <div style={{ flex: 1 }}>
              <Autocomplete
                options={businesses}
                value={
                  businesses.find(
                    (option) => option.value === values.business
                  ) || null
                }
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) => {
                  setFieldValue("business", newValue ? newValue.value : "");
                }}
                disabled
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Business"
                    fullWidth
                    error={touched.business && Boolean(errors.business)}
                    helperText={touched.business && errors.business}
                  />
                )}
              />
            </div>

            {/* Job Time Type Selector */}
            <div style={{ flex: 1 }}>
              <Autocomplete
                options={jobTimeTypeOptions}
                value={
                  jobTimeTypeOptions.find(
                    (option) => option.value === values.jobTimeType
                  ) || null
                }
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) => {
                  setFieldValue("jobTimeType", newValue ? newValue.value : "");
                  setFieldValue("repetitionCount", "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Job Time Type"
                    fullWidth
                    error={touched.jobTimeType && Boolean(errors.jobTimeType)}
                    helperText={touched.jobTimeType && errors.jobTimeType}
                  />
                )}
              />
            </div>

            {values.jobTimeType && (
              <div style={{ flex: 1 }}>
                <TextField
                  label="Repetition Count"
                  type="number"
                  fullWidth
                  value={values.repetitionCount || ""}
                  onChange={(event) => {
                    const value = event.target.value;
                    if (/^\d*$/.test(value)) {
                      setFieldValue("repetitionCount", value);
                    }
                  }}
                  error={
                    touched.repetitionCount && Boolean(errors.repetitionCount)
                  }
                  helperText={touched.repetitionCount && errors.repetitionCount}
                />
              </div>
            )}
          </div>

          {/* Visit Days Checkbox Group */}
          <div className="form-group" style={{ marginTop: "20px" }}>
            <label>Visit Days Information</label>
            <FormGroup
              style={{ display: "flex", flexDirection: "row", gap: "10px" }}
            >
              {visitDaysOptions.map((day) => (
                <FormControlLabel
                  key={day.value}
                  control={
                    <Checkbox
                      checked={values.visitDays.includes(day.value)}
                      onChange={(event) => {
                        const newVisitDays = event.target.checked
                          ? [...values.visitDays, day.value]
                          : values.visitDays.filter((val) => val !== day.value);
                        setFieldValue("visitDays", newVisitDays);
                      }}
                    />
                  }
                  label={day.label}
                />
              ))}
            </FormGroup>
            {touched.visitDays && errors.visitDays && (
              <div style={{ color: "red", marginTop: "5px" }}>
                {errors.visitDays}
              </div>
            )}
          </div>

          {/* Visit Time Checkbox Group */}
          <div className="form-group" style={{ marginTop: "20px" }}>
            <label>Visit Time Information</label>
            <FormGroup
              style={{ display: "flex", flexDirection: "row", gap: "10px" }}
            >
              {visitTimes.map((time) => (
                <FormControlLabel
                  key={time.visitTimeId}
                  control={
                    <Checkbox
                      checked={values.visitTimes.includes(time.visitTimeId)}
                      onChange={(event) => {
                        const newVisitTimes = event.target.checked
                          ? [...values.visitTimes, time.visitTimeId]
                          : values.visitTimes.filter(
                              (val) => val !== time.visitTimeId
                            );
                        setFieldValue("visitTimes", newVisitTimes);
                        console.log("15555", values.visitTimes);
                      }}
                    />
                  }
                  label={time.visitTimeInformation}
                />
              ))}
            </FormGroup>
            {touched.visitTimes && errors.visitTimes && (
              <div style={{ color: "red", marginTop: "5px" }}>
                {errors.visitTimes}
              </div>
            )}
          </div>

          {/* Min Age and Max Age Fields Side by Side */}
          <div
            className="form-group"
            style={{ marginTop: "20px", display: "flex", gap: "20px" }}
          >
            {/* Min Age Field */}
            <div style={{ flex: 1 }}>
              <TextField
                fullWidth
                label="Min Age"
                type="number"
                value={values.minAge}
                onChange={(e) => setFieldValue("minAge", e.target.value)}
                error={touched.minAge && Boolean(errors.minAge)}
                helperText={touched.minAge && errors.minAge}
              />
            </div>

            {/* Max Age Field */}
            <div style={{ flex: 1 }}>
              <TextField
                fullWidth
                label="Max Age"
                type="number"
                value={values.maxAge}
                onChange={(e) => setFieldValue("maxAge", e.target.value)}
                error={touched.maxAge && Boolean(errors.maxAge)}
                helperText={touched.maxAge && errors.maxAge}
              />
            </div>

            <div style={{ flex: 1 }}>
              <Autocomplete
                options={genderOptions}
                value={
                  genderOptions.find(
                    (option) => option.value === values.gender
                  ) || null
                }
                getOptionLabel={(option) => option.label}
                onChange={(event, newValue) => {
                  setFieldValue("gender", newValue ? newValue.value : "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Gender"
                    fullWidth
                    error={touched.gender && Boolean(errors.gender)}
                    helperText={touched.gender && errors.gender}
                  />
                )}
              />
            </div>
          </div>

          {/* From Date Field */}
          <div
            className="form-group"
            style={{ marginTop: "20px", display: "flex", gap: "20px" }}
          >
            <div style={{ flex: 1 }}>
              <TextField
                fullWidth
                label="From Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={values.fromDate}
                onChange={(e) => setFieldValue("fromDate", e.target.value)}
                error={touched.fromDate && Boolean(errors.fromDate)}
                helperText={touched.fromDate && errors.fromDate}
              />
            </div>

            {/* To Date Field */}
            <div style={{ flex: 1 }}>
              <TextField
                fullWidth
                label="To Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={values.toDate}
                onChange={(e) => setFieldValue("toDate", e.target.value)}
                error={touched.toDate && Boolean(errors.toDate)}
                helperText={touched.toDate && errors.toDate}
              />
            </div>
          </div>

          {/* Block shopper */}
          <div
            className="form-group"
            style={{ marginTop: "20px", display: "flex", gap: "20px" }}
          >
            <Autocomplete
              options={[...Array(12).keys()].map((i) => (i + 1).toString())} // Generates ["1", "2", ..., "12"]
              value={selectedValue}
              onChange={(event, newValue) => setSelectedValue(newValue)}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} label="Select Number" />
              )}
            />
          </div>

          {/* Submit Button */}
          <div className="text-center" style={{ marginTop: "30px" }}>
            <Button
              style={{ padding: "9px 70px" }}
              type="submit"
              variant="contained"
              color="primary"
              className="table-row-background"
            >
              Submit
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ViewJobCriteriaBusiness;
