import React from "react";
import { Box, Typography } from "@mui/material";

const Footer: React.FC = () => {
  
  return (
    <div className="ShowDiv">
      <Box
        component="footer"
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "#f5f5f5",
          textAlign: "center",
          padding: "10px 0",
          boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)",
          zIndex:"999"
        }}
      >
        <div>
         
        </div>
        <Typography color="textSecondary">
          &copy; {new Date().getFullYear()} Mystery Management Pty. Ltd. All Rights Reserved.
        </Typography>
      </Box>
    </div>
  );
};

export default Footer;
