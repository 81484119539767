import {
  usersLogin,
  getAllRoles,
  getAdminList,
  getClientEntities,
  getUserDetails,
  postCreateAdmin,
  putUpdateClient,
  postCreateClient,
  getClients,
  putDeleteClient,
  putStoreStatus,
  putUpdateClientEntity,
  postCreateClientEntity,
  getEntityTypeList,
  getVenueList,
  putVenueStatus,
  postCreateVenue,
  putUpdateVenue,
  putUpdateAdminDetails,
  putUpdateAdminRole,
  putUpdateAdminStatus,
  getShopType,
  getStarRating,
  getBriefJson,
  putUpdateEditors,
  putVenueEditors,
  getVisitTimes,
  postVisitTimes,
  putVisitTimes,
  postClientEntityTemplate,
  getClientEntityTemplateByClientEntityId,
  postVenueTemplate,
  getVenueEntityTemplateByClientEntityId,
  putClientEntityTemplate,
  getAllocations,
  putUpdateAllocation,
  postCreateAllocations,
  getAllShoppersApplications,
  putUpdateShopperApplicationStatus,
  getAppliedAllocations,
  postUpdateAppliedAllocationStatus,
  getShopper,
  getOrphanAllocations,
  getNearbyShopper,
  postInviteShopper,
  getcategories,
  getentities,
  addquestions,
  updatequestions,
  getquestions,
  getquestiontemplate,
  uploadVenueImage,
  addquestiontemplate,
  updatequestiontemplate,
  deletequestiontemplate,
  gettemplatequestions,
  addtemplatequestions,
  removetemplatequestions,
  putCancelJob,
  getcliententityquestions,
  addbusinessquestions,
  removebusinessquestions,
  updatebusinessquestions,
  addvenuequestions,
  getvenuequestions,
  removevenuequestions,
  updatevenuequestions,
  updatevenuequestionorder,
  updatevenuecategoryorder,
  getClientDbName
} from './ApiEndPoint.tsx'
import { baseApiCall } from './BaseApiCall.tsx'

export const ApiUsersLogin = (data: any) => {
  return baseApiCall({
    url: usersLogin,
    method: 'post',
    data
  })
}

export const ApiGetAllRoles = (data: any) => {
  return baseApiCall({
    url: getAllRoles,
    method: 'get',
    data
  })
}

export const ApiGetAdminList = () => {
  return baseApiCall({
    url: getAdminList,
    method: 'get'
  })
}
export const ApiGetClientEntities = (data: any) => {
  return baseApiCall({
    url: getClientEntities,
    method: 'get',
    data
  })
}

export const ApiGetUserDetails = (id: any) => {
  return baseApiCall({
    url: getUserDetails + id,
    method: 'get'
  })
}
export const ApiPostCreateAdmin = (data: any) => {
  return baseApiCall({
    url: postCreateAdmin,
    method: 'post',
    data
  })
}
export const ApiPutUpdateClient = (data: any) => {
  return baseApiCall({
    url: putUpdateClient,
    method: 'put',
    data
  })
}

export const ApiPostCreateClient = (data: any) => {
  return baseApiCall({
    url: postCreateClient,
    method: 'post',
    data
  })
}
export const ApiGetClients = () => {
  return baseApiCall({
    url: getClients,
    method: 'get'
  })
}
export const ApiPutDeleteClient = (clientId: any) => {
  return baseApiCall({
    url: putDeleteClient + clientId,
    method: 'put'
  })
}
export const ApiPutStoreStatus = (data: any) => {
  return baseApiCall({
    url: putStoreStatus,
    method: 'put',
    data
  })
}
export const ApiPutUpdateClientEntity = (data: any) => {
  return baseApiCall({
    url: putUpdateClientEntity,
    method: 'put',
    data
  })
}
export const ApiPostCreateClientEntity = (data: any) => {
  return baseApiCall({
    url: postCreateClientEntity,
    method: 'post',
    data
  })
}

export const ApiGetEntityTypeList = () => {
  return baseApiCall({
    url: getEntityTypeList,
    method: 'get'
  })
}

export const ApiGetVenueList = (data: any) => {
  return baseApiCall({
    url: getVenueList + data,
    method: 'get'
  })
}
export const ApiPutVenueStatus = (data: any) => {
  return baseApiCall({
    url: putVenueStatus,
    method: 'put',
    data
  })
}

export const ApiPostCreateVenue = (data: any) => {
  return baseApiCall({
    url: postCreateVenue,
    method: 'post',
    data
  })
}
export const ApiPutUpdateVenue = (data: any) => {
  return baseApiCall({
    url: putUpdateVenue,
    method: 'put',
    data
  })
}

export const ApiPutUpdateAdminDetails = (data: any) => {
  return baseApiCall({
    url: putUpdateAdminDetails,
    method: 'put',
    data
  })
}

export const ApiPutUpdateAdminRole = (data: any) => {
  return baseApiCall({
    url: putUpdateAdminRole,
    method: 'put',
    data
  })
}

export const ApiPutUpdateAdminStatus = (data: any) => {
  return baseApiCall({
    url: putUpdateAdminStatus,
    method: 'put',
    data
  })
}

export const ApiGetShopType = () => {
  return baseApiCall({
    url: getShopType,
    method: 'get'
  })
}

export const ApiGetStarRating = () => {
  return baseApiCall({
    url: getStarRating,
    method: 'get'
  })
}
export const ApiGetBriefJson = () => {
  return baseApiCall({
    url: getBriefJson,
    method: 'get'
  })
}
export const ApiPutBriefJson = (data: any) => {
  return baseApiCall({
    url: getBriefJson,
    method: 'put',
    data
  })
}

export const ApiPostBriefJson = (data: any) => {
  return baseApiCall({
    url: getBriefJson,
    method: 'post',
    data
  })
}

export const ApiPutUpdateEditors = (data: any) => {
  return baseApiCall({
    url: putUpdateEditors,
    method: 'put',
    data
  })
}

export const ApiPutVenueUpdateEditors = (data: any) => {
  return baseApiCall({
    url: putVenueEditors,
    method: 'put',
    data
  })
}

export const ApiGetClientEntityListById = (data: any) => {
  return baseApiCall({
    url: getClientEntities + data,
    method: 'get'
  })
}

export const ApiGetVisitTimes = () => {
  return baseApiCall({
    url: getVisitTimes,
    method: 'get'
  })
}

export const ApiPostVisitTimes = (data: any) => {
  return baseApiCall({
    url: postVisitTimes,
    method: 'post',
    data
  })
}

export const ApiPutVisitTimes = (data: any) => {
  return baseApiCall({
    url: putVisitTimes,
    method: 'put',
    data
  })
}

export const ApiPostClientEntityTemplate = (data: any) => {
  return baseApiCall({
    url: postClientEntityTemplate,
    method: 'post',
    data
  })
}

export const ApiGetClientEntitiTemplate = (data: any) => {
  return baseApiCall({
    url: getClientEntityTemplateByClientEntityId + data,
    method: 'get'
  })
}

export const ApiPutClientEntityTemplate = (data: any) => {
  return baseApiCall({
    url: putClientEntityTemplate,
    method: 'put',
    data
  })
}

export const ApiPostVenueTemplate = (data: any) => {
  return baseApiCall({
    url: postVenueTemplate,
    method: 'post',
    data
  })
}
export const ApiPutVenueTemplate = (data: any) => {
  return baseApiCall({
    url: postVenueTemplate,
    method: 'put',
    data
  })
}

export const ApiGetVenueEntityTemplate = (data: any) => {
  return baseApiCall({
    url: getVenueEntityTemplateByClientEntityId + data,
    method: 'get'
  })
}

export const ApiGetAllocations = (data: any) => {
  return baseApiCall({
    url: getAllocations + data,
    method: 'get'
  })
}
export const ApiPutUpdateAllocation = (data: any) => {
  return baseApiCall({
    url: putUpdateAllocation,
    method: 'put',
    data
  })
}
export const ApiPostCreateAllocations = (data: any) => {
  return baseApiCall({
    url: postCreateAllocations,
    method: 'post',
    data
  })
}
export const ApiGetShopper = (data: any) => {
  return baseApiCall({
    url: getShopper + data,
    method: 'get'
  })
}
export const ApiGetOrphanAllocations = (data: any) => {
  return baseApiCall({
    url: getOrphanAllocations + data,
    method: 'get'
  })
}

export const ApiGetNearbyShopper = (data: any) => {
  return baseApiCall({
    url: getNearbyShopper + data,
    method: 'get'
  })
}

export const ApiGetShoppersApplication = () => {
  return baseApiCall({
    url: getAllShoppersApplications,
    method: 'get'
  })
}

export const ApiPutUpdateShopperApplicationStatus = (data: any) => {
  return baseApiCall({
    url: putUpdateShopperApplicationStatus,
    method: 'put',
    data
  })
}

export const ApiGetAppliedAllocations = (data: any) => {
  return baseApiCall({
    url: getAppliedAllocations + data,
    method: 'get',
  })
}

export const ApiPostUpdateAppliedAllocationStatus = (data: any) => {
  return baseApiCall({
    url: postUpdateAppliedAllocationStatus,
    method: 'post',
    data
  })
}

export const ApiPostInviteShopper = (data: any) => {
  return baseApiCall({
    url: postInviteShopper,
    method: 'post',
    data
  })
}
  export const GetCategories = () => {
    return baseApiCall({
      url: getcategories,
      method: 'get',
    })
  }
  export const GetEntities = () => {
    return baseApiCall({
      url: getentities,
      method: 'get',
    })
  }
  export const AddNewQuestion = (data: any) => {
    return baseApiCall({
      url: addquestions,
      method: 'post',
      data
    })
  }
  export const UpdateQuestion = (data: any) => {
    return baseApiCall({
      url: updatequestions,
      method: 'put',
      data
    })
  }
  export const GetQuestions = (offset: number, limit: number,entityTypeId:number | string, categoryId: number | string, search: string) => {
    return baseApiCall({
      url:`${getquestions}?offset=${offset}&limit=${limit}&${categoryId !== 0 && `categoryId=${categoryId}`}&${entityTypeId !== 0 && `entityTypeId=${entityTypeId}`}&${search?.length > 0 && `search=${search}`}`,
      method: 'get',
    })
  }
  export const DeleteQuestionTemplate = (entityTypeTemplateId:number) => {
    return baseApiCall({
      url:`${deletequestiontemplate}?entityTypeTemplateId=${entityTypeTemplateId}`,
      method: 'delete',
    })
  }
  export const GetQuestionTemplate = () => {
    return baseApiCall({
      url: getquestiontemplate,
      method: 'get',
    })
  }
  export const ApiPostUploadVenueImage = (data: any) => {
    return baseApiCall({
      url: uploadVenueImage,
      method: 'post',
      data
    })
  }
  export const AddQuestionTemplate = (data: any) => {
    return baseApiCall({
      url: addquestiontemplate,
      method: 'post',
      data
    })
  }
  export const UpdateQuestionTemplate = (data: any) => {
    return baseApiCall({
      url: updatequestiontemplate,
      method: 'put',
      data
    })
  }
  export const GetTemplateQuestions = (entityTypeTemplateId: number) => {
    return baseApiCall({
      url:`${gettemplatequestions}?&entityTypeTemplateId=${entityTypeTemplateId}`,
      method: 'get',
    })
  }
  export const GetClientEntityQuestions = (clientEntityId: number) => {
    return baseApiCall({
      url:`${getcliententityquestions}?&clientEntityId=${clientEntityId}`,
      method: 'get',
    })
  }
  export const AddTemplateQuestions = (data: any) => {
    return baseApiCall({
      url: addtemplatequestions,
      method: 'post',
      data
    })
  }
  export const AddBusinessQuestions = (data: any) => {
    return baseApiCall({
      url: addbusinessquestions,
      method: 'post',
      data
    })
  }
  export const RemoveTemplateQuestions = (entityTypeTemplateQuestionId: number) => {
    return baseApiCall({
      url: `${removetemplatequestions}?entityTypeTemplateQuestionId=${entityTypeTemplateQuestionId}`,
      method: 'post',
      // data
    })
  }
  export const RemoveClientQuestions = (clientEntityQuestionId: number | boolean,clientEntityId:number) => {
    return baseApiCall({
      url: `${removebusinessquestions}?${clientEntityQuestionId === true ? 'allDataNeedToDelete' :'clientEntityQuestionId'}=${clientEntityQuestionId}&clientEntityId=${clientEntityId}`,
      method: 'delete',
      // data
    })
  }
  export const UpdateBusinessQuestions = (data: any) => {
    return baseApiCall({
      url: updatebusinessquestions,
      method: 'put',
      data
    })
  }
  
  export const AddVenueQuestions = (data: any) => {
    return baseApiCall({
      url: addvenuequestions,
      method: 'post',
      data
    })
  }
  export const GetVenueQuestions = (venueId: number) => {
    return baseApiCall({
      url:`${getvenuequestions}?venueId=${venueId}`,
      method: 'get',
    })
  }
  export const RemoveVenueQuestions = (clientEntityQuestionVenueId: number | boolean,venueId:number) => {
    return baseApiCall({
      url: `${removevenuequestions}?${clientEntityQuestionVenueId === true ? 'allDataNeedToDelete' :'clientEntityQuestionVenueId'}=${clientEntityQuestionVenueId}&venueId=${venueId}`,
      method: 'delete',
      // data
    })
  }
  export const UpdateVenueQuestions = (data: any) => {
    return baseApiCall({
      url: updatevenuequestions,
      method: 'put',
      data
    })
  }
  export const UpdateVenueQuestionOrder = (data: any) => {
    return baseApiCall({
      url: updatevenuequestionorder,
      method: 'post',
      data
    })
  }
  export const UpdateVenueCategoryOrder = (data: any) => {
    return baseApiCall({
      url: updatevenuecategoryorder,
      method: 'post',
      data
    })
  }
  export const ApiPutCancelJob = (data: any) => {
    return baseApiCall({
      url: putCancelJob + data,
      method: 'put'
    })
  }

  export const ApiGetClientDbName = () => {
    return baseApiCall({
      url: getClientDbName,
      method: 'get'
    })
  }