import React from "react";
import { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { Button, Autocomplete, TextField } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { toast } from "react-toastify";
import {
  ApiPutUpdateClientEntity,
  ApiPostCreateClientEntity,
  ApiGetClients,
  ApiGetEntityTypeList,
  ApiGetBriefJson,
  ApiGetStarRating,
} from "../../../apiService/Core/Apicall.tsx";
interface AddOrUpdateStoreProps {
  initialValues?: {
    locationName: string;
    code: string;
    clientName: string;
    clientPhone: string;
    clientAddress: string;
    clientState: string;
    clientPostCode: string;
    clientInvoiceEmail: string;
    fileName: string;
    pdfName: string;
    templateURL: string;
    minWords: number;
    maxWords: number;
    showProductOfMonth: boolean;
    showExtraFields: boolean;
    hotelType: string;
    ownerEmail: string;
    teamBCCEmail: string;
    reportMessage: string;
    briefJson: string;
    starRatings: number;
    maxImages: number;
    minImages: number;
    hideInvoicePanel: boolean;
  };
  mode: "add" | "update";
  onSubmit: (values: any) => void;
}

const AddOrUpdateStore: React.FC<AddOrUpdateStoreProps> = ({
  initialValues = {
    locationName: "",
    entityCode: "",
    clientName: "",
    clientPhone: "",
    clientAddress: "",
    clientState: "",
    clientPostCode: "",
    clientInvoiceEmail: "",
    fileName: "",
    pdfName: "",
    templateURL: "",
    minWords: 0,
    maxWords: 0,
    showProductOfMonth: false,
    showExtraFields: false,
    hotelType: "",
    ownerEmail: "",
    teamBCCEmail: "",
    reportMessage: "",
    briefJson: "",
    starRatings: "",
    maxImages: 0,
    minImages: 0,
    hideInvoicePanel: false,
  },
  onSubmit,
}) => {
  const { mode } = useParams<{ mode: "add" | "update" }>();

  const location = useLocation();
  const clientEntity = location?.state?.clientEntity;
  const [formValues, setFormValues] = useState(initialValues);
  const [clients, setClients] = useState([]);
  const [entityType, setEntityType] = useState<any[]>([]);
  const [clientName, setClientName] = useState("");
  const [hotelType, setHotelType] = useState("");
  const [starRatingList, setStarRating] = useState<any>([]);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    minWords: Yup.number()
      .min(0, "Minimum words must be at least 0")
      .test(
        "min-max-words",
        "Minimum words cannot be greater than maximum words",
        function (minWords) {
          const { maxWords } = this.parent;
          return !minWords || !maxWords || minWords <= maxWords;
        }
      ),
    maxWords: Yup.number().min(0, "Maximum words must be at least 0"),
    minImages: Yup.number()
      .min(0, "Minimum images must be at least 0")
      .test(
        "min-max-images",
        "Minimum images cannot be greater than maximum images",
        function (minImages) {
          const { maxImages } = this.parent;
          return !minImages || !maxImages || minImages <= maxImages;
        }
      ),
    maxImages: Yup.number().min(0, "Maximum images must be at least 0"),
  });

  useEffect(() => {
    if (mode === "update" && clientEntity) {
      console.log(`Fetching data for admin with ID: ${clientEntity}`);
      setClientName(clientEntity.clientId.ClientID || "");
      setHotelType(clientEntity.entityTypeId.entityTypeId || "");
      setFormValues({
        locationName: clientEntity.entityName || "",
        entityCode: clientEntity.entityCode || "",
        clientName: clientEntity.clientId.ClientID || "",
        clientPhone: clientEntity.contactNo || "",
        clientAddress: clientEntity.address || "",
        clientState: clientEntity.state || "",
        clientPostCode: clientEntity.postalCode || "",
        clientInvoiceEmail: clientEntity.emailId || "",
        fileName: clientEntity.fileName || "",
        pdfName: clientEntity.pdfName || "",
        templateURL: clientEntity.templateURL || "",
        minWords: clientEntity.minNoofWords || 0,
        maxWords: clientEntity.maxNoofWords || 0,
        showProductOfMonth: clientEntity.showPom || false,
        showExtraFields: clientEntity.extraFields || false,
        hotelType: clientEntity.entityTypeId.entityTypeId || "",
        ownerEmail: clientEntity.ownerEmail || "",
        teamBCCEmail: clientEntity.bccEmailId || "",
        reportMessage: clientEntity.reportMessage || "",
        briefJson: clientEntity.briefJson || "",
        starRatings: clientEntity.ratingJson || "",
        maxImages: clientEntity.maxNoOfImages || 0,
        minImages: clientEntity.minNoOfImages || 0,
        hideInvoicePanel: clientEntity.invoicePanel || false,
      });
    }
  }, [mode, clientEntity]);

  useEffect(() => {
    if (clientEntity) {
      console.log("response getUserDetails", clientEntity?.entityName);
    }
    handleGetApiCallClients();
    handleGetApiCallEntityType();
    handelApiGetStarRating();
    handleGetEditType();
  }, [clientEntity, mode]);

  const handleGetApiCallClients = async () => {
    try {
      const response: any = await ApiGetClients();
      console.log("response roles", response.data);
      setClients(
        response.data.map((client: any) => ({
          label: client.ClientName,
          value: client.ClientID,
        }))
      );
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const handelApiGetStarRating = async () => {
    try {
      const response: any = await ApiGetStarRating();
      console.log("StarRating list", response.data);
      setStarRating(
        response.data.map((starRating: any) => ({
          label: starRating.starRatingDesc,
          value: starRating.starRatingId,
        }))
      );
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const handleGetApiCallEntityType = async () => {
    try {
      const response: any = await ApiGetEntityTypeList();
      console.log("response roles", response.data);

      const transformedData = response.data.map((item: any) => ({
        value: item.entityTypeId,
        label: item.entityTypeName,
      }));
      setEntityType(transformedData);
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  const handleAddStore = async (values: any) => {
    const valueNew = Array.isArray(values.briefJson)
      ? values.briefJson.map((item) => item)
      : [];
    const commaSeparated = valueNew.join(",");

    const valueRatingJson = Array.isArray(values.starRatings) ? values.starRatings : []
    const commaSeparatedRatingJson = valueRatingJson.length > 0 ? valueRatingJson.join(",") : values.starRatings

    const FormData = {
      entityName: values.locationName,
      entityCode: values.entityCode,
      clientId: clientName,
      contactNo: values.clientPhone,
      emailId: values.clientInvoiceEmail,
      address: values.clientAddress,
      city: "",
      state: values.clientState,
      postalCode: values.clientPostCode,
      country: "",
      lat: "",
      long: "",
      fileName: values.fileName,
      pdfName: values.pdfName,
      minNoofWords: values.minWords,
      maxNoofWords: values.maxWords,
      showPom: values.showProductOfMonth,
      extraFields: values.showExtraFields,
      entityTypeId: hotelType,
      bccEmailId: values.teamBCCEmail,
      briefJson: commaSeparated,
      ratingJson: commaSeparatedRatingJson,
      maxNoOfImages: values.maxImages,
      minNoOfImages: values.minImages,
      invoicePanel: values.hideInvoicePanel,
      createdBy: 1,
    };
    try {
      const response = await ApiPostCreateClientEntity(FormData);
      console.log("New store added:", response);
      toast.success("Store added successfully!");
      navigate(-1);
    } catch (error) {
      console.error("Error adding store:", error);
      toast.error(error);
    }
  };
  const handleUpdateStore = async (values: any) => {
    const valueNew = Array.isArray(values.briefJson)
      ? values.briefJson.map((item) => item)
      : [];
    const commaSeparated = valueNew.join(",");

    const valueRatingJson = Array.isArray(values.starRatings) ? values.starRatings : []
    const commaSeparatedRatingJson = valueRatingJson.length > 0 ? valueRatingJson.join(",") : values.starRatings

    if(values.minWords > values.maxWords){
      toast.error("min Words should not be greater then max words!");
      return;
    }

    if(values.minImages > values.maxImages){
      toast.error("min Image should not be greater then max Image!");
      return;
    }

    const FormData = {
      clientEntityId: clientEntity?.clientEntityId,
      entityName: values.locationName,
      entityCode: values.entityCode,
      clientId: clientName,
      contactNo: values.clientPhone,
      emailId: values.clientInvoiceEmail,
      address: values.clientAddress,
      city: "",
      state: values.clientState,
      postalCode: values.clientPostCode,
      country: "",
      lat: "",
      long: "",
      fileName: values.fileName,
      pdfName: values.pdfName,
      minNoofWords: values.minWords,
      maxNoofWords: values.maxWords,
      showPom: values.showProductOfMonth,
      extraFields: values.showExtraFields,
      entityTypeId: hotelType,
      bccEmailId: values.teamBCCEmail,
      briefJson: commaSeparated,
      ratingJson: commaSeparatedRatingJson,
      maxNoOfImages: values.maxImages,
      minNoOfImages: values.minImages,
      invoicePanel: values.hideInvoicePanel,
      createdBy: 1,
    };
    try {
      const response = await ApiPutUpdateClientEntity(FormData);
      console.log("Store updated:", response);
      toast.success("Store Update successfully!");
      navigate(-1);
    } catch (error) {
      console.error("Error updating store:", error);
    }
  };

  const [briefJsonNew, setBriefJson] = useState<any>([]);

  const handleGetEditType = async () => {
    try {
      const response: any = await ApiGetBriefJson();
      if (Array.isArray(response.data)) {
        const transformedData = response.data.map((item: any) => ({
          value: item.briefJsonId,
          label: item.briefJsonTitle,
        }));
        setBriefJson(transformedData);
      } else {
        console.error("Unexpected data format:", response.data);
        setBriefJson([]);
      }
    } catch (error) {
      console.error("Error during API call:", error);
      setBriefJson([]);
    }
  };

  return (
    <Formik
      initialValues={formValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={(values) => {
        console.log("Form submitted with values:", values);
        if (mode === "add") {
          console.log("Calling handleAddStore");
          handleAddStore(values);
        } else {
          console.log("Calling handleUpdateStore");
          handleUpdateStore(values);
        }
      }}
    >
      {({ handleChange, handleBlur, values }) => (
        <Form style={{ background: "white", padding: "30px" }}>
          <h1 className="common-add">
            {mode === "add" ? "Add New Business" : "Update Business"}
          </h1>

          <div className="menage-store-select">
            <Autocomplete
              options={clients}
              value={
                clients.find((option: any) => option.value === clientName) ||
                null
              }
              getOptionLabel={(option: any) => option.label}
              onChange={(event, newValue) => {
                if (newValue) {
                  setClientName(newValue.value);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Store" />
              )}
              fullWidth
              disabled={mode === "update"}
            />
            <Autocomplete
              options={entityType}
              style={{ marginTop: "15px" }}
              value={
                entityType.find((option) => option.value === hotelType) || null
              }
              getOptionLabel={(option) => option.label}
              onChange={(event, newValue) => {
                if (newValue) {
                  setHotelType(newValue.value);
                }
              }}
              renderInput={(params) => (
                <TextField {...params} label="Select Entity Type" />
              )}
              fullWidth
              disabled={mode === "update"}
            />
          </div>
          <div style={{ padding: "20px", width: "100%", paddingBottom: "0px" }}>
            {[
              { label: "Location Name", name: "locationName", type: "text" },
              { label: "Entity Code", name: "entityCode", type: "text" },
              { label: "Client Phone", name: "clientPhone", type: "text" },
              { label: "Client Address", name: "clientAddress", type: "text" },
              { label: "Client State", name: "clientState", type: "text" },
              {
                label: "Client PostCode",
                name: "clientPostCode",
                type: "text",
              },
              {
                label: "Client Invoice Email",
                name: "clientInvoiceEmail",
                type: "email",
              },
              { label: "File Name", name: "fileName", type: "text" },
              { label: "PDF Name", name: "pdfName", type: "text" },
              // { label: 'Template URL', name: 'templateURL', type: 'url' },
              { label: "Min. No. of Words", name: "minWords", type: "number" },
              { label: "Max. No. of Words", name: "maxWords", type: "number" },
              {
                label: "Show Product of Month?",
                name: "showProductOfMonth",
                type: "checkbox",
              },
              {
                label: "Show Extra Fields?",
                name: "showExtraFields",
                type: "checkbox",
              },
              { label: "Team BCC Email", name: "teamBCCEmail", type: "email" },
              // {
              //   label: 'Report Message',
              //   name: 'reportMessage',
              //   type: 'textarea'
              // },
              { label: "Brief JSON", name: "briefJson", type: "Brief" },
              { label: "Star Ratings", name: "starRatings", type: "string" },
              {
                label: "Max. No. of Images",
                name: "maxImages",
                type: "number",
              },
              {
                label: "Min. No. of Images",
                name: "minImages",
                type: "number",
              },
              {
                label: "Hide Invoice Panel?",
                name: "hideInvoicePanel",
                type: "checkbox",
              },
            ].map(({ label, name, type }) => (
              <div
                key={name}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "15px",
                  width: "100%",
                }}
              >
                <label
                  htmlFor={name}
                  style={{
                    marginRight: "10px",
                    minWidth: "200px",
                    textAlign: "right",
                  }}
                >
                  {label}:
                </label>
                {type === "Brief" ? (
                  <div style={{ width: "100%" }}>
                    <Autocomplete
                      multiple
                      options={briefJsonNew}
                      value={briefJsonNew.filter((editor) =>
                        values.briefJson.includes(editor.value)
                      )}
                      getOptionLabel={(option) => option.label}
                      onChange={(event, newValue) => {
                        const selectedValues = newValue.map(
                          (option) => option.value
                        );
                        handleChange({
                          target: { name: "briefJson", value: selectedValues },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select briefJson"
                          fullWidth
                        />
                      )}
                    />
                  </div>
                ) : type === "textarea" ? (
                  <Field
                    as="textarea"
                    id={name}
                    name={name}
                    style={{ width: "100%", height: "80px" }}
                    value={values[name]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : type === "checkbox" ? (
                  <Field
                    type="checkbox"
                    id={name}
                    name={name}
                    style={{ width: "auto", margin: "0 10px" }}
                    checked={values[name]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ) : type === "number" ? (
                  <div style={{ width: "calc(100% - 220px)" }}>
                    <Field
                      type={type}
                      id={name}
                      name={name}
                      style={{
                        width: "100%",
                        padding: "5px",
                      }}
                      value={values[name]}
                      min="0"
                      onChange={(e) => {
                        const value = e.target.value;
                        if (
                          value === "" ||
                          (Number(value) >= 0 && /^\d*$/.test(value))
                        ) {
                          handleChange(e);
                        }
                      }}
                      onBlur={handleBlur}
                    />
                    {name === "minWords" ||
                    name === "maxWords" ||
                    name === "minImages" ||
                    name === "maxImages" ? (
                      <ErrorMessage name={name}>
                        {(msg) => (
                          <div style={{ color: "red", fontSize: "12px" }}>
                            {msg}
                          </div>
                        )}
                      </ErrorMessage>
                    ) : null}
                  </div>
                ) : name === "clientInvoiceEmail" ? (
                  <Field
                    type={type}
                    id={name}
                    name={name}
                    style={{
                      width: "calc(100% - 220px)",
                      padding: "5px",
                    }}
                    value={values[name]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={mode === "update"}
                  />
                ) : type === "string" ? (
                  <Autocomplete
                    multiple
                    id="star-ratings-multiple"
                    options={starRatingList}
                    style={{ marginTop: "15px", width: "calc(100% - 220px)" }}
                    value={starRatingList.filter((option) =>
                      values.starRatings.includes(option.value)
                    )}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValues) => {
                      const selectedValues = newValues.map(
                        (option) => option.value
                      );
                      handleChange({
                        target: { name: "starRatings", value: selectedValues },
                      });
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Star Ratings" />
                    )}
                    fullWidth
                  />
                ) : (
                  <Field
                    type={type}
                    id={name}
                    name={name}
                    style={{
                      width: "calc(100% - 220px)",
                      padding: "5px",
                    }}
                    value={values[name]}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                )}
              </div>
            ))}
          </div>
          <div className="text-center">
            <Button
              style={{ padding: "9px 70px" }}
              type="submit"
              variant="contained"
              color="primary"
              className="table-row-background"
            >
              {mode === "add" ? "Add Business" : "Update Business"}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddOrUpdateStore;
