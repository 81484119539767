import { Button, List, Typography, Box, FormControl, TextField, Select, MenuItem, ListItem, Pagination } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { AddBusinessQuestions, AddTemplateQuestions, AddVenueQuestions, GetCategories, GetQuestions } from '../../../apiService/Core/Apicall.tsx';
import { SearchOutlined } from '@mui/icons-material';

const limit = 5;
function QuestionList({ venueId, entityTypeTemplateId, clientEntityId, onUpdate }) {
    const [category, setcategorey] = useState<any>('')
    const [selectedCategory, setselectedCategory] = useState<number | string>(0)
    const [questionBank, setquestionBank] = useState<any>([])
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [total, setTotal] = useState<number>(0);

    useEffect(() => {
        const fetchcategory = async () => {
            try {
                const categories = await GetCategories()
                setcategorey(categories.data)
            }
            catch (error) {
                alert(error)
            }
        }

        fetchcategory()
    }, [])
    useEffect(() => {
        const fetchquestionbank = async () => {
            const offset = (currentPage - 1) * limit;
            try {
                const response = await GetQuestions(offset, limit, 0, selectedCategory, searchTerm)
                setquestionBank(response.data)
                setTotal(response.totalCount);
                console.log(response.data)

            }
            catch (error) {
                alert(error)
            }
        }
        fetchquestionbank()
    }, [currentPage, selectedCategory, searchTerm])

    const totalPages = Math.ceil(total / limit);

    const filteredQuestions = questionBank.filter((q) =>
        q.questions.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const addData = async (questionId) => {
        if (clientEntityId === null) {

            const values = {
                entityTypeTemplateId: entityTypeTemplateId,
                questions: [{ questionId: questionId }]
            }
            // console.log("Values", values)
            try {
                const response = await AddTemplateQuestions(values)
                // alert(response.message)
                onUpdate()
            }
            catch (error) {
                alert(error)
            }

        }
        else {
            if (venueId === null) {
                const data = {
                    clientEntityId: clientEntityId,
                    questionId: questionId,
                    createdBy: 1
                }
                // console.log(data)
                try {
                    const response = await AddBusinessQuestions(data)
                    // alert(response.message)
                    onUpdate()
                }
                catch (error) {
                    alert(error)
                }
            }
            else {
                const data = {
                    venueId: venueId,
                    questionId: questionId,
                    createdBy: 1
                }
                // console.log(data)
                try {
                    const response = await AddVenueQuestions(data)
                    // alert(response.message)
                    onUpdate()
                }
                catch (error) {
                    alert(error)
                }

            }

        }

    }
    return (
        <Box className='w-100' maxHeight='100%' overflow='auto'>
            <Typography className='fs-18 fw-bold mb-2 mt-1'>Question Bank</Typography>
            <Box className='d-flex'>
                <FormControl className='mr-2' fullWidth>
                    <TextField
                        value={searchTerm ?? ""}
                        onChange={(e) => { setSearchTerm(e.target.value) }}
                        placeholder='Search Your Question here...'
                        InputProps={{ startAdornment: (<SearchOutlined />) }}
                    />
                </FormControl>
                <FormControl className='col-2'>
                    <Select value={selectedCategory}
                        onChange={(e) => { setselectedCategory(Number(e.target.value)) }}>

                        <MenuItem value={0}>Select Category</MenuItem>
                        {category.length > 0 &&
                            category.map((cat, index) => (
                                <MenuItem value={cat.categoryId} key={index}>
                                    {cat.categoryName}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
            </Box>
            {filteredQuestions.length > 0 && filteredQuestions.map((question, index) => (
                <Box key={index} className='my-2' border='1px solid #E0E0E0' borderRadius='5px' padding='10px'>
                    <Box className='d-flex align-items-center justify-content-between my-3'>
                        <Box className='d-flex align-items-center' gap={1}>
                            <Typography className='text-main fw-bold'>Question ID: #{question.questionId}</Typography>
                            <Typography className='text-main fw-bold'>|</Typography>
                            <Typography className='fw-bold'>Category: {question.categoryId.categoryName}</Typography>
                            <Typography className='fw-bold'>|</Typography>
                            <Typography className='fw-bold'>Type: {question.qType.length > 0 ? question.qType === "R" ? "Radio" : "Text" : 'notFound'}</Typography>
                            <Typography className='fw-bold'>|</Typography>
                            <Typography className="d-flex align-items-center" gap={1} sx={{ fontWeight: 'bold' }}>color: <Box height='20px' width='50px' bgcolor={question.colorHex} className="border"> </Box></Typography>

                        </Box>
                        <Button className='px-4 py-1 bg-grey transform text-light' onClick={() => { addData(question.questionId) }}>+ Add</Button>
                    </Box>
                    <Box>
                        <Typography className='fw-bold my-1 d-flex'>Q:&nbsp;<span dangerouslySetInnerHTML={{ __html: question.questions }} /></Typography>
                        <Box className='d-flex align-items-center' gap={3}>
                            <Box className='d-flex align-items-center'>
                                {/* <Box className='px-4 py-1 text-main' borderRight='1px solid #747247' bgcolor='#7472471A'>
                                    <Typography>Ver {question.verNo}</Typography>
                                </Box> */}
                                <Typography className='px-4 my-2 py-1 text-main bg-light-primary'>{question.entityTypeId.entityTypeName || 'undefined'}</Typography>
                            </Box>
                            {question.isPhotoRequire && (
                                <Box className=" bg-light-primary px-4 py-2" borderRadius='20px' width='fit-content'>
                                    <Typography>Images - Min: {question.minPhoto}</Typography>
                                </Box>
                            )}
                        </Box>
                        {question.qOptionJson !== null && question.qOptionJson.trim() !== "" ? (
                            <List component='ol' className="question-options w-100 d-flex justify-content-between flex-wrap">
                                {(() => {
                                    try {
                                        const parsedOptions = JSON.parse(question.qOptionJson);
                                        return parsedOptions.map((option, index) => (
                                            <ListItem key={index} className="col-6 option-text">
                                                <span>{index + 1}. &nbsp;</span>
                                                <span dangerouslySetInnerHTML={{ __html: option.value }} />
                                            </ListItem>
                                        ));
                                    } catch (error) {
                                        console.error("Invalid JSON in qOptionJson:", error);
                                        return (
                                            <Typography className="option-text text-danger">
                                                Error: Invalid JSON format
                                            </Typography>
                                        );
                                    }
                                })()}
                            </List>
                        ) : (
                            <Typography className="option-text">
                                {question.options ? question.options[0] : "Answer will be a manual Text Entry."}
                            </Typography>
                        )}
                        <Typography className="d-flex">
                            Discription: &nbsp;<span dangerouslySetInnerHTML={{ __html: question.questionDescription }} />
                        </Typography>
                        <Typography className="d-flex">
                            Hint: &nbsp;<span dangerouslySetInnerHTML={{ __html: question.questionHint }} />
                        </Typography>
                    </Box>
                </Box>
            ))}
            <Pagination
                className="d-flex justify-content-center"
                count={totalPages}
                page={currentPage}
                onChange={(event, value) => setCurrentPage(value)} // Page change handler
            />
        </Box>
    )
}

export default QuestionList
